import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { AD_TYPE } from '@seek/je-shared-data';
import { Badge } from '@components/Badge';
import { PlusAdInfoWithUpgradeButton } from '../AdInfo';

type PlusAdCardProps = {
  adDuration?: number;
  price: React.ReactNode;
  countryCode: string;
  jobId: string;
  campaignId: string;
};

export const UpsellPlusAdCard = (props: PlusAdCardProps) => {
  const { t } = useTranslation();
  const { adDuration, price, countryCode, jobId, campaignId } = props;
  const { redirect } = useRedirect();

  const redirectToEnhanceJob = () => {
    redirect(
      `/${countryCode}/jobs/${jobId}/enhance-job?campaign=${campaignId}&adType=${AD_TYPE.PLUS}`
    );
  };

  return (
    <div className={'flex flex-col p-md border'}>
      <div className="flex flex-row gap-md w-full justify-end min-h-10">
        <Badge tone="promo">{t('upgradeJobForm.recommended')}</Badge>
      </div>

      <PlusAdInfoWithUpgradeButton
        adDuration={adDuration}
        price={price}
        onUpgradeButtonClicked={redirectToEnhanceJob}
      />
    </div>
  );
};

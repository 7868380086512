import {
  useAtJobLimit,
  useShowOverBusinessLimitModal
} from '@common/hooks/userPackages';

export const useJobAndBusinessLimits = () => {
  const {
    isAtLimit: showJobLimitReached,
    isLoading: isJobLimitLoading,
    refetch: refetchJobLimit
  } = useAtJobLimit();
  const showOverBusinessLimitBlockingModal = useShowOverBusinessLimitModal();

  return {
    showJobLimitReached,
    isJobLimitLoading,
    refetchJobLimit,
    showOverBusinessLimitBlockingModal
  };
};

import React from 'react';
import { Trans as I18nextTrans, useTranslation } from 'react-i18next';
import { useGetSignUpLink } from '@common/hooks/appPageLinks';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';

export const BottomRegistrationCard = () => {
  const brandNameText = useBrandNameText();
  const signUpLink = useGetSignUpLink();
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  return (
    <Card testID="bottom-registration-card">
      <CardContent>
        <Box marginTop="medium">
          <Heading level="h2">
            {t('employerLandingPage.joinThousandsCard.heading', {
              brandNameText
            })}
          </Heading>
        </Box>
        <Box marginTop="xsmall">
          <I18nextTrans
            i18nKey="employerLandingPage.text.signUpForFreeToday"
            parent={Text}
            components={[<Text weight="medium" />]}
          />
        </Box>

        <Box
          marginTop="medium"
          direction={{ mobile: 'column', tablet: 'row', desktop: 'row' }}
        >
          <Button
            testID="bottom-registration-card-sign-up-action"
            onPress={redirect(signUpLink)}
          >
            {t('employerLandingPage.action.signupNow')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

export const NudgeContainer = ({ children }: PropsWithChildren) => (
  <div className="p-md bg-grey50 border border-grey200 rounded-xsm">
    <div className="flex flex-col">
      <div
        className={clsx(
          'flex flex-col items-start gap-md',
          'md:flex-row md:items-center md:gap-md md:justify-between',
          'lg:flex-row lg:items-center lg:gap-md lg:justify-between'
        )}
      >
        {children}
      </div>
    </div>
  </div>
);

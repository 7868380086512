import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.EDUCATION]?: string[];
};

type Item = {
  highestEducation?: string;
};

export const filterEducation = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.EDUCATION];

  if (!filterValue) return items;

  return items.filter(
    (item) =>
      item.highestEducation && filterValue.includes(item.highestEducation)
  );
};

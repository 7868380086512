import React from 'react';
import { I18nextTrans, useTranslation } from '@common/hooks/useTranslation';
import { Text } from '@components/Text';
import { Benefit } from './Benefit';
import { plusAdBenefits } from './plusAdBenefits';

export type Props = {
  adDuration?: number;
  price: React.ReactNode;
};

export const PlusAdInfo = (props: Props) => {
  const { adDuration = '-', price } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <Text size="xlarge" weight="regular" testID="plus-package">
            {t('upgradeJob.plusPackage.name')}
          </Text>
          {price}
        </div>
        <Text size="small" weight="regular" tone="neutralLight">
          {t('upgradeJob.plusPackage.details', {
            adDuration
          })}
        </Text>
      </div>

      <div className="flex flex-col gap-sm mt-md">
        {plusAdBenefits.map((benefit, index) => (
          <div key={index} className="flex flex-col gap-1">
            <Text weight="regular" size="standard">
              <I18nextTrans
                i18nKey={benefit.title}
                components={[<Text size="standard" weight="bold" />]}
              />
            </Text>
            <div className="flex flex-col">
              {benefit.points.map((point, pointIndex) => (
                <Benefit key={pointIndex}>{t(point)}</Benefit>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { INTERACTION_TYPE } from '@store/entities/applications';
import { trackInteraction } from '@store/entities/applications/actions';
import { useAppDispatch } from '@store/hooks';
import { trackViewProfileClicked } from './actions';

export function useViewProfileAction(application: Application) {
  const dispatch = useAppDispatch();
  const PROFILE_STATE = {
    OPEN: 'open',
    CLOSE: 'close'
  };
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);

  const trackProfileExpanded = (type) => {
    dispatch(
      trackViewProfileClicked({
        type,
        employerJobId: application.jobId,
        applicationId: application.id,
        candiApplicationId: application?.candiApplicationId,
        candiUserId: application.candiUserId
      })
    );

    dispatch(
      trackInteraction(
        application.id,
        type === PROFILE_STATE.OPEN
          ? INTERACTION_TYPE.VIEW_ONLINE_RESUME
          : INTERACTION_TYPE.CLOSE_ONLINE_RESUME
      )
    );
  };

  return {
    isProfileExpanded,
    onViewProfilePressed: () => {
      const updatedIsProfileExpanded = !isProfileExpanded;

      setIsProfileExpanded(updatedIsProfileExpanded);
      trackProfileExpanded(
        updatedIsProfileExpanded ? PROFILE_STATE.OPEN : PROFILE_STATE.CLOSE
      );
    }
  };
}

export function useViewProfileDisplay(isProfileExpanded) {
  const { t } = useTranslation();

  return isProfileExpanded
    ? t('applications.applicationCard.closeProfile')
    : t('applications.applicationCard.viewProfile');
}

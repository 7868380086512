import { useMemo } from 'react';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import { useGetJobForEdit } from '@common/hooks/job';
import { toFormData } from '../transformers/job/toFormData';

export const useMapJobToFormData = (jobId: string) => {
  const { data: job, isLoading } = useGetJobForEdit({ jobId });

  useHandleAppLoading(isLoading, 'getJobForEdit');

  return useMemo(
    () => ({
      formData: toFormData(job),
      isLoading
    }),
    [job, isLoading]
  );
};

import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';

type NavControlProps = {
  disabled: boolean;
  onBack: () => void;
  onNext: () => Promise<void>;
  onNextText?: string;
  hideBackButton?: boolean;
};

export const NavControls = (props: NavControlProps) => {
  const {
    onBack,
    onNext,
    disabled,
    onNextText,
    hideBackButton = false
  } = props;
  const { t } = useTranslation();

  if (hideBackButton) {
    return (
      <div className="flex flex-row items-center gap-md">
        <Button onPress={onNext} disabled={disabled} testID="nav-controls-next">
          {onNextText || t('common.action.next')}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center gap-md">
      <Button
        variant="secondary"
        onPress={onBack}
        disabled={disabled}
        testID="nav-controls-back"
      >
        {t('common.action.back')}
      </Button>

      <Button onPress={onNext} disabled={disabled} testID="nav-controls-next">
        {onNextText || t('common.action.next')}
      </Button>
    </div>
  );
};

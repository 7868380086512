import React from 'react';
import { T, useTranslation } from '@common/hooks/useTranslation';
import {
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailability,
  ShiftAvailabilityType
} from '@seek/je-shared-data/lib/types/jobs';
import { Column } from '@components/Column';
import { Text } from '@components/Text';

type ShiftAvailabilityOptions = {
  key: ShiftAvailabilityType;
  label: React.ReactElement;
};

const defaultShiftAvailabilityOptions = (t: T): ShiftAvailabilityOptions[] => [
  {
    key: SHIFT_AVAILABILITY_TYPE.ANY_TIME,
    label: (
      <Column>
        <Text>
          {t('postJobForm.shiftAvailability.availabilityTypes.anyTime.label')}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.anyTime.description'
          )}
        </Text>
      </Column>
    )
  },
  {
    key: SHIFT_AVAILABILITY_TYPE.SPECIFIC,
    label: (
      <Column>
        <Text>
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.specificTimes.label'
          )}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypes.specificTimes.description'
          )}
        </Text>
      </Column>
    )
  }
];

const shiftAvailabilityOptionsV2 = (t: T): ShiftAvailabilityOptions[] => [
  {
    key: SHIFT_AVAILABILITY_TYPE.ANY_TIME,
    label: (
      <Column>
        <Text>
          {t('postJobForm.shiftAvailability.availabilityTypesV2.anyTime.label')}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypesV2.anyTime.description'
          )}
        </Text>
      </Column>
    )
  },
  {
    key: SHIFT_AVAILABILITY_TYPE.SPECIFIC,
    label: (
      <Column>
        <Text>
          {t(
            'postJobForm.shiftAvailability.availabilityTypesV2.specificTimes.label'
          )}
        </Text>

        <Text tone="neutralLight" size="small">
          {t(
            'postJobForm.shiftAvailability.availabilityTypesV2.specificTimes.description'
          )}
        </Text>
      </Column>
    )
  }
];

export function useShiftAvailabilityTypeRadio() {
  const { t } = useTranslation();

  const onShiftAvailabilityTypeChange = (
    value: ShiftAvailability,
    onChange: (value: ShiftAvailability) => void,
    selectedShiftAvailabilityType: string
  ) => {
    onChange({
      ...value,
      type: selectedShiftAvailabilityType as ShiftAvailabilityType
    });
  };

  return {
    defaultShiftAvailabilityOptions: defaultShiftAvailabilityOptions(t),
    shiftAvailabilityOptionsV2: shiftAvailabilityOptionsV2(t),
    onShiftAvailabilityTypeChange
  };
}

import React from 'react';
import { useRedirect } from '@common/hooks/useRedirect';
import { AD_TYPE } from '@seek/je-shared-data';
import { MaxAdInfoWithUpgradeButton } from '../AdInfo';

type MaxAdCardProps = {
  adDuration?: number;
  price: React.ReactNode;
  countryCode: string;
  jobId: string;
  campaignId: string;
};

export const UpsellMaxAdCard = (props: MaxAdCardProps) => {
  const { adDuration, price, countryCode, jobId, campaignId } = props;
  const { redirect } = useRedirect();

  const redirectToEnhanceJob = () => {
    redirect(
      `/${countryCode}/jobs/${jobId}/enhance-job?campaign=${campaignId}&adType=${AD_TYPE.MAX}`
    );
  };

  return (
    <div className={'flex flex-col p-md border'}>
      <div className="gap-md min-h-10">
        {/* No recommended badge for now, but still necessary to ensure equal spacing between cards that have the badge and cards that don't */}
      </div>

      <MaxAdInfoWithUpgradeButton
        adDuration={adDuration}
        price={price}
        onUpgradeButtonClicked={redirectToEnhanceJob}
      />
    </div>
  );
};

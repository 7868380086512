import { useTranslation } from '@common/hooks/useTranslation';
import { ageVerificationConsentRules } from './AgeVerification';
import { termsAndPrivacyConsentRules } from './AgreeToTerms';
import { confirmEmail } from './ConfirmEmail';
import { cookiesConsentRules } from './CookieConsent';
import { email } from './Email';
import { emailVerificationCode } from './EmailVerificationCode';
import { givenName } from './GivenName';
import { mobileNumber } from './MobileNumber';
import { password } from './Password';
import { passwordConfirmationRules } from './PasswordConfirmation';
import { surname } from './Surname';

export function useAccountRules() {
  const { t } = useTranslation();

  return {
    ageVerificationConsentRules: ageVerificationConsentRules(t),
    termsAndPrivacyConsentRules: (brandNameText: string) =>
      termsAndPrivacyConsentRules(t, brandNameText),
    confirmEmailRules: confirmEmail(t),
    cookiesConsentRules: cookiesConsentRules(t),
    emailRules: email(t),
    emailVerificationCodeRules: emailVerificationCode(t),
    givenNameRules: givenName(t),
    surnameRules: surname(t),
    mobileNumberRules: mobileNumber(t),
    passwordRules: password(t),
    passwordConfirmationRules: passwordConfirmationRules(t)
  };
}

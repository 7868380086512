import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

/**
 * Replaces the last part of a given path with a new part and substitutes the country code placeholder.
 *
 * @param path - The original matching route path defined in the route configuration. i.e. '/:countryCode/page/*'.
 * @param newPart - The new part to replace the last part of the path.
 * @param countryCode - The country code to replace the ':countryCode' placeholder in the path.
 * @returns The modified path with the last part replaced and the country code substituted.
 */
function replaceLastPathPart(path: string, newPart: string): string {
  return `${path}${newPart}`;
}

function replacePathParams(path: string, pathParams: Record<string, string>) {
  let newPath = path;

  const paramKeys = Object.keys(pathParams);
  paramKeys.forEach((paramKey) => {
    newPath = newPath.replace(`:${paramKey}`, pathParams[paramKey]);
  });

  return newPath;
}

export function useRedirectToNewPath() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const pathParams = useParams();

  const redirectToNewPath = (newPart: string, queryParams?: string) => {
    let newPath = replaceLastPathPart(path, newPart);

    if (pathParams) {
      newPath = replacePathParams(newPath, pathParams);
    }

    // Backwards and forwards navigation in CAJA is considered a history "PUSH" action.
    history.push(`${newPath}${queryParams ? `?${queryParams}` : ''}`);
  };

  return { redirectToNewPath };
}

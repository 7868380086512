import { AppState } from '@store/store';

export function getBusiness(id?: string) {
  return (state: AppState) =>
    id ? state.entities.businesses.items[id] : undefined;
}

/**
 * Getting business from business list
 */
export function getBusinessById(id: string) {
  return (state: AppState) =>
    state.entities.businesses.list.businesses.find(
      (business) => business.id === id
    );
}

export function getBusinesses() {
  return (state: AppState) => state.entities.businesses.list.businesses;
}

import { BUSINESS_ROLE, INDUSTRY } from '@seek/je-shared-data';
import { Location } from '@seek/je-shared-data/lib/types/location';
import { createBusiness as createBusinessAction } from '@store/entities/businesses/actions';
import { useAppDispatch } from '@store/hooks';
import { CreateBusinessRequest } from './types';

export type BusinessData = {
  name: string;
  country: string;
  businessAddress: Location;
  industryId: string;
  industryOtherDescription?: string;
  businessSize: string;
  role: string;
  recruitmentCompanyName?: string;
  website?: string;
  businessRegistrationNumber?: string;
};

type CreateBusinessProps = {
  business: BusinessData;
  sourceParams: Record<string, string>;
};

const toBusinessRequestData = (
  business: BusinessData
): CreateBusinessRequest => {
  return {
    businessSize: business.businessSize,
    industryId: business.industryId,
    name: business.name,
    role: business.role,
    website: business.website,
    businessAddress: business.businessAddress,
    businessRegistrationNumber: business.businessRegistrationNumber,
    ...(business.industryId === INDUSTRY.OTHER && {
      industryOtherDescription: business.industryOtherDescription
    }),
    ...(business.role === BUSINESS_ROLE.RECRUITER && {
      recruitmentCompanyName: business.recruitmentCompanyName
    })
  };
};

export const useCreateBusiness = () => {
  const dispatch = useAppDispatch();

  return async ({ business, sourceParams }: CreateBusinessProps) => {
    const response = await dispatch(
      createBusinessAction(toBusinessRequestData(business), sourceParams)
    );

    return {
      data: response?.result,
      isSuccessful: !response?.error
    };
  };
};

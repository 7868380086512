import type { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the business size field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function businessSizeRules(t: T) {
  return {
    required: t('validations.business.businessSize.required')
  };
}

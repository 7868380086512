import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.667}
      d="m1.444 15.99 5.983-6.666 5.128 3.334 6.837-6.667 3.419 3.333 6.837-7.5"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.667}
      d="M25.375.992h4.534a.567.567 0 0 1 .593.603v4.397"
    />
  </>
);

export const TrendingIcon = createIcon({
  viewBox: '0 0 32 17',
  vectorComponent
});

import React from 'react';
import { GivenName as InnerField } from '@common/components/Account/components/GivenName';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { useTranslation } from '@common/hooks/useTranslation';
import { FieldWrapper } from '../FieldWrapper';

export const GivenName = () => {
  const { t } = useTranslation();
  const { givenNameRules } = useAccountRules();

  return (
    <FieldWrapper<string>
      name="givenName"
      rules={givenNameRules}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} />;
      }}
    />
  );
};

import { TrpcRouter } from 'je-bff/src/handlers/bff';
import { ClientError } from '@common/helpers/errors';
import { useQueryClient } from '@tanstack/react-query';
import { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';
import { optimisticallyUpdate } from './optimisticallyUpdate';
import { optimisticallyUpdateRepostDraft } from './optimisticallyUpdateRepostDraft';

export type UpdateDraftParams =
  inferRouterInputs<TrpcRouter>['jobs']['drafts']['update'];

export const useUpdateDraft = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, error, isSuccess, isLoading } =
    trpcBff.jobs.drafts.update.useMutation({
      onMutate: (args) => optimisticallyUpdate(args, queryClient)
    });

  return {
    updateDraftAsync: mutateAsync,
    error: getClientError(error),
    isLoading,
    isSuccess
  };
};

export const useUpdateRepostDraft = (
  repostJobId: string,
  forceNewDraft: boolean
) => {
  const queryClient = useQueryClient();

  const { mutateAsync, error, isSuccess, isLoading } =
    trpcBff.jobs.drafts.update.useMutation({
      onMutate: (args) =>
        optimisticallyUpdateRepostDraft(
          args.draft,
          {
            repostJobId,
            forceNewDraft
          },
          queryClient
        )
    });

  return {
    updateDraftAsync: mutateAsync,
    error: getClientError(error),
    isLoading,
    isSuccess
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};

import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { useTranslation } from '@common/hooks/useTranslation';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { options } from './options';

type Props = {
  value?: boolean;
  onChange: (key: boolean) => void;
  error?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
};

export const ImmediateStartField = ({
  value,
  onChange,
  error,
  label,
  disabled = false
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <Field
        label={label || t('postJobForm.fieldLabel.immediateStart')}
        message={
          error && (
            <Box marginTop="xsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <SingleSelect<boolean>
            disabled={disabled}
            testID="job-form-immediateStart"
            onChange={onChange}
            options={options(t)}
            value={value}
          />
        </Box>
      </Field>
    </Stack>
  );
};

import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { IndustrySelector as InnerField } from '@common/components/BusinessForm/components/IndustrySelector';
import { FieldWrapper } from '../FieldWrapper';

export default function IndustrySelector() {
  const { industryRules } = useBusinessRules();
  return (
    <FieldWrapper<string>
      name="industryId"
      rules={industryRules}
      render={(props) => <InnerField {...props} />}
    />
  );
}

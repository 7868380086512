import React from 'react';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Accordion } from '@components/Accordion';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';
import { sortByPrimaryCountry } from './sortByPrimaryCountry';
import { useLocationOptions } from './useLocationOptions';
import { LocatableItem } from './useLocationOptions/type';

type DisabledLocationFilterProps = {
  profileApplications: LocatableItem[];
  primaryCountry?: SupportedCountryCode;
};

export const DisabledLocationFilter = ({
  primaryCountry,
  profileApplications
}: DisabledLocationFilterProps) => {
  const locationOptions = useLocationOptions(profileApplications);

  const options = Object.entries(locationOptions)
    .sort(([countryA], [countryB]) =>
      sortByPrimaryCountry(countryA, countryB, primaryCountry)
    )
    .map(([, countryOptions]) => ({
      label: (
        <span className="text-gray-400">
          {countryOptions[0]?.value.countryName}
        </span>
      ),
      content: (
        <div className="flex flex-wrap">
          {countryOptions.map((option) => (
            <div className="w-full md:w-1/2 mb-6">
              <Checkbox
                disabled
                key={option.label}
                label={`${option.label} (${option.counter})`}
                onChange={() => {}}
              />
            </div>
          ))}
        </div>
      )
    }));
  return (
    <div>
      <div className="flex justify-end">
        <ClearLink onClear={() => {}} active={false} />
      </div>
      <Accordion items={options} />
    </div>
  );
};

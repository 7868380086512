import { useLocation } from 'react-router-dom';
import { getStepIndex } from './model';
import { StepPathInfo } from './types';
import { useRedirectToNewPath } from './useRedirectToNewPath';

type UseStepProps<Data, StepIds extends string> = {
  data: Data;
  getSteps: (data: Data) => StepPathInfo<StepIds>[];
};

type UseStepsReturn<Data, StepIds extends string> = {
  steps: StepPathInfo<StepIds>[];
  currentStep: StepPathInfo<StepIds>;
  nextStep: StepPathInfo<StepIds>;
  gotoPreviousStep: () => void;
  gotoNextStep: (stepData: Partial<Data>) => void;
};

/**
 * Custom hook to manage multi-step form logic.
 *
 * @template T - The type of the data object.
 *
 * @param {Partial<T>} data - The data for the form steps.
 * @param {(data: Partial<T>) => Step[]} getSteps - Function to get the steps based on the data.
 *
 * @returns {UseStepsReturn<T>} - The steps, current step, current step index, step info, and functions to navigate between steps.
 */
export const useSteps = <Data, StepIds extends string>({
  data,
  getSteps
}: UseStepProps<Data, StepIds>): UseStepsReturn<Data, StepIds> => {
  const { pathname } = useLocation();
  const { redirectToNewPath } = useRedirectToNewPath();

  const steps = getSteps(data);
  const currentStepIndex = getStepIndex(steps, pathname);
  const currentStep = steps[currentStepIndex];
  const nextStep = steps[currentStepIndex + 1];
  const previousStep = steps[currentStepIndex - 1];

  const gotoPreviousStep = () => {
    if (previousStep?.path) {
      redirectToNewPath(previousStep.path, previousStep?.queryParams);
    }
  };

  const gotoNextStep = (nextStepData: Partial<Data>) => {
    const updatedData = { ...data, ...nextStepData };

    const updatedSteps = getSteps(updatedData);

    const nextStepConfig = updatedSteps[currentStepIndex + 1];

    if (nextStepConfig.path) {
      redirectToNewPath(nextStepConfig.path, nextStepConfig?.queryParams);
    }
  };

  return {
    steps,
    currentStep,
    gotoPreviousStep,
    gotoNextStep,
    nextStep
  };
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ExpectedSalary,
  JobType,
  ShiftAvailability,
  WorkExperience
} from '@common/components/Job/formFields';
import { useShiftAvailabilityTypeRadio } from '@common/components/Job/formFields/fields/ShiftAvailability/hooks';
import { useWorkExperience } from '@common/components/Job/formFields/fields/WorkExperience/hooks/useWorkExperience';
import { NavControls } from '../components';
import { FormData } from './types';

type Props = {
  defaultValues: FormData;
  onSubmit: (data: FormData) => Promise<void>;
  onBack: () => void;
};

export const BasicDetailsForm = ({
  defaultValues,
  onSubmit,
  onBack
}: Props) => {
  const { t } = useTranslation();
  const { optionsV2 } = useWorkExperience();
  const { shiftAvailabilityOptionsV2 } = useShiftAvailabilityTypeRadio();
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues
  });

  const handleFormSubmitForStep = async (data: FormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <WorkExperience
          name="workExperience"
          control={control}
          overrides={{
            label: t('postJobForm.fieldLabel.workExperienceV2', {
              jobTitle: defaultValues.jobTitle?.jobTitle
            }),
            options: optionsV2
          }}
        />
        <ExpectedSalary
          name="salary"
          control={control}
          overrides={{ label: t('postJobForm.fieldLabel.expectedSalaryV2') }}
        />
        <JobType
          name="jobType"
          control={control}
          overrides={{ label: t('postJobForm.fieldLabel.jobTypeV2') }}
        />
        <ShiftAvailability
          name="shiftAvailability"
          control={control}
          overrides={{
            label: t('postJobForm.fieldLabel.shiftAvailability'),
            options: shiftAvailabilityOptionsV2
          }}
        />

        <NavControls
          onBack={onBack}
          onNext={handleSubmit(handleFormSubmitForStep)}
          disabled={formState.isSubmitting}
        />
      </div>
    </form>
  );
};

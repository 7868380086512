import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Heading } from '@components/Heading';
import { JobSuccessIllustration } from '@components/Illustration';

export const HeaderInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col pt-[24px] p-lg items-center gap-md bg-background-neutral">
      <JobSuccessIllustration size="standard" />
      <Heading level="h1" weight="regular">
        {t('postJobSuccessPage.heading')}
      </Heading>
    </div>
  );
};

import _unescapeHtmlEntities from 'lodash.unescape';
import { push } from 'redux-first-history';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import {
  ClientError,
  ErrorPreventLogging
} from '../../../../common/helpers/errors';
import { getUuid } from '../../../../common/helpers/uuid/uuid';
import REQUEST_KEY from '../../../constants/requestKeys';
import { formatRecruitmentCompanyName } from '../../../helpers/jobs';
import * as draftActions from '../../draft/actions';
import { BUSINESS_DRAFT_FORM } from '../../draft/constants';
import * as constants from './constants';
import { BusinessWithUsers } from './types';

type ErrorBody = { code: string; guid: string };

function formatBusinessListResponse(
  businesses: BusinessWithUsers[]
): BusinessWithUsers[] {
  return businesses.map((business) => ({
    ...business,
    name: _unescapeHtmlEntities(business.name),
    recruitmentCompanyName:
      business.recruitmentCompanyName &&
      _unescapeHtmlEntities(business.recruitmentCompanyName)
  }));
}

function formatSingleBusinessResponse(
  business: BusinessWithUsers
): BusinessWithUsers {
  return {
    ...business,
    name: _unescapeHtmlEntities(business.name),
    website: business.website && _unescapeHtmlEntities(business.website),
    users: Array.isArray(business.users)
      ? business.users.map((user) => {
          return {
            ...user,
            displayCompanyName:
              user.displayCompanyName &&
              _unescapeHtmlEntities(user.displayCompanyName),
            recruitmentCompanyName:
              user.recruitmentCompanyName &&
              _unescapeHtmlEntities(user.recruitmentCompanyName)
          };
        })
      : []
  };
}

function handleLoadError(body: ErrorBody) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.NOT_FOUND:
      throw new ErrorPreventLogging(code);
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}

function handleCreateBusinessError(body: ErrorBody) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.VALIDATION:
      throw new ClientError('errors.validation', { guid });
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}

function handleUpdateBusinessError(body: ErrorBody) {
  const { code, guid } = body;

  switch (code) {
    case GENERAL_EXCEPTION.VALIDATION:
      throw new ClientError('errors.validation', { guid });
    default:
      throw new ClientError('errors.defaultWithGuid', {
        guid,
        responseBody: body
      });
  }
}

export function load(businessId: string) {
  return {
    types: [
      constants.LOAD_BUSINESS,
      constants.LOAD_BUSINESS_SUCCESS,
      constants.LOAD_BUSINESS_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { businessService }
        },
        entities: {
          businesses: { items }
        }
      } = getState();

      const isCached = items[businessId];

      if (isCached) {
        return items[businessId];
      }

      const body = await fetch(
        `${businessService}/${businessId}`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.BUSINESS.LOAD,
          showGlobalSpinner: true,
          onResponseError: handleLoadError
        }
      );

      return formatSingleBusinessResponse(body);
    }
  };
}

export function loadBusinesses({ showGlobalSpinner = false } = {}) {
  return {
    types: [
      constants.LOAD_BUSINESSES,
      constants.LOAD_BUSINESSES_SUCCESS,
      constants.LOAD_BUSINESSES_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { businessService }
        }
      } = getState();

      const body = await fetch(
        `${businessService}/list`,
        {
          method: 'GET',
          credentials: 'include'
        },
        {
          requestKey: REQUEST_KEY.BUSINESS.LIST,
          showGlobalSpinner: showGlobalSpinner,
          defaultError: 'errors.business.listing.default'
        }
      );

      return formatBusinessListResponse(body);
    }
  };
}

export function createBusiness(data, queryParams) {
  return {
    types: [
      constants.CREATE_BUSINESS,
      constants.CREATE_BUSINESS_SUCCESS,
      constants.CREATE_BUSINESS_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { businessService }
        }
      } = getState();
      const id = getUuid();
      const {
        businessSize,
        industryId,
        industryOtherDescription,
        name,
        role,
        recruitmentCompanyName,
        website,
        businessAddress,
        businessRegistrationNumber
      } = data;

      const businessData = {
        id,
        name,
        locations: [businessAddress],
        businessSize,
        industryId,
        industryOtherDescription: industryOtherDescription || undefined,
        role,
        recruitmentCompanyName: recruitmentCompanyName || undefined,
        website: website || undefined,
        businessRegistrationNumber: businessRegistrationNumber || undefined,
        queryParams:
          queryParams && Object.keys(queryParams).length
            ? queryParams
            : undefined
      };

      const body = await fetch(
        `${businessService}`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(businessData)
        },
        {
          requestKey: REQUEST_KEY.BUSINESS.CREATE,
          showGlobalSpinner: true,
          onResponseError: handleCreateBusinessError
        }
      );

      dispatch(draftActions.clearDraftForm(BUSINESS_DRAFT_FORM));

      return body;
    }
  };
}

export function updateBusiness({ data, t }) {
  return {
    types: [
      constants.UPDATE_BUSINESS,
      constants.UPDATE_BUSINESS_SUCCESS,
      constants.UPDATE_BUSINESS_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      const {
        config: {
          endpoints: { businessService }
        },
        localisation
      } = getState();
      const { countryCode } = localisation;
      const {
        id,
        businessSize,
        industryId,
        industryOtherDescription,
        name,
        role,
        recruitmentCompanyName,
        website,
        businessAddress,
        businessRegistrationNumber
      } = data;

      const displayCompanyName = recruitmentCompanyName
        ? formatRecruitmentCompanyName({ t, name, recruitmentCompanyName })
        : name;
      const businessData = {
        name,
        locations: [businessAddress],
        businessSize,
        industryId,
        ...(industryOtherDescription ? { industryOtherDescription } : null),
        role,
        recruitmentCompanyName,
        displayCompanyName,
        website: website || null,
        businessRegistrationNumber: businessRegistrationNumber || null
      };

      await fetch(
        `${businessService}/${id}`,
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify(businessData)
        },
        {
          requestKey: REQUEST_KEY.BUSINESS.EDIT,
          showGlobalSpinner: true,
          onResponseError: handleUpdateBusinessError
        }
      );

      dispatch(push(`/${countryCode}/businesses`));
      dispatch(draftActions.clearDraftForm(BUSINESS_DRAFT_FORM));

      return { id };
    }
  };
}

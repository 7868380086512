import React from 'react';
import { T } from '@common/hooks/useTranslation';
import {
  DAY,
  SHIFT_AVAILABILITY_TYPE,
  ShiftAvailability,
  ShiftAvailabilityType
} from '@seek/je-shared-data/lib/types/jobs';
import { Text } from '@components/Text';

const isShiftTypeAnyTime = (
  shiftAvailabilityType: ShiftAvailabilityType | undefined
) => shiftAvailabilityType === SHIFT_AVAILABILITY_TYPE.ANY_TIME;

export const getShiftAvailabilitySummary = (
  shiftAvailability: ShiftAvailability | undefined,
  t: T
) => {
  if (!shiftAvailability) return null;

  if (isShiftTypeAnyTime(shiftAvailability.type)) {
    return (
      <li>
        <Text>{t('postJobForm.summary.shiftAvailability.anyTime')}</Text>
      </li>
    );
  }

  return Object.values(DAY).map((day) => {
    if (!shiftAvailability?.specificShiftAvailability?.[day].length) {
      return null;
    }

    return (
      <li key={day}>
        <span>
          {t(`postJobForm.shiftAvailability.shiftDay.${day}`) + ': '}
          {shiftAvailability?.specificShiftAvailability?.[day]
            .map((shiftTime) =>
              t(
                `postJobForm.shiftAvailability.shiftTime.${shiftTime.toLowerCase()}`
              )
            )
            .join(', ')}
        </span>
      </li>
    );
  });
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from '@common/hooks/useRedirect';
import { Button } from '@components/Button';
import { TrendingIcon } from '@components/Icon';
import { Text } from '@components/Text';
import { NudgeContainer } from './NudgeContainer';

export type Props = {
  url: string;
};

export const SetupForSuccessNudge = ({ url }: Props) => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();

  return (
    <NudgeContainer>
      <div className="flex flex-row align-center gap-md">
        <div className="flex flex-col justify-start md:justify-center lg:justify-center pt-[12px] md:pt-0 lg:pt-0">
          <TrendingIcon tone="brand" variant="outline" height="large" />
        </div>
        <div className="flex flex-col">
          <Text weight="regular">
            {t('enhanceJobNudge.setupForSuccess.title')}
          </Text>
          <Text size="small">
            {t('enhanceJobNudge.setupForSuccess.description')}
          </Text>
        </div>
      </div>
      <div className="w-full md:w-auto lg:w-auto">
        <Button onPress={redirect(url)}>
          {t('dashboardPage.action.enhanceAd')}
        </Button>
      </div>
    </NudgeContainer>
  );
};

import { BrandName } from '@seek/je-shared-data/lib/types/brand';

const jobTitlesImports = {
  jobstreetExpress_id: () =>
    import('@seek/jora-matchbox/job-title-jse-id.json'),
  jobstreetExpress_sg: () =>
    import('@seek/jora-matchbox/job-title-jse-sg.json'),
  jora_au: () => import('@seek/jora-matchbox/job-title-jora-au.json'),
  jora_gb: () => import('@seek/jora-matchbox/job-title-jora-gb.json'),
  jora_hk: () => import('@seek/jora-matchbox/job-title-jora-hk.json'),
  jora_id: () => import('@seek/jora-matchbox/job-title-jora-id.json'),
  jora_my: () => import('@seek/jora-matchbox/job-title-jora-my.json'),
  jora_nz: () => import('@seek/jora-matchbox/job-title-jora-nz.json'),
  jora_ph: () => import('@seek/jora-matchbox/job-title-jora-ph.json'),
  jora_th: () => import('@seek/jora-matchbox/job-title-jora-th.json'),
  jora_vn: () => import('@seek/jora-matchbox/job-title-jora-vn.json'),
  jobsdb_au: () => import('@seek/jora-matchbox/job-title-jora-sg.json'),
  jobstreetVn_au: () => import('@seek/jora-matchbox/job-title-jora-vn.json'),
  jobstreetVn_vn: () => import('@seek/jora-matchbox/job-title-jora-vn.json')
};

type LoadJobTitles = {
  brandName: BrandName;
  countryCode: string;
};

export default async function loadJobTitles({
  brandName,
  countryCode
}: LoadJobTitles) {
  try {
    const jobTitles = await jobTitlesImports[`${brandName}_${countryCode}`]();

    return jobTitles.default;
  } catch (error) {
    console.error(
      `Failed to load job titles for: ${brandName}-${countryCode}`,
      error
    );
  }
}

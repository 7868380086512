import React from 'react';
import { MobileNumber as InnerField } from '@common/components/Account/components/MobileNumber';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { mapCountryCodeToDiallingCode } from '@common/constants/mapping';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useTranslation } from '@common/hooks/useTranslation';
import { FieldWrapper } from '../FieldWrapper';

export const MobileNumber = () => {
  const { t } = useTranslation();
  const countryCode = useSiteCountryCode();
  const defaultDiallingCode = mapCountryCodeToDiallingCode(countryCode);

  const { mobileNumberRules } = useAccountRules();
  return (
    <FieldWrapper<{ diallingCode: string; phoneNumber: string }>
      name="mobileNumber"
      rules={mobileNumberRules}
      render={(props) => {
        const mergedProps = { ...props, t };
        return (
          <InnerField
            {...mergedProps}
            showExplanationText={true}
            value={
              props.value || {
                diallingCode: defaultDiallingCode,
                phoneNumber: ''
              }
            }
          />
        );
      }}
    />
  );
};

import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import {
  useApplicantStatusTiles,
  useApplicantStatusTilesProps
} from './useApplicantStatusTiles';

type ApplicantStatusTilesProps = useApplicantStatusTilesProps & {
  applications: Application[];
};

export const ApplicantStatusTiles = ({
  applications = [],
  value = [],
  onChange,
  onClear
}: ApplicantStatusTilesProps) => {
  const { t } = useTranslation();

  const { getStatusTileStyle, handleStatusTileClick } = useApplicantStatusTiles(
    {
      onChange,
      onClear,
      value
    }
  );

  return (
    <div className="grid lg:grid-cols-6 grid-cols-3 gap-md">
      {tiles(applications, t).map(({ key, label, value: optionValue }) => (
        <a
          key={key}
          onClick={() => handleStatusTileClick(key)}
          className={`
            ${getStatusTileStyle(key)} border rounded-[4px]
            flex flex-col flex-start h-[100px] px-5 pt-8
            text-center align-items-top content-center hover:no-underline`}
        >
          <div className="text-[20px] font-bold">{optionValue}</div>
          <div className="text-[12px] font-light">{label}</div>
        </a>
      ))}
    </div>
  );
};

export const tiles = (applications: Application[], t: TFunction) => {
  const counts = applications.reduce(
    (acc, { status = APPLICANT_STATUS.NO_STATUS }) => {
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    {}
  );

  const orderedStatuses = [
    APPLICANT_STATUS.NO_STATUS,
    APPLICANT_STATUS.NOT_SUITABLE,
    APPLICANT_STATUS.SHORTLISTED,
    APPLICANT_STATUS.SCHEDULED_INTERVIEW,
    APPLICANT_STATUS.OFFERED_JOB,
    APPLICANT_STATUS.ACCEPTED_OFFER
  ];

  return orderedStatuses.map((status) => {
    const count = counts[status] || 0;

    return {
      key: status,
      label: t(`applications.status.${status}`),
      value: count || '-'
    };
  });
};

import requestKeys from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export const useGetRequestErrors = () => {
  const { error: logoutError } = useAppSelector(
    getRequestState(requestKeys.AUTH.LOGOUT)
  );
  const { error: getUnsubscribeUrlError } = useAppSelector(
    getRequestState(requestKeys.UNSUBSCRIBE.GET_EMAIL_PREFERENCE_CENTER_URL)
  );

  return {
    hasError: Boolean(logoutError || getUnsubscribeUrlError),
    requestKeys: [
      requestKeys.AUTH.LOGOUT,
      requestKeys.UNSUBSCRIBE.GET_EMAIL_PREFERENCE_CENTER_URL
    ]
  };
};

import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.TARGET_LOCATION]?: { locationCode: string }[];
};

type Item = {
  targetLocation?: ProfileApplication['targetLocation'];
};

export const filterTargetLocation = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.TARGET_LOCATION];

  if (!filterValue) return items;
  return items.filter(
    ({ targetLocation }) =>
      targetLocation &&
      filterValue.some(({ locationCode }) => targetLocation === locationCode)
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { BUSINESS_ROLE_OPTIONS } from '@common/constants/data';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { WebDropdown } from '@components/FormElements/Dropdown';

type RoleProps = {
  value: string | undefined;
  onChange: () => void;
  error?: string;
};

export const Role = (props: RoleProps) => {
  const { t } = useTranslation();
  const businessRoleOptions = BUSINESS_ROLE_OPTIONS(t);
  const { error, onChange, value } = props;

  return (
    <Field
      label={t('createBusinessForm.fieldLabel.role')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <WebDropdown
        items={businessRoleOptions}
        selectedItem={value}
        placeholder={t('business.businessRole.select')}
        onChange={onChange}
      />
    </Field>
  );
};

import { FindRecentResponse } from 'je-bff/src/jobs/drafts/findRecent/findRecent';
import { ClientError } from '@common/helpers/errors';
import { trpcBff } from '@trpcBff';

export type UseFindRecentDraftProps = {
  repostJobId?: string;
  queryOpts?: {
    enabled?: boolean;
  };
};

export type DraftJob = ReturnType<typeof useFindRecentDraft>['data'];

export type FindRecentDraft = {
  data: FindRecentResponse;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  error?: ClientError;
};

export const useFindRecentDraft = ({
  repostJobId,
  queryOpts: { enabled = true } = {}
}: UseFindRecentDraftProps = {}): FindRecentDraft => {
  const params = { repostJobId };
  const { data, isError, isFetched, isLoading, error } =
    trpcBff.jobs.drafts.findRecent.useQuery(params, {
      staleTime: 10000,
      retry: false,
      enabled
    });

  return {
    data,
    isLoading,
    isFetched,
    isError,
    error: getClientError(error)
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};

import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { targetLocationRules } from '../../rules';
import { TargetLocationField } from './TargetLocationField';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label?: string | React.ReactNode;
  disabled?: boolean;
};

export function TargetLocation<T extends FieldValues>({
  name,
  control,
  label,
  disabled
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={targetLocationRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <TargetLocationField
          label={label}
          disabled={formState.disabled || disabled}
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}

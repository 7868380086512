import React from 'react';
import { processRichText } from '@src/richText';
import { BaseEditor } from './BaseEditor';
import { Footer } from './Footer';

export interface RichTextProps {
  value: string;
  error?: boolean;
  onBlur?: () => void;
  onChange: (text: string) => void;
  testID?: string;
  charCountProvider?: (count: number) => string;
  showCharCount?: boolean;
  placeholder?: string;
}

export const RichTextInput = ({
  value,
  error = false,
  charCountProvider,
  onBlur,
  onChange,
  testID,
  showCharCount = true,
  placeholder
}: RichTextProps) => {
  const onValueChanged = (text: string) => {
    onChange(processRichText(text));
  };

  return (
    <div
      id="editor-wrapper"
      className={`${error ? 'error' : ''}`}
      data-testid={testID}
    >
      <BaseEditor
        value={value}
        onChange={onValueChanged}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {showCharCount && (
        <Footer value={value} charCountProvider={charCountProvider} />
      )}
    </div>
  );
};

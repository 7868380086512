import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CajaWrapper,
  StepError,
  StepLoader
} from '@common/components/Caja/components';
import {
  useAdTypeState,
  useCajaVariant,
  useGetPaymentIntentParam,
  useTrackCajaEvent
} from '@common/components/Caja/hooks';
import {
  EditJobSuccess,
  JobSummaryAndDesc,
  MultiStepJobEdit,
  enhanceJobRouter,
  getImmediateStartPhrase,
  getRightToWorkDocument,
  isEditJobStep,
  paymentJobRouter
} from '@common/components/Caja/steps';
import { useGetCompletedUrl } from '@common/components/Caja/steps/PaymentJobStep/hooks/useGetCompletedUrl';
import { useBusinessState } from '@common/hooks/business';
import { useFetchJobData } from '@common/hooks/job';
import { useSteps } from '@common/hooks/multiStep';
import { useUpgradePermissions } from '@common/hooks/payment';
import { useTranslation } from '@common/hooks/useTranslation';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { SupportedCountryCode as CountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType, upgraded } from '@src/job';
import { toLowerCase } from '@src/lib/formatting';
import {
  EditJobStepIds,
  GetStepsParams,
  getPageTitle,
  getStepNumber,
  getStepTitle,
  getSteps,
  ignoreIndicator,
  totalNumberOfSteps
} from './stepsInfo';

export function EditJob() {
  useUpdateSourceQuery();

  const { t } = useTranslation();
  const { hasPaymentIntent } = useGetPaymentIntentParam(); // for handling payment race condition; see https://jobseeker.atlassian.net/browse/AD-753?focusedCommentId=57731
  const { jobId } = useParams<{ jobId: string }>();
  const cajaVariant = useCajaVariant();
  const { isUpgradeable } = useUpgradePermissions();
  const { getOnCompletedUrl } = useGetCompletedUrl();
  const {
    data: jobData,
    isLoading: fetchJobLoading,
    error: fetchJobError,
    refetch
  } = useFetchJobData({
    jobId
  });

  const [selectedAdType, setSelectedAdType] = useAdTypeState();
  const [createdJobCountryCode] = useBusinessState(
    toLowerCase<CountryCode>(jobData?.countryCode)
  );

  const isJobUpgradable = isUpgradeable(createdJobCountryCode);

  const { steps, currentStep, nextStep, gotoNextStep, gotoPreviousStep } =
    useSteps<GetStepsParams, EditJobStepIds>({
      data: {
        isUpgradeable: isJobUpgradable,
        selectedAdType,
        hasJobUpgraded: upgraded(jobData?.adType) || hasPaymentIntent
      },
      getSteps
    });

  useEffect(() => {
    // This is to solve MultiStepJobEdit out of date when navigating back to the step
    if (!isEditJobStep(currentStep.id)) refetch();
  }, [currentStep.id, refetch]);

  useTrackCajaEvent({
    step: `edit_${currentStep.id}`,
    variant: `edit-job-${cajaVariant}`,
    jobId
  });

  if (fetchJobLoading) return <StepLoader />;

  const EnhanceJob = enhanceJobRouter({ cajaVariant });
  const PaymentJob = paymentJobRouter({ cajaVariant });

  return (
    <CajaWrapper
      title={getPageTitle(currentStep.id)}
      ignoreIndicator={ignoreIndicator(currentStep.id)}
      step={{
        number: getStepNumber(steps, currentStep.path),
        title: getStepTitle(currentStep.id),
        total: totalNumberOfSteps(steps)
      }}
    >
      {isEditJobStep(currentStep.id) && (
        <MultiStepJobEdit
          onCompleted={gotoNextStep}
          onNext={gotoNextStep}
          onBack={gotoPreviousStep}
          stepInfo={{
            jobId,
            job: jobData!!,
            currentStepId: currentStep.id
          }}
        />
      )}
      {currentStep.id === 'enhanceJob' && (
        <EnhanceJob
          stepInfo={{
            jobId,
            jobCountryCode: createdJobCountryCode,
            disableAdTypeSelector: upgraded(jobData?.adType),
            initialAdType: upgraded(jobData?.adType)
              ? jobData?.adType
              : selectedAdType
          }}
          onCompleted={({ jobAdType, hasJobUpgraded }) => {
            setSelectedAdType(jobAdType);
            gotoNextStep({
              selectedAdType: jobAdType,
              hasJobUpgraded: hasJobUpgraded || upgraded(jobData?.adType)
            });
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'payment' && (
        <PaymentJob
          stepInfo={{
            jobId,
            jobCountryCode: createdJobCountryCode,
            adType: selectedAdType as UpgradeableAdType,
            onCompletedUrl: getOnCompletedUrl(nextStep)
          }}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'jobSummaryAndDesc' && (
        <JobSummaryAndDesc
          stepInfo={{
            jobId,
            showAdTypeBadge: isJobUpgradable,
            adType: selectedAdType,
            submitText: t('postJobForm.action.updateJob'),
            rightToWorkDocument: getRightToWorkDocument(cajaVariant),
            immediateStartPhrase: getImmediateStartPhrase(cajaVariant)
          }}
          onCompleted={gotoNextStep}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'editJobSuccess' && <EditJobSuccess />}

      {fetchJobError && <StepError error={fetchJobError} />}
    </CajaWrapper>
  );
}

import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements/SingleSelect';
import { useTranslation } from '@common/hooks/useTranslation';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { options as defaultOptions } from './options';

type Props = {
  onChange: (key: boolean) => void;
  options?: { label: string; key: boolean }[];
  value?: boolean;
  error?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
};

export const RightToWorkRadioField = ({
  value,
  options,
  onChange,
  error,
  disabled = false,
  label
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <Field
        label={label || t('postJobForm.fieldLabel.rightToWorkVisa')}
        message={
          error && (
            <Box marginTop="xsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <SingleSelect<boolean>
            disabled={disabled}
            testID="job-form-rightToWork"
            onChange={onChange}
            options={options || defaultOptions(t)}
            value={value}
          />
        </Box>
      </Field>
    </Stack>
  );
};

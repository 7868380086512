import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { List } from '@components/List';
import { Text } from '@components/Text';
import {
  useWhatHappensNext,
  useWhatHappensNextActions
} from './useWhatHappensNext';

export const WhatHappensNextInfo = () => {
  const { isRealTimeJobPostingBrand, brandNameText } = useWhatHappensNext();

  return (
    <div className="flex flex-col pt-[24px] p-lg mt-md mb-md bg-background-neutral">
      {isRealTimeJobPostingBrand && brandNameText ? (
        <LiveJobPostContent brandNameText={brandNameText} />
      ) : (
        <NonLiveJobPostContent />
      )}

      <Actions />
    </div>
  );
};

export const LiveJobPostContent = ({
  brandNameText
}: {
  brandNameText: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-md" data-testid="live-job-post-content">
      <Heading level="h2">
        {t('postJobSuccessPage.temporary.firstTimePoster.heading', {
          brandNameText
        })}
      </Heading>
      <Text>
        {t('postJobSuccessPage.temporary.firstTimePoster.description')}
      </Text>

      <Heading level="h2">
        {t('postJobSuccessPage.temporary.verifiedPoster.heading', {
          brandNameText
        })}
      </Heading>
      <Text>
        {t('postJobSuccessPage.temporary.verifiedPoster.description')}
      </Text>
    </div>
  );
};

export const NonLiveJobPostContent = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col gap-md"
      data-testid="non-live-job-post-content"
    >
      <Heading level="h2">
        {t('postJobSuccessPage.description.whatHappensNow')}
      </Heading>
      <List variant="bullet">
        <Text>{t('postJobSuccessPage.description.weAreReviewing')}</Text>
        <Text>{t('postJobSuccessPage.description.ifYouAlready')}</Text>
      </List>

      <Heading level="h2">
        {t('postJobSuccessPage.description.whatHappensNext')}
      </Heading>
      <Text>{t('postJobSuccessPage.description.startManagingJobAds')}</Text>
      <List variant="number">
        <Text>{t('postJobSuccessPage.description.editOrCloseJob')}</Text>
        <Text>
          {t('postJobSuccessPage.description.reviewAndShortlistApplicants')}
        </Text>
      </List>
    </div>
  );
};

const Actions = () => {
  const { t } = useTranslation();
  const { redirectToHomePage, redirectToPostJobPage } =
    useWhatHappensNextActions();

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-md mt-md ">
      <Button onPress={redirectToPostJobPage}>
        {t('postJobSuccessPage.action.postAnotherJob')}
      </Button>

      <Button onPress={redirectToHomePage} variant="secondary">
        {t('common.action.backToHome')}
      </Button>
    </div>
  );
};

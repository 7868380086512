import React from 'react';
import { useForm } from 'react-hook-form';
import { Business, JobTitle } from '@common/components/Job/formFields';
import { RedirectTo } from '@common/search';
import { NavControls } from '../components';
import { FormData } from './types';

type Props = {
  defaultValues: FormData;
  onSubmit: (data: FormData) => void;
  onBack: () => void;
  businessOptions: {
    isBusinessEditable?: boolean;
    redirectCreateBusinessTo?: RedirectTo;
  };
};

export const TitleAndBusinessForm = ({
  defaultValues,
  businessOptions: { isBusinessEditable = true, redirectCreateBusinessTo },
  onSubmit,
  onBack
}: Props) => {
  const { handleSubmit, control, formState } = useForm<FormData>({
    defaultValues
  });

  const handleFormSubmitForStep = async (data: FormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <JobTitle name="jobTitle" control={control} />

        <Business
          name="business"
          control={control}
          isBusinessEditable={isBusinessEditable}
          shouldRedirectBusinessCreatedTo={redirectCreateBusinessTo}
        />

        <NavControls
          hideBackButton
          onBack={onBack}
          onNext={handleSubmit(handleFormSubmitForStep)}
          disabled={formState.isSubmitting}
        />
      </div>
    </form>
  );
};

import React, { ReactNode, forwardRef } from 'react';
import { View } from 'react-native';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { FieldStyleProps, useFieldStyle } from './useFieldStyle';

export type FieldProps = {
  children: ReactNode;
  label: string | ReactNode;
  message?: ReactNode;
  testID?: string;
  explanationText?: string;
} & FieldStyleProps;

export const Field = forwardRef<View, FieldProps>((props, ref) => {
  const { label, children, message, testID, ...restProps } = props;
  const resolvedStyle = useFieldStyle(restProps);

  return (
    <View style={resolvedStyle} testID={testID} ref={ref}>
      {typeof label === 'string' ? (
        <Box tone="transparent" marginBottom="xxsmall">
          <Text weight="regular">{label}</Text>
        </Box>
      ) : (
        label
      )}
      {children}
      {message ? message : null}
    </View>
  );
});

import { useLocation } from 'react-router-dom';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { getUser } from '@store/auth/selectors';
import { useAppSelector } from '@store/hooks';
import { shouldHideNavMenu } from '../helpers/shouldHideNavMenu';

/**
 * Custom hook that determines whether the navigation menu should be hidden.
 * It checks the user's authentication status, current user, country code, and current path.
 *
 * @returns {boolean} True if the navigation menu should be hidden, false otherwise.
 */
export const useIsNavMenuHidden = () => {
  const { isAuthenticated, currentUser } = useAppSelector(getUser);
  const countryCode = useSiteCountryCode();
  const { pathname } = useLocation();

  return shouldHideNavMenu({
    isAuthenticated,
    currentUser,
    countryCode,
    currentPath: pathname
  });
};

import React from 'react';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <path
      d="M9.5 4.5H13.5V17.5H0.5V4.5H4.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 9.5V1"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 2L6.80685 0.592588C6.93014 0.467868 7.13562 0.467868 7.25069 0.600383L9 2"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const ShareIcon = createIcon({
  viewBox: '0 0 14 18',
  vectorComponent
});

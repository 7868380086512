import { getQueryKey } from '@trpc/react-query';
import { trpcBff } from '@trpcBff';
import { UseFindRecentDraftProps } from './useFindOrCreateDraftForRepost';

export const findOrCreateRepostDraftKey = ({
  repostJobId,
  forceNewDraft
}: UseFindRecentDraftProps) => {
  return getQueryKey(
    trpcBff.jobs.drafts.findOrCreateDraftForRepost,
    {
      repostJobId,
      forceNewDraft
    },
    'query'
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { WebDropdown } from '@components/FormElements/Dropdown';
import { useCountrySelector } from './hooks/useCountrySelector';

type CountrySelectorProps = {
  value: string | undefined;
  onChange: () => void;
  error?: string;
};

export const CountrySelector = (props: CountrySelectorProps) => {
  const { t } = useTranslation();
  const { filteredCountryOptions } = useCountrySelector();
  const { error, onChange, value } = props;

  return (
    <Field
      label={t('createBusinessForm.fieldLabel.country')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <WebDropdown
        items={filteredCountryOptions}
        selectedItem={value}
        onChange={onChange}
        placeholder={filteredCountryOptions[0].label}
        testID="business-form-countrySelector"
      />
    </Field>
  );
};

import React from 'react';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <path
      d="M13.5 5.5H4.5V17.5H13.5V5.5Z"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 12.5H0.5V0.5H9.5V3.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

export const CopyIcon = createIcon({
  viewBox: '0 0 14 18',
  vectorComponent
});

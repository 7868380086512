import React from 'react';
import isMatch from 'lodash.ismatch';
import { ProfileLocation } from '@common/types';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Accordion } from '@components/Accordion';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';
import { sortByPrimaryCountry } from './sortByPrimaryCountry';
import { useLocationOptions } from './useLocationOptions';
import { LocatableItem } from './useLocationOptions/type';

type LocationFilterProps = {
  profileApplications: LocatableItem[];
  onChange: (value: ProfileLocation[]) => void;
  onClear: () => void;
  value?: ProfileLocation[];
  primaryCountry?: SupportedCountryCode;
};

export const LocationFilter = ({
  primaryCountry,
  profileApplications,
  onChange,
  onClear,
  value = []
}: LocationFilterProps) => {
  const locationOptions = useLocationOptions(profileApplications);

  const handleCheckbox = (checkValue: ProfileLocation) => {
    const isCurrentlySelected = !!value.find((opt) => isMatch(checkValue, opt));
    const newValue = isCurrentlySelected
      ? value.filter((item) => !isMatch(item, checkValue))
      : value.concat(checkValue);

    return onChange(newValue);
  };

  const options = Object.entries(locationOptions)
    .sort(([countryA], [countryB]) =>
      sortByPrimaryCountry(countryA, countryB, primaryCountry)
    )
    .map(([, countryOptions]) => ({
      label: countryOptions[0]?.value.countryName,
      content: (
        <div className="flex flex-wrap">
          {countryOptions.map((option) => (
            <div className="w-full md:w-1/2 mb-6">
              <Checkbox
                key={option.label}
                value={
                  !!value.find((selected) => isMatch(option.value, selected))
                }
                label={`${option.label} (${option.counter})`}
                onChange={() => handleCheckbox(option.value)}
              />
            </div>
          ))}
        </div>
      )
    }));

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-end">
        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <Accordion items={options} />
    </div>
  );
};

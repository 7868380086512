import { Component } from 'react';
import PropTypes from 'prop-types';
import { WebDropdown } from '../../../../design-system/components/FormElements/Dropdown';
import Label from '../../Label/Label';
import ReduxFormField from '../ReduxFormField/ReduxFormField';

// @deprecated - use WebDropdown instead. Supporting legacy that uses ReduxForm
@ReduxFormField
export default class Dropdown extends Component {
  static propTypes = {
    'data-test-key': PropTypes.string,
    error: PropTypes.any,
    touched: PropTypes.bool,
    fieldId: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired
      })
    ),
    initialValue: PropTypes.string,
    value: PropTypes.string
  };

  onChange(value) {
    const { onChange } = this.props;

    if (onChange) onChange(value);
  }

  render() {
    const {
      fieldId,
      initialValue,
      touched,
      error,
      options,
      label,
      'data-test-key': dataTestKey,
      hideLabel,
      value
    } = this.props;

    return (
      <div>
        {hideLabel ? null : <Label labelFor={fieldId}>{label}</Label>}
        <WebDropdown
          testID={`dropdown-${dataTestKey || fieldId}`}
          items={options}
          onChange={(newValue) => this.onChange(newValue)}
          selectedItem={value || initialValue}
          error={touched && error}
        />
      </div>
    );
  }
}

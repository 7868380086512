import React from 'react';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useTranslation } from '@common/hooks/useTranslation';
import { PageHeader } from '@components/PageHeader';
import { StepProgressIndicator } from './StepProgressIndicator/StepProgressIndicator';

export interface CajaWrapperProps {
  children: React.ReactNode;
  title?: string;
  step: { title: string; number: number; total: number };
  ignoreIndicator?: boolean;
}

export default function CajaWrapper(props: CajaWrapperProps) {
  const { title, step, ignoreIndicator = false } = props;
  const { t } = useTranslation();

  const showIndicator = !ignoreIndicator && step.total > 1;

  return (
    <div className="flex flex-col">
      {title && (
        <div>
          <JoraEmployerHelmet title={t(title)} />
          <PageHeader>{t(title)}</PageHeader>
        </div>
      )}

      {showIndicator && (
        <StepProgressIndicator
          stepTitle={step.title}
          currentStepNumber={step.number}
          totalSteps={step.total}
        />
      )}

      <div z-index={1}>{props.children}</div>
    </div>
  );
}

import { REGEX } from '@common/constants/data';
import {
  MAX_LENGTH,
  WEBSITE_URL_MAX_LENGTH
} from '@common/constants/validation';
import type { T } from '@common/hooks/useTranslation';

export const businessWebsiteRules = (t: T) => {
  return {
    [MAX_LENGTH]: {
      value: WEBSITE_URL_MAX_LENGTH,
      message: t('validations.business.website.maxLength', {
        max: WEBSITE_URL_MAX_LENGTH
      })
    },
    validate: (value: string) => {
      if (!value) return;

      if (!REGEX.WEBSITE_FORMAT.test(value)) {
        return t('validations.business.website.format');
      }
      if (REGEX.ANGLE_BRACKET.test(value)) {
        return t('validations.business.website.noAngleBracket');
      }

      return;
    }
  };
};

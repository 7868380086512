import { useGetJobForEdit } from '@common/hooks/job';
import { toFormData } from '../transformers/job/toFormData';

export const useMapJobToFormData = (jobId: string) => {
  const { data: job, isLoading } = useGetJobForEdit({
    jobId,
    queryOptions: { retry: true } // Because of data-syncing issues
  });

  return {
    formData: toFormData(job),
    isLoading
  };
};

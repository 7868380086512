import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useGetAdDuration } from '@common/hooks/job';
import { useGetCoinPrice } from '@common/hooks/upgradeJobAd';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { BaseUpgradeSelector } from '../BaseUpgradeSelector';
import { PremiumUpgradeSelector } from '../PremiumUpgradeSelector';
import { CoinAmount } from '../PriceInformation';

type Props = {
  jobCountryCode: SupportedCountryCode;
  onChange: (adType: AD_TYPE) => void;
  value: AD_TYPE;
  disabled?: boolean;
  showBasicAdType?: boolean;
};

/**
 * Component to display coin payment options for job ads
 */
export const CoinUpgradeSelector = (props: Props) => {
  const {
    jobCountryCode,
    onChange,
    value,
    disabled = false,
    showBasicAdType = true
  } = props;
  const { pricingText, fetchErrorMessage } = useGetCoinPrice({
    jobCountryCode
  });
  const { adDuration } = useGetAdDuration(jobCountryCode);

  if (!pricingText) return null;

  if (showBasicAdType) {
    return (
      <div className="flex flex-col gap-lg">
        <div className="flex flex-col gap-md" data-testid="coin-ad-panel">
          <BaseUpgradeSelector
            adDuration={adDuration}
            disabled={disabled}
            price={{
              [AD_TYPE.PLUS]: <CoinAmount price={pricingText[AD_TYPE.PLUS]} />,
              [AD_TYPE.MAX]: <CoinAmount price={pricingText[AD_TYPE.MAX]} />
            }}
            value={value}
            onChange={onChange}
          />
        </div>

        <ErrorMessage>{fetchErrorMessage}</ErrorMessage>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-lg">
      <div className="flex flex-col gap-md" data-testid="coin-ad-panel">
        <PremiumUpgradeSelector
          adDuration={adDuration}
          disabled={disabled}
          price={{
            [AD_TYPE.PLUS]: <CoinAmount price={pricingText[AD_TYPE.PLUS]} />,
            [AD_TYPE.MAX]: <CoinAmount price={pricingText[AD_TYPE.MAX]} />
          }}
          value={value}
          onChange={onChange}
        />
      </div>

      <ErrorMessage>{fetchErrorMessage}</ErrorMessage>
    </div>
  );
};

import type { T } from '@common/hooks/useTranslation';

interface SurnameErrors {
  required: string;
}

export const surname = (t: T) => {
  const errors = t('validations.user.surname', {
    returnObjects: true
  }) as SurnameErrors;

  return {
    required: errors.required
  };
};

// THIS IS GENERIC ENOUGH TO MOVE ELSEWHERE
import { ClientError } from '@common/helpers/errors';
import { updateError } from '@store/app';
import { useAppDispatch } from '@store/hooks';

export function useGlobalFormError() {
  const dispatch = useAppDispatch();

  return (requestKey: string) => {
    updateError(
      dispatch,
      requestKey,
      new ClientError('errors.defaultWithGuid')
    );
  };
}

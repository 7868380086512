import React from 'react';
import { useTranslation } from 'react-i18next';
import { PricingPanel } from '@common/components/PricingPanel';
import { AD_TYPE } from '@seek/je-shared-data';
import { Heading } from '@components/Heading';

type Props = {
  adType: AD_TYPE.PLUS | AD_TYPE.MAX;
  currencyPrice: {
    [AD_TYPE.PLUS]: string;
    [AD_TYPE.MAX]: string;
  };
  coinPrice: {
    [AD_TYPE.PLUS]: number | undefined;
    [AD_TYPE.MAX]: number | undefined;
  };
};

export const BasePricingPanel = (props: Props) => {
  const { adType, coinPrice, currencyPrice } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-[12px]">
        <Heading level="h2">{t('paymentPage.heading')}</Heading>

        <PricingPanel
          adType={adType}
          coinPrice={coinPrice[adType]!}
          currencyPrice={currencyPrice[adType]!}
        />
      </div>
    </div>
  );
};

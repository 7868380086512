import type {
  FormProps,
  IncompleteBusinessFormData,
  OnSubmitCallback
} from '../types';

export interface HydratedBusinessFormProps {
  defaultValues: IncompleteBusinessFormData;
  onSubmit: OnSubmitCallback;
  submitBtnText: string;
  showCountrySelector: boolean;
}

export function hydrateBusinessFormProps(
  props: FormProps
): HydratedBusinessFormProps {
  return {
    defaultValues: props.defaultValues ?? {},
    onSubmit: props.onSubmit ?? (() => {}),
    submitBtnText: props.submitBtnText ?? '',
    showCountrySelector: props.showCountrySelector ?? true
  };
}

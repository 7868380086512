import React from 'react';
import CreateBusinessForm from '@common/components/Business/forms/createBusiness/BusinessForm';
import { CompleteBusinessFormData } from '@common/components/Business/forms/createBusiness/types';
import { StepWrapper } from '@common/components/Caja/components';
import { useCreateBusiness } from '@common/hooks/business';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useShowCountrySelector } from '@common/hooks/useShowCountrySelector';
import { useTranslation } from '@common/hooks/useTranslation';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type Props = Pick<TopLevelStep<undefined>, 'onCompleted'>;

export function CreateBusiness({ onCompleted }: Props) {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const createBusiness = useCreateBusiness();
  const sourceParams = useGetSourceParams();
  const showCountrySelector = useShowCountrySelector();
  const { countryCode: siteCountryCode } = useAppSelector(getLocalisation);

  // Create business
  const onCreateBusinessSubmit = async (business: CompleteBusinessFormData) => {
    const { isSuccessful, data } = await createBusiness({
      business,
      sourceParams
    });

    if (isSuccessful) {
      onCompleted(data);
    }
  };

  return (
    <StepWrapper>
      <CreateBusinessForm
        defaultValues={{ country: siteCountryCode }}
        onSubmit={onCreateBusinessSubmit}
        submitBtnText={t('common.action.next')}
        showCountrySelector={showCountrySelector}
      />
    </StepWrapper>
  );
}

import { EnhanceJobStepIds } from './stepConfig';

type StepTitles<T extends string> = {
  [key in T]: {
    pageTitle: string;
  };
};

const stepsTitleInfo: StepTitles<EnhanceJobStepIds> = {
  enhanceJob: {
    pageTitle: 'enhanceJob.heading'
  },
  payment: {
    pageTitle: 'paymentJobStep.payWithCardHeading'
  },
  paymentSuccess: {
    pageTitle: ''
  }
};

export const getPageTitle = (stepId: EnhanceJobStepIds) =>
  stepsTitleInfo[stepId].pageTitle;

import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';

export function useAccountDetailsRedirect() {
  const { redirect } = useRedirect();
  const { checkQueryParam } = useSearchQueryParam();
  const { postJobLink } = useGetPostJobLink();
  const countryCode = useSiteCountryCode();

  const onCancelRedirect = () => {
    const fromPostJobFlow = checkQueryParam('fromPostJob');
    const redirectLink = fromPostJobFlow
      ? postJobLink
      : `/${countryCode}/account/`;
    return redirect(redirectLink)();
  };

  const onSubmitRedirect = (emailVerified: boolean) => {
    const fromPostJobFlow = checkQueryParam('fromPostJob');

    if (!emailVerified) {
      return redirect(
        `/${countryCode}/account/verify-email?fromUpdateAccount=true`
      )();
    }

    if (emailVerified && fromPostJobFlow) {
      const redirectLink = `/${countryCode}/post-job`;
      return redirect(redirectLink)();
    }

    return redirect(`/${countryCode}/account`)();
  };

  return {
    onCancelRedirect,
    onSubmitRedirect
  };
}

import React from 'react';
import { t } from 'i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { ClientError } from '@common/helpers/errors';

type StepErrorProps = {
  error?: ClientError;
};

export const StepError = ({ error }: StepErrorProps) => {
  if (!error) return null;

  return (
    <div className="w-full text-center mt-8">
      <ErrorMessage>{t(error.message, { ...error.properties })}</ErrorMessage>
    </div>
  );
};

import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, CardContent } from '@components/Card';
import Div from '../../../common/components/Div/Div';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import * as appActions from '../../store/app/actions';

/**
 * @deprecated Use the RequestErrorMessage component instead.
 */
@connect(
  ({ app }) => ({
    error: app.error,
    submissionError: app.submissionError
  }),
  {
    clearError: appActions.clearError
  }
)
@withTranslation()
export default class ConnectedErrorMessage extends Component {
  static propTypes = {
    requestKey: PropTypes.string.isRequired,
    error: PropTypes.object,
    submissionError: PropTypes.object,
    withCard: PropTypes.bool,
    hideWithSubmissionError: PropTypes.bool,
    clearError: PropTypes.func.isRequired,
    extraTopMargin: PropTypes.bool,
    children: PropTypes.node,
    t: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string,
    className: PropTypes.string,
    additionCondition: PropTypes.func
  };

  componentWillUnmount() {
    const { requestKey, clearError } = this.props;

    clearError(requestKey);
  }

  renderError(children) {
    const {
      withCard,
      'data-test-key': dataTestKey
    } = this.props;

    return (
      <Div data-test-key={dataTestKey || 'connected-error-message'}>
        {withCard ? (
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        ) : (
          children
        )}
      </Div>
    );
  }

  render() {
    const {
      error,
      hideWithSubmissionError,
      submissionError,
      requestKey,
      extraTopMargin,
      children,
      t,
      className,
      additionCondition
    } = this.props;

    if (hideWithSubmissionError && submissionError[requestKey]) {
      return <Fragment />;
    }

    return error[requestKey] &&
      (!additionCondition ||
        additionCondition(error[requestKey].properties)) ? (
      this.renderError(
        <ErrorMessage extraTopMargin={extraTopMargin} className={className}>
          {error[requestKey] &&
            t(error[requestKey].message, error[requestKey].properties)}
        </ErrorMessage>
      )
    ) : (
      <Fragment>{children}</Fragment>
    );
  }
}

import { useEffect } from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';
import { useCreatePaymentIntent } from './useCreatePaymentIntent';

type Params = {
  campaign: string;
  jobCountryCode: SupportedCountryCode;
  jobId: string;
  adType: UpgradeableAdType;
  sourceParams?: string;
  currencyPrice: {
    [AD_TYPE.PLUS]: string;
    [AD_TYPE.MAX]: string;
  };
};

export const useSetupPayment = ({
  campaign,
  jobCountryCode,
  jobId,
  adType,
  currencyPrice,
  sourceParams
}: Params) => {
  const pricingText = currencyPrice[adType];

  // Check if the price contains a number instead of a dash
  const isPriceAvailable = /\d/.test(currencyPrice[adType]);

  const setupPayment = useCreatePaymentIntent({
    jobCountryCode,
    jobId,
    campaign,
    sourceParams
  });

  useEffect(() => {
    if (isPriceAvailable) {
      setupPayment(adType, pricingText);
    }
    // Only run this effect again when the price is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPriceAvailable]);
};

import { groupLocationFilterOptionsByCountryCode } from './groupLocationFilterOptionsByCountryCode';
import { mapLocationFilterOptions } from './mapLocationFilterOptions';
import { sortLocationFilterOptions } from './sortLocationFilterOptions';
import { GroupedLocationFilterOptions } from './type';
import { LocatableItem } from './type';

/**
 * @param {LocatableItem[]} profileApplications Profile applications
 * @returns {GroupedLocationFilterOptions} Location filter options
 */
export const useLocationOptions = (
  profileApplications: LocatableItem[]
): GroupedLocationFilterOptions => {
  const filterOptions = mapLocationFilterOptions(profileApplications);
  const sortedFilterOptions = sortLocationFilterOptions(filterOptions);

  return groupLocationFilterOptionsByCountryCode(sortedFilterOptions);
};

import { Platform } from 'react-native';
import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { colors } from '@theme/tokens/colors';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type AutoSuggestTone = 'neutral' | 'success' | 'danger' | 'successMuted';

type AutoSuggestStyleProps = {
  tone?: AutoSuggestTone;
  fullWidth?: boolean;
};

export default function useAutoSuggestStyle({
  tone = 'neutral',
  fullWidth
}: AutoSuggestStyleProps) {
  const { formElement, border } = useTheme();
  const { resolveToken } = useResolveToken();
  const { container, color: formElementColor } = formElement;
  const { borderColor } = formElementColor[tone];

  return {
    container: {
      width: fullWidth ? '100%' : container.maxWidth,
      zIndex: 11
    },
    suggestionList: {
      zIndex: 1,
      width: '100%',
      top: 50,
      ...(Platform.OS === 'web' ? { maxHeight: 240 } : { maxHeight: 445 }),
      backgroundColor: resolveToken(colors, 'white'),
      position: 'absolute',
      borderColor,
      borderWidth: resolveToken(border.width, container.borderWidth),
      borderTopWidth: 0,
      borderBottomEndRadius: resolveToken(border.radius, 'small'),
      borderBottomStartRadius: resolveToken(border.radius, 'small')
    },
    placeholder: {
      color: resolveToken(colors, 'grey600')
    }
  } as const;
}

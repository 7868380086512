import React from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import { MaxAdCard, PlusAdCard } from '../AdTypeCard';

export type Props = {
  price: {
    [AD_TYPE.PLUS]: React.ReactNode;
    [AD_TYPE.MAX]: React.ReactNode;
  };
  onChange: (adType: AD_TYPE) => void;
  value: AD_TYPE;
  adDuration?: {
    [AD_TYPE.PLUS]: number;
    [AD_TYPE.MAX]: number;
  };
  disabled?: boolean;
};

export const AdUpgradeSelector = (props: Props) => {
  const { adDuration, value, onChange, price, disabled = false } = props;

  return (
    <div className="flex flex-col gap-sm">
      <div className="flex flex-col md:flex-row lg:flex-row gap-sm">
        <div className="flex md:w-1/2 lg:w-1/2">
          <PlusAdCard
            adDuration={adDuration?.[AD_TYPE.PLUS]}
            onChange={onChange}
            value={value}
            disabled={disabled}
            price={price[AD_TYPE.PLUS]}
          />
        </div>
        <div className="flex md:w-1/2 lg:w-1/2">
          <MaxAdCard
            adDuration={adDuration?.[AD_TYPE.MAX]}
            onChange={onChange}
            value={value}
            disabled={disabled}
            price={price[AD_TYPE.MAX]}
          />
        </div>
      </div>
    </div>
  );
};

import { getNormalisedLocation } from './getNormalisedLocation';
import { LocationFilterOption } from './type';

export const sortLocationFilterOptions = (items: LocationFilterOption[]) => {
  return items.sort((optionA, optionB) => {
    const {
      countryName: countryA,
      district: districtA,
      subDistrict: subDistrictA
    } = getNormalisedLocation(optionA.value);

    const {
      countryName: countryB,
      district: districtB,
      subDistrict: subDistrictB
    } = getNormalisedLocation(optionB.value);

    if (countryA! > countryB) {
      return 1;
    } else if (countryA! < countryB) {
      return -1;
    }

    if (districtA! > districtB!) {
      return 1;
    } else if (districtA! < districtB!) {
      return -1;
    }

    if (subDistrictA! > subDistrictB!) {
      return 1;
    } else if (subDistrictA! < subDistrictB!) {
      return -1;
    }

    return -1;
  });
};

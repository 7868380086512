import { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@components/Box';
import { Text } from '@components/Text';

export default class Label extends Component {
  static propTypes = {
    labelFor: PropTypes.string,
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return (
      <Box tone="transparent" marginBottom="xxsmall">
        <Text weight="regular">{children}</Text>
      </Box>
    );
  }
}

import React, { useEffect, useRef, useState } from 'react';
import {
  CoinUpgradeSelector,
  DisclaimerText
} from '@common/components/upgradeJobAd';
import { AD_TYPE } from '@seek/je-shared-data';
import { EnhancedJobProps } from '../types';
import {
  EnhanceFormData,
  EnhanceFormRef,
  EnhanceJobForm
} from './EnhanceJobForm';

export type OnSubmitValues = EnhanceFormData & {
  adType: AD_TYPE;
};

type DefaultValues = Partial<EnhanceFormData> | undefined;

export type Props = EnhancedJobProps<OnSubmitValues, DefaultValues> & {
  onNext: (values: OnSubmitValues | undefined) => void;
};

export const EnhanceJob = (props: Props) => {
  const {
    jobCountryCode,
    defaultValues,
    onSubmit,
    onBack,
    onNext,
    onChangeAdType,
    selectedAdType,
    onNextText,
    hideBackButton,
    hideBasicAdType = false,
    disableSelector = false
  } = props;
  const [adType, setAdType] = useState<AD_TYPE>(selectedAdType || AD_TYPE.PLUS);
  const formRef = useRef<EnhanceFormRef | null>(null);

  useEffect(() => {
    onChangeAdType(adType);
  }, [adType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectorChange = (selected: AD_TYPE) => {
    setAdType(selected);
    formRef.current?.clearErrors();

    if (selected === AD_TYPE.BASIC) {
      formRef.current?.resetFormValues({
        rightToWork: null,
        immediateStart: null,
        videoScreeningQuestion: null
      } as any);
    }
  };

  return (
    <div className="flex flex-col gap-lg">
      <CoinUpgradeSelector
        value={adType}
        onChange={onSelectorChange}
        jobCountryCode={jobCountryCode}
        disabled={disableSelector}
        showBasicAdType={!hideBasicAdType}
      />

      <EnhanceJobForm
        ref={formRef}
        jobCountryCode={jobCountryCode}
        disabled={adType === AD_TYPE.BASIC}
        onNext={(values) => onNext(values && { ...values, adType })}
        onBack={onBack}
        onSubmit={(values) => onSubmit(values && { ...values, adType })}
        onNextText={onNextText}
        hideBackButton={hideBackButton}
        defaultValues={defaultValues}
      />

      <DisclaimerText jobCountryCode={jobCountryCode} />
    </div>
  );
};

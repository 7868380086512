import { type RightToWork } from '@common/constants/data';
import {
  useGetUserPackage,
  useUserHasPremiumFilterAccess
} from '@common/hooks/userPackages';
import {
  ApplicationStatus,
  HighestEducation,
  ProfileLocation
} from '@common/types';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';
import { trackApplicationFiltersApplied } from '@store/application_filters';
import { useAppDispatch } from '@store/hooks';
import { ApplicationSortValue, FILTERS } from '../../../../types';
import {
  getLocationLabel,
  getNormalisedLocation
} from '../../components/FiltersModal/components/FiltersContent/components';

export function useTrackFiltersApplied({
  filters
}: {
  filters: {
    [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
    [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
    [FILTERS.CITIZENSHIP]?: string[];
    [FILTERS.DISTANCE]?: number;
    [FILTERS.EDUCATION]?: HighestEducation[];
    [FILTERS.LOCATION]?: ProfileLocation[];
    [FILTERS.RIGHT_TO_WORK]?: RightToWork[];
    [FILTERS.VIDEO_SCREENING]?: boolean;
    [FILTERS.WORK_EXPERIENCE]?: WorkExperience[];
  };
}) {
  const dispatch = useAppDispatch();
  const userPackage = useGetUserPackage();
  const userHasPremiumFilters = useUserHasPremiumFilterAccess();

  return () =>
    dispatch(
      trackApplicationFiltersApplied({
        userPackageType: userPackage.packageType,
        userHasPremiumFilters,
        applicantStatusFilter: filters.applicant_status,
        citizenshipFilter: filters.citizenship,
        distanceFilter: filters.distance,
        educationFilter: filters.education,
        videoScreeningFilter: filters.video_screening,
        rightToWorkFilter: filters.right_to_work,
        sortBy: filters.sort_applications,
        workExperienceFilter: filters.work_experience,
        locationFilter: filters.location
          ?.map(getNormalisedLocation)
          .map((loc) => ({
            [loc.countryCode]: getLocationLabel(loc)
          }))
      })
    );
}

import { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Recaptcha from 'react-recaptcha';
import Div from '../Div/Div';
import ExternalLink from '../ExternalLink/ExternalLink';
import styles from './RecaptchaBadge.scss';

export default class RecaptchaBadge extends Component {
  static propTypes = {
    sitekey: PropTypes.string,
    bypassVerify: PropTypes.bool,
    insideCard: PropTypes.bool,
    onVerify: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.data = {};
    this.execute = this.execute.bind(this);
    this.recaptchaInstance = createRef();

    this.state = {
      isReady: false
    };
  }

  onloadCallback = () => {
    this.setState({ isReady: true });
  };

  verifyCallback = (recaptchaResponse) => {
    const { onVerify } = this.props;
    onVerify({
      ...this.data,
      recaptchaResponse
    });
  };

  expiredCallback = () => {
    this.recaptchaInstance.current.reset();
  };

  execute(data) {
    this.data = data;
    this.recaptchaInstance.current.execute();
  }

  renderRecaptchaComponent() {
    const { sitekey, insideCard } = this.props;
    return [
      sitekey && (
        <Recaptcha
          key="recaptcha-component"
          id="recaptcha-component"
          ref={this.recaptchaInstance}
          sitekey={sitekey}
          size="invisible"
          render="explicit"
          badge="inline"
          onloadCallback={this.onloadCallback}
          verifyCallback={this.verifyCallback}
          expiredCallback={this.expiredCallback}
        />
      ),
      <div
        key="recaptcha-text"
        className={classNames(styles.text, {
          [styles.reducedTopMargin]: !insideCard
        })}
      >
        <Trans
          i18nKey="common.message.recaptchaExplanationText"
          components={[
            <ExternalLink
              href="https://policies.google.com/privacy"
              target="_blank"
            />,
            <ExternalLink
              href="https://policies.google.com/terms"
              target="_blank"
            />
          ]}
        />
      </div>
    ];
  }

  render() {
    const { insideCard } = this.props;

    if (insideCard) {
      return (
        <Div
          marginTop="medium"
          className="captcha-block"
          data-test-key="recaptcha"
        >
          {this.renderRecaptchaComponent()}
        </Div>
      );
    }

    return (
      <Div className="captcha-block" data-test-key="recaptcha">
        {this.renderRecaptchaComponent()}
      </Div>
    );
  }
}

import {
  getStepNumberForIndicator,
  getTotalStepsForIndicator
} from '@common/hooks/multiStep';
import { StepIndicatorInfo, StepPathInfo } from '@common/hooks/multiStep/types';
import { PostJobStepIds } from './stepConfig';

const stepsIndicatorInfo: StepIndicatorInfo<PostJobStepIds> = {
  enhanceJob: {
    title: 'enhanceJobStep.stepTitle',
    pageTitle: 'enhanceJobStep.heading'
  },
  payment: {
    title: '',
    pageTitle: 'paymentJobStep.payWithCardHeading',
    ignoreIndicator: true
  },
  jobSummaryAndDesc: {
    title: 'summaryAndDescStep.stepTitle',
    pageTitle: 'summaryAndDescStep.heading'
  },
  postJobSuccess: {
    title: '',
    pageTitle: '',
    ignoreIndicator: true
  },
  title: {
    title: 'postJobTitleStep.stepTitle',
    pageTitle: 'postJobTitleStep.heading'
  },
  basicDetails: {
    title: 'postJobBasicDetailsStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  },
  editTitle: {
    title: 'postJobTitleStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  },
  editBasicDetails: {
    title: 'postJobBasicDetailsStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  }
};

export const getPageTitle = (stepId: PostJobStepIds) =>
  stepsIndicatorInfo[stepId].pageTitle;

export const getStepTitle = (stepId: PostJobStepIds) =>
  stepsIndicatorInfo[stepId].title;

export const ignoreIndicator = (stepId: PostJobStepIds) =>
  Boolean(stepsIndicatorInfo[stepId].ignoreIndicator);

export const totalNumberOfSteps = (steps: StepPathInfo<PostJobStepIds>[]) =>
  getTotalStepsForIndicator<PostJobStepIds>(steps, stepsIndicatorInfo);

export const getStepNumber = (
  steps: StepPathInfo<PostJobStepIds>[],
  currentPathname: string
) => getStepNumberForIndicator(steps, stepsIndicatorInfo, currentPathname);

import React from 'react';
import { AppDownloadReminderBanner } from '@common/components/AppDownloadReminderBanner';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { HeaderInfo } from './HeaderInfo';
import { WhatHappensNextInfo } from './WhatHappensNextInfo';

export const PostJobSuccess = () => {
  useRedirectUnauthenticatedUser();

  return (
    <div>
      <HeaderInfo />

      <WhatHappensNextInfo />

      <div className="mb-md">
        <AppDownloadReminderBanner testID="app-download-banner" />
      </div>
    </div>
  );
};

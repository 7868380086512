import {
  ExactSalary,
  JOB_TYPES,
  RangeSalary,
  Salary,
  ShiftAvailability,
  WorkExperience
} from '@seek/je-shared-data/lib/types/jobs';

export enum RightToWorkDocument {
  WORK_VISA = 'work_visa',
  WORK_PASS = 'work_pass'
}

export enum ImmediateStartPhrase {
  IMMEDIATE_START = 'immediate_start',
  URGENT_HIRING = 'urgent_hiring'
}

/** Job summary types **/
export type JobForBaseSummary = {
  jobTitle: string;
  jobType: JOB_TYPES;
  workExperience: WorkExperience;
  shiftAvailability: ShiftAvailability;
  location: string;
  businessName: string;
  salary?: SalaryWithOptionalAmount;
  rightToWork?: boolean;
  immediateStart?: boolean;
};

export type SalaryWithOptionalAmount = Omit<Salary, 'amount'> & {
  amount?: Partial<RangeSalary['amount']> | ExactSalary['amount'];
};

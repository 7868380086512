import { TFunction } from 'i18next';
import { useTranslation } from '@common/hooks/useTranslation';
import { Item } from '@components/FormElements/Dropdown/DropdownLegacy/DropdownLegacy';

const salaryByOptions = (t: TFunction): Item<string>[] => {
  return [
    {
      label: t('postJobForm.expectedSalary.salaryType.exact'),
      value: 'exact',
      testID: 'salaryBy-exact'
    },
    {
      label: t('postJobForm.expectedSalary.salaryType.range'),
      value: 'range',
      testID: 'salaryBy-range'
    }
  ];
};

export const useSalaryTypeSelector = () => {
  const { t } = useTranslation();

  return { salaryByOptions: salaryByOptions(t) };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdTypeBadge } from '@common/components/AdTypeBadge';
import { JobCardStatus } from '@common/components/JobCardStatus';
import { JobStatusBadge } from '@common/components/JobStatusBadge';
import { localisedFormatDate } from '@common/helpers/dateTime';
import { getJobStatusDateText } from '@common/helpers/jobs';
import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { Job } from '@common/types';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { CalloutDetails } from './components/CalloutDetails';
import { CardFooterActions } from './components/CardFooterActions';
import { UpgradeAdCallout } from './components/UpgradeAdCallout';

type JobDetailsProps = {
  job: Job;
  showUpgradeNudge: boolean;
};

export const JobDetails = ({ job, showUpgradeNudge }: JobDetailsProps) => {
  const { t } = useTranslation();
  const isMonetisationEnabled = useMonetisationEnabled(job.countryCode);
  const dateSubtitle = getJobStatusDateText(t, job);

  return (
    <Stack spacing="medium" tone="transparent">
      <Card
        borderWidth="xsmall"
        leftAdornment={
          job.status ? <JobCardStatus status={job.status} /> : undefined
        }
        testID={`job-details-card-${job.id}`}
      >
        <CardContent
          paddingBottom="large"
          paddingLeft={{
            mobile: 'xxsmall',
            tablet: 'medium',
            desktop: 'medium'
          }}
        >
          <Column spacing="xsmall">
            <Row justifyContent="space-between">
              {job.status ? (
                <JobStatusBadge
                  status={job.status}
                  testID={`job-status-badge-${job.status}`}
                />
              ) : null}
              {isMonetisationEnabled ? (
                <AdTypeBadge adType={job.adType} />
              ) : null}
            </Row>
            <Box marginBottom="xxsmall">
              <Text size="small" testID="job-posted-date-text">
                {t('dashboardPage.jobCard.postedOn', {
                  date: localisedFormatDate(t, 'dd MMM yyyy', job.createdAt)
                })}
              </Text>
            </Box>

            {dateSubtitle ? (
              <Box marginBottom="xsmall">
                <Text size="small" tone="neutralLight">
                  {dateSubtitle}
                </Text>
              </Box>
            ) : null}

            <Text size="small" weight="regular" testID="company-text">
              {job.company}
            </Text>
            <Text size="small" testID="location-text">
              {job.location}
            </Text>
            <Text size="small" testID={`job-adtype-${job.jobType}-text`}>
              {t(`postJobForm.jobType.${job.jobType}`)}
            </Text>

            {showUpgradeNudge && (
              <UpgradeAdCallout jobId={job.id} jobStatus={job.status!} />
            )}
          </Column>
        </CardContent>
        <CardFooterActions job={job} />
      </Card>
      <CalloutDetails job={job} />
    </Stack>
  );
};

import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { jobTitleRules } from '../../rules/index';
import { JobTitleField } from './JobTitleField';

export function JobTitle<T extends FieldValues>({
  name,
  control
}: UseControllerProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={jobTitleRules(t)}
      render={({ field: { onChange, onBlur, value }, formState }) => {
        return (
          <JobTitleField
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
}

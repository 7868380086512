import { useParams, useRouteMatch } from 'react-router-dom';

export const useGetCompletedUrl = () => {
  const { path: currentPath } = useRouteMatch();
  const pathParams = useParams();

  const removeCountryCode = (path: string) => {
    return path.replace(/\/:countryCode\//, '');
  };

  const replacePathParams = (path: string) => {
    let newPath = path;

    const paramKeys = Object.keys(pathParams);
    paramKeys.forEach((paramKey) => {
      newPath = newPath.replace(`:${paramKey}`, pathParams[paramKey]);
    });

    return newPath;
  };

  const getOnCompletedUrl = ({
    path,
    queryParams
  }: {
    path: string;
    queryParams?: string;
  }) => {
    if (!path) return 'redirect?redirectPath=/';

    let basePath = removeCountryCode(currentPath);

    if (pathParams) {
      basePath = replacePathParams(basePath);
    }

    return `redirect?redirectPath=${basePath}${path}${
      queryParams ? `&${queryParams}` : ''
    }`;
  };

  return { getOnCompletedUrl };
};

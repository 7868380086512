import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';

// for handling payment race condition; see https://jobseeker.atlassian.net/browse/AD-753?focusedCommentId=57731
export const useGetPaymentIntentParam = () => {
  const params = useSearchQueryParam();

  const payment_intent = params.getQueryParam('payment_intent');
  const hasPaymentIntent = Boolean(payment_intent);

  return {
    hasPaymentIntent
  };
};

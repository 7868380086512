import fetchInterceptor from '@common/store/middleware/fetchInterceptor';
import { toJobServiceEndpoint } from '@store/entities/jobs/actions/stateUtils/toJobServiceEndpoint';
import { useAppDispatch } from '@store/hooks';
import { store } from '@store/store';

export function useJobServiceFetch() {
  const dispatch = useAppDispatch();
  const interceptedFetch = fetchInterceptor(fetch, dispatch, store.getState);

  return {
    fetch: <ReturnType>(path: string, options: RequestInit) => {
      return interceptedFetch(
        `${toJobServiceEndpoint(store.getState)}/${path}`,
        options
      ) as Promise<ReturnType>;
    }
  };
}

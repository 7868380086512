import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@components/Text';

const MAX_APPLICANT_COUNT = 499;

type Props = {
  count: number;
};

export const ApplicationsBarTitle = ({ count }: Props) => {
  const { t } = useTranslation();
  const applicantCount = Math.min(count, MAX_APPLICANT_COUNT);

  return (
    <Text size="xlarge" weight="medium">
      {t(
        applicantCount === MAX_APPLICANT_COUNT
          ? 'applications.title.applicantMaxCount'
          : 'applications.title.applicantCount',
        { count: applicantCount }
      )}
    </Text>
  );
};

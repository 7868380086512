import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';
import { mapYearsToOption } from './mapYearsToOption';

type WorkExperienceFilterProps = {
  applications: Application[];
  onClear: () => void;
  onChange: (selectedValues: WorkExperience[]) => void;
  value?: WorkExperience[];
};

export const WorkExperienceFilter = ({
  applications,
  value = [],
  onClear,
  onChange
}: WorkExperienceFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: WorkExperience) => {
    const newValue = value.includes(checkboxValue)
      ? value.filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];

    newValue.length ? onChange(newValue) : onClear();
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-md mb-md font-md">
          {t('applications.applicationsFilter.workExperience.title')}
        </div>

        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(
          (option) =>
            option?.value !== undefined && (
              <div key={option.value} className="w-full md:w-1/2 mb-md">
                <Checkbox
                  value={value.includes(option.value)}
                  label={option.label}
                  onChange={() => handleCheckbox(option.value)}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export const options = (applications: Application[], t: TFunction) => {
  const counts: {
    [numberOfYears: number]: number;
  } = applications.reduce(
    (acc, { yearsOfExperienceInJobRole: numberOfYears }) => {
      if (numberOfYears !== undefined && numberOfYears !== null) {
        acc[numberOfYears] = (acc[numberOfYears] || 0) + 1;
      }
      return acc;
    },
    {}
  );

  return Object.entries(counts).map(([numberOfYears, count]) =>
    mapYearsToOption(Number(numberOfYears), count, t)
  );
};

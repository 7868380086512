import React from 'react';
import { Box } from '@components/Box';

export type PageHeaderProps = {
  children: React.ReactElement;
};

export const PageHeader = ({ children }) => {
  return (
    <Box padding="medium" tone="transparent">
      <h1>{children}</h1>
    </Box>
  );
};

import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';
import { useWhatHappensNextActions } from './useWhatHappensNext';

export const WhatHappensNextInfo = ({
  isBankTransfer
}: {
  isBankTransfer: boolean;
}) => {
  return (
    <div className="flex flex-col pt-[24px] p-lg mt-md mb-md bg-background-neutral">
      <EnhancedJobContent isBankTransfer={isBankTransfer} />

      <Actions />
    </div>
  );
};

const EnhancedJobContent = ({
  isBankTransfer
}: {
  isBankTransfer: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-md">
      {isBankTransfer && (
        <Text>{t('paymentSuccessPage.description.bankTransferDelayText')}</Text>
      )}
      <Heading level="h2">
        {t('paymentSuccessPage.description.whatHappensNow.heading')}
      </Heading>
      <Text>
        {t(
          'paymentSuccessPage.description.whatHappensNow.yourJobWillBeUpgraded'
        )}
      </Text>
    </div>
  );
};

const Actions = () => {
  const { t } = useTranslation();
  const { redirectToHomePage, redirectToPostJobPage } =
    useWhatHappensNextActions();

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-md mt-md ">
      <Button onPress={redirectToPostJobPage}>
        {t('postJobSuccessPage.action.postAnotherJob')}
      </Button>

      <Button onPress={redirectToHomePage} variant="secondary">
        {t('common.action.backToHome')}
      </Button>
    </div>
  );
};

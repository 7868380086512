import { REGEX } from '@common/constants/data';
import type { T } from '@common/hooks/useTranslation';

interface confirmEmailErrors {
  required: string;
  format: string;
  mismatch: string;
}

export const confirmEmail = (t: T) => {
  const errors = t('validations.general.email', {
    returnObjects: true
  }) as confirmEmailErrors;

  return {
    required: errors.required,
    validate: (value: string, formFields: Partial<{ email: string }>) => {
      if (!REGEX.EMAIL_FORMAT.test(value.trim())) {
        return errors.format;
      }

      if (formFields.email !== value) {
        return errors.mismatch;
      }
    }
  };
};

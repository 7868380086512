import React from 'react';
import clsx from 'clsx';
import { LabelType } from './RadioButton';

type Props = {
  label: LabelType;
  disabled?: boolean;
};

export const RadioLabel = (props: Props) => {
  const { disabled = false, label } = props;

  if (typeof label === 'string') {
    return (
      <label
        className={clsx(
          'text-sm font-light cursor-pointer',
          disabled && 'opacity-60'
        )}
      >
        {label}
      </label>
    );
  }

  return label;
};

import type {
  FormProps,
  IncompleteRegisterFormData,
  OnSubmitCallback
} from '../types';

export interface HydratedRegisterFormProps {
  defaultValues: IncompleteRegisterFormData;
  onSubmit: OnSubmitCallback;
  showCookieConsent: boolean;
  showAgeVerification: boolean;
  brandNameText: string;
  submitBtnText: string;
}

export function hydrateRegisterFormProps(
  props: FormProps
): HydratedRegisterFormProps {
  return {
    defaultValues: props.defaultValues ?? {},
    onSubmit: props.onSubmit ?? (() => {}),
    brandNameText: props.brandNameText,
    showCookieConsent: props.showCookieConsent ?? false,
    showAgeVerification: props.showAgeVerification ?? false,
    submitBtnText: props.submitBtnText ?? ''
  };
}

import { useJobServiceFetch } from '@src/fetchers';

export function useTrackConfirmEmailEvent() {
  const { fetch } = useJobServiceFetch();

  return () => {
    fetch('caja-unverified-events', {
      body: JSON.stringify({
        step: 'verifyEmail'
      }),
      method: 'POST',
      credentials: 'include'
    });
  };
}

export const isCajaRoute = (pathname: string) => {
  const cajaRoutesEnding = [
    '/sign-up',
    '/post-job',
    '/repost-job',
    '/edit-job',
    '/enhance-job'
  ];

  const cajaRoutePartials = [
    '/sign-up/',
    '/post-job/',
    '/repost-job/',
    '/edit-job/',
    '/enhance-job/'
  ];

  return (
    cajaRoutesEnding.some((route) => pathname.endsWith(route)) ||
    cajaRoutePartials.some((route) => pathname.includes(route))
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { useAtJobLimit } from '@common/hooks/userPackages';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { PrivacyIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Text } from '@components/Text';

export const PostJobAction = () => {
  const { t } = useTranslation();
  const { postJobLink } = useGetPostJobLink();
  const { redirect } = useRedirect();
  const { isAtLimit: atJobLimit } = useAtJobLimit();

  const disablePostJob = atJobLimit;

  return (
    <>
      <Box
        marginHorizontal={{ desktop: 'none', tablet: 'none', mobile: 'none' }}
        tone="transparent"
        alignItems={{
          mobile: 'stretch',
          tablet: 'flex-end',
          desktop: 'flex-end'
        }}
      >
        <Button
          testID="post-job-button"
          onPress={redirect(postJobLink)}
          disabled={disablePostJob}
        >
          {t('dashboardPage.action.postJob')}
        </Button>

        {disablePostJob && (
          <Row
            tone="transparent"
            spacing="xxsmall"
            alignItems="center"
            marginTop="xsmall"
            justifyContent="center"
          >
            <PrivacyIcon />
            <Text size="small">{t('dashboardPage.state.full')}</Text>
          </Row>
        )}
      </Box>
    </>
  );
};

import { ClientError } from '@common/helpers/errors';
import { useJobServiceFetch } from '@src/fetchers';
import { JobItemState } from '@store/entities/jobs';
import { useQuery } from '@tanstack/react-query';

interface Params {
  jobId?: string;
}

export function useFetchJobData({ jobId }: Params) {
  const { fetch } = useJobServiceFetch();

  const fetchJob = async () => {
    if (!jobId) return Promise.resolve(undefined);

    return await fetch<JobItemState>(`${jobId}`, {
      method: 'GET',
      credentials: 'include'
    });
  };

  const { data, error, isLoading, refetch } = useQuery(
    ['loadJob', jobId],
    fetchJob,
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    data,
    error: getClientError(error),
    isLoading,
    refetch
  };
}

const getClientError = (error) => {
  if (!error?.message) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: error?.properties?.guid,
    responseBody: error?.properties?.errorMessage
  });
};

import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { ImmediateStartPhrase, RightToWorkDocument } from '../BaseJobSummary';
import { DescriptionForm } from './DescriptionForm';
import { JobSummary } from './JobSummary';
import {
  JobDescriptionFormData,
  JobDescriptionLength,
  JobForSummary,
  OnInvalidDescriptionCallback
} from './types';

type ReviewAndDescriptionProps = {
  descriptionForm: {
    defaultValues: JobDescriptionFormData;
    formOptions: {
      jobDescriptionLength: JobDescriptionLength;
      onInvalidDescription: OnInvalidDescriptionCallback;
      rightToWorkDocument: RightToWorkDocument;
      immediateStartPhrase: ImmediateStartPhrase;
    };
  };
  jobSummary: {
    values?: JobForSummary;
    showAdTypeBadge?: boolean;
  };
  onSubmit: (data: JobDescriptionFormData) => Promise<void>;
  onBack: () => void;
  submitText?: string;
};

export const SummaryAndDescription = ({
  descriptionForm: {
    defaultValues: formData,
    formOptions: {
      jobDescriptionLength,
      onInvalidDescription,
      rightToWorkDocument,
      immediateStartPhrase
    }
  },
  jobSummary: { values: jobSummaryValues, showAdTypeBadge },
  onSubmit,
  onBack,
  submitText
}: ReviewAndDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-12">
      {jobSummaryValues && (
        <JobSummary
          job={jobSummaryValues}
          showAdTypeBadge={showAdTypeBadge}
          rightToWorkDocument={rightToWorkDocument}
          immediateStartPhrase={immediateStartPhrase}
        />
      )}

      <DescriptionForm
        defaultValues={{ jobDescription: formData.jobDescription }}
        formOptions={{
          jobDescriptionLength,
          onInvalidDescription
        }}
        onSubmit={onSubmit}
        onBack={onBack}
        stepInfo={{
          submitText: submitText || t('postJobForm.action.postJob')
        }}
      />
    </div>
  );
};

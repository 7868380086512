import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { openExternalURL } from '@common/helpers/externalConnection';

export function useRedirect() {
  const history = useHistory();

  const redirect = useCallback(
    (location) => {
      const isExternal = /^https?:/.test(location);

      if (isExternal) {
        return () => openExternalURL(location);
      }

      return () => history.push(location);
    },
    [history]
  );

  return { redirect };
}

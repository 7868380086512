import { JobDescriptionFormData } from '@common/components/Job/SummaryAndDescription';
import { JobItemState } from '@store/entities/jobs';

export const mapJobToFormData = (
  job?: JobItemState
): JobDescriptionFormData => {
  const formData: JobDescriptionFormData = {
    jobDescription: job?.description
  };

  return formData;
};

import { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the business field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function businessRules(t: T) {
  return {
    required: t('validations.job.business.required')
  };
}

import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { Editor, useEditor } from '@tiptap/react';
import {
  BoldIcon,
  OrderedListIcon,
  UnorderedListIcon
} from '@components/Icon/icons';
import { EditorContent } from './EditorContent';

interface BaseEditorProps {
  value: string;
  onChange: (e: string) => void;
  onBlur?: () => void;
  placeholder?: string;
}

export function BaseEditor({
  value,
  onBlur,
  onChange,
  placeholder
}: BaseEditorProps) {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      ListItem,
      OrderedList,
      BulletList,
      Placeholder.configure({
        placeholder,
        emptyNodeClass:
          'first:before:text-gray-400 first:before:float-left first:before:content-[attr(data-placeholder)] first:before:pointer-events-none'
      })
    ],
    content: value,
    onBlur: onBlur,
    onUpdate: (e) => {
      onChange(e.editor.getHTML());
    }
  }) as Editor;

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [editor, value]);

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnorderedList = useCallback(() => {
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);

  const toggleOrderedList = useCallback(() => {
    editor.chain().focus().toggleOrderedList().run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="editor">
      <div className="menu">
        <button
          type="button"
          className={classNames('menu-button', {
            'is-active': editor.isActive('bold')
          })}
          onClick={toggleBold}
        >
          <BoldIcon size="large" variant="outline" tone="neutral" />
        </button>
        <button
          type="button"
          className={classNames('menu-button', {
            'is-active': editor.isActive('bulletList')
          })}
          onClick={toggleUnorderedList}
        >
          <UnorderedListIcon size="large" tone="neutral" />
        </button>
        <button
          type="button"
          className={classNames('menu-button', {
            'is-active': editor.isActive('orderedList')
          })}
          onClick={toggleOrderedList}
        >
          <OrderedListIcon size="large" tone="neutral" />
        </button>
      </div>
      <EditorContent editor={editor} />
    </div>
  );
}

import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { CalloutText } from '@components/CalloutText';
import { Stack } from '@components/Stack';

export const JobDescriptionCalloutDetails = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing="small">
      <CalloutText tone="warning" testID="bad-ad-tips-callout">
        {t('postJobForm.explanationText.jobDescription.badJobDescription')}
      </CalloutText>
    </Stack>
  );
};

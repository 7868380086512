import type { ValueProps } from '@common/components/Account/components/MobileNumber';
import { FORMAT_VALIDATION_ERROR } from '@common/constants/validation';
import { isValidMobileNumber } from '@common/helpers/validation';
import type { T } from '@common/hooks/useTranslation';

interface MobileNumberErrors {
  required: string;
  [FORMAT_VALIDATION_ERROR]: string;
}

export const mobileNumber = (t: T) => {
  const errors = t('validations.general.mobile', {
    returnObjects: true
  }) as MobileNumberErrors;

  return {
    validate: (data: ValueProps) => {
      const { diallingCode, phoneNumber } = data || {};
      if (!phoneNumber) {
        return errors.required;
      }

      if (phoneNumber && !isValidMobileNumber(diallingCode, phoneNumber)) {
        return errors[FORMAT_VALIDATION_ERROR];
      }
    }
  };
};

import { useMemo } from 'react';
import { DraftJob } from '@common/hooks/job/useFindRecentDraft';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { FormData } from '../PostJob';
import { toPostJobFormData } from '../transformers/draft/toPostJobFormData';
import { useDraftBusiness } from './useDraftBusiness';

export const useMapDraftToFormData = (
  draft: DraftJob,
  businesses: BusinessWithUsers[]
): FormData => {
  const business = useDraftBusiness(businesses, draft?.businessId);

  return useMemo(() => toPostJobFormData(draft, business), [draft, business]);
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type Callback = () => void;

export const useBrowserBackNavigationListener = (onBack: Callback) => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      // POP only happens when the user navigates back via browser back button within CAJA
      // https://github.com/remix-run/history/blob/dev/docs/api-reference.md#action
      if (history.action === 'POP') onBack();
    });
  });
};

import { useJobServiceFetch } from '@src/fetchers';

interface JobEvent {
  step: string;
  jobId: string;
  version?: string | null;
  variant?: string | null;
  campaign?: string | null;
}

export function useTrackJobEvent() {
  const { fetch } = useJobServiceFetch();
  return ({ step, jobId, version, campaign, variant }: JobEvent) => {
    fetch('caja-job-events', {
      body: JSON.stringify({
        step,
        jobId,
        version,
        campaign,
        variant
      }),
      method: 'POST',
      credentials: 'include'
    });
  };
}

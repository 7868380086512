import { REGEX } from '@common/constants/data';
import type { T } from '@common/hooks/useTranslation';

interface EmailErrors {
  required: string;
  format: string;
}

export const email = (t: T) => {
  const errors = t('validations.general.email', {
    returnObjects: true
  }) as EmailErrors;

  return {
    required: errors.required,
    validate: (value: string) => {
      if (!REGEX.EMAIL_FORMAT.test(value.trim())) {
        return errors.format;
      }
    }
  };
};

import React from 'react';
import { Path } from 'svgs';
import { createIllustratedIcon } from '../createIllustratedIcon';

const vectorComponent = () => (
  <>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0.428589C27.4677 0.428589 35.1428 8.10371 35.1428 17.5714C35.1428 27.0392 27.4677 34.7143 18 34.7143C8.53223 34.7143 0.857109 27.0392 0.857109 17.5714C0.857109 8.10371 8.53223 0.428589 18 0.428589ZM20.7993 9.00002C18.3662 9.00002 16.776 10.4746 16.776 13.1443V15.491H14.0714V18.5701H16.776V26.0135C17.3183 26.0986 17.8741 26.1429 18.4403 26.1429C19.0065 26.1429 19.5624 26.0986 20.1047 26.0135V18.5701H22.5866L23.0589 15.491H20.1047V13.4929C20.1047 12.6505 20.5173 11.8294 21.8406 11.8294H23.1837V9.20806C23.1837 9.20806 21.9647 9.00002 20.7993 9.00002Z"
      fill="#3B5998"
    />
  </>
);

export const FacebookIcon = createIllustratedIcon({
  viewBox: '0 0 35 35',
  vectorComponent
});

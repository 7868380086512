import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, initialize, reduxForm } from 'redux-form';
import { ConfirmPaymentProvider } from '@common/components/FormElements/PaymentProvider';
import { getOS } from '@common/helpers/deviceInfo';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Stack } from '@components/Stack';
import Form from '../../../common/components/Form/Form';
import InputField from '../../../common/components/FormElements/InputField/InputField';
import { isNative } from '../../../common/helpers/detection';
import REQUEST_KEY from '../../constants/requestKeys';
import * as appActions from '../../store/app/actions';
import * as paymentActions from '../../store/payment/actions';
import * as constants from '../../store/payment/constants';
import ConnectedErrorMessage from '../ConnectedErrorMessage/ConnectedErrorMessage';
import Submit from '../Submit/Submit';
import { PaymentInputFields } from './components/PaymentInputFields';
import {
  getRedirectBaseURL,
  getSuccessUrlPathFromCampaign
} from './helpers/getRedirectURL';
import { validateCheckoutForm } from './helpers/validation';

@connect(
  (state) => {
    const {
      auth: { currentUser },
      localisation: {
        countryCode,
        brandConfig: { name: brand }
      },
      app,
      payment: {
        intent: { adType, jobCountry, pricingText }
      },
      config
    } = state;

    const { email, givenName, surname } = currentUser;
    const isPaymentElementLoading = app.loadStackWithSpinner.includes(
      REQUEST_KEY.PAYMENT.LOAD_PAYMENT_ELEMENT
    );

    const shouldDisplayIdBankTransfer =
      jobCountry === SupportedCountryCode.Indonesia;

    const getInitialValues = () => {
      const fullName = givenName && surname && `${givenName} ${surname}`;
      const bankTransferInitialValues = {
        bankName: '',
        fullName,
        email,
        country: countryCode,
        paymentMethod: constants.PAYMENT_METHOD.indonesianBankTransfer
      };
      const cardInitialValues = {
        email,
        nameOnCard: fullName,
        country: countryCode,
        paymentMethod: constants.PAYMENT_METHOD.card
      };

      return shouldDisplayIdBankTransfer
        ? bankTransferInitialValues
        : cardInitialValues;
    };

    return {
      config,
      brand,
      countryCode,
      currentUser,
      initialValues: getInitialValues(),
      pricingText,
      adType,
      jobCountry,
      isPaymentElementLoading,
      shouldDisplayIdBankTransfer,
      paymentMethod: getInitialValues().paymentMethod,
      deviceType: getOS(),
      domain: config.domain[brand]
    };
  },
  {
    initializeForm: initialize,
    confirmPayment: paymentActions.confirmPayment,
    confirmPaymentIntent: paymentActions.confirmPaymentIntent,
    clearCheckoutDetails: paymentActions.clearCheckoutDetails,
    clearError: appActions.clearError,
    onLoadPaymentElement: paymentActions.onLoadPaymentElement,
    onReadyPaymentElement: paymentActions.onReadyPaymentElement
  }
)
@reduxForm({
  form: constants.CONFIRM_PAYMENT_FORM,
  validate: validateCheckoutForm
})
@withTranslation()
export default class CheckoutForm extends PureComponent {
  static propTypes = {
    ...reduxForm,
    elements: PropTypes.object,
    jobId: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    campaign: PropTypes.string,
    currentUser: PropTypes.shape({
      email: PropTypes.string.isRequired,
      givenName: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired
    }),
    t: PropTypes.func.isRequired,
    pricingText: PropTypes.string,
    adType: PropTypes.string,
    jobCountry: PropTypes.string,
    isPaymentElementLoading: PropTypes.bool,
    onReady: PropTypes.func,
    backButton: PropTypes.element,
    onCompletedUrl: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.confirmPaymentProvider = createRef();
  }

  componentDidMount() {
    const { initialValues, initializeForm, onLoadPaymentElement } = this.props;

    onLoadPaymentElement();

    initializeForm(
      constants.CONFIRM_PAYMENT_FORM,
      {
        ...initialValues
      },
      true
    );
  }

  componentWillUnmount() {
    const { clearCheckoutDetails } = this.props;
    clearCheckoutDetails();
  }

  shouldDisplayCardFields = () => {
    const { shouldDisplayIdBankTransfer } = this.props;
    return !shouldDisplayIdBankTransfer;
  };

  isIndonesiaBankTransfer = (paymentMethod) =>
    paymentMethod === constants.PAYMENT_METHOD.indonesianBankTransfer;

  getFullRedirectURL = () => {
    const { paymentMethod, deviceType, domain } = this.props;

    return (
      getRedirectBaseURL(paymentMethod, deviceType, domain) +
      getSuccessUrlPathFromCampaign(this.props)
    );
  };

  confirmStripePayment = (fn, paymentMethod) => {
    const { onCompletedUrl, domain, countryCode } = this.props;

    const fullPaymentCompleteUrl = onCompletedUrl
      ? `${domain}/${countryCode}/${onCompletedUrl}&paymentMethod=${paymentMethod}`
      : '';

    return async (data) => {
      await fn({
        data,
        successfulRedirectUrl:
          fullPaymentCompleteUrl || this.getFullRedirectURL()
      });
    };
  };

  confirmPayment = (paymentMethod) => {
    // todo: Use useConfirmPayment and useConfirmIdBankTransferPayment hook once this form is migrated.
    const { confirmIdBankTransferPayment, confirmPayment } =
      this.confirmPaymentProvider.current;

    return this.isIndonesiaBankTransfer(paymentMethod) && isNative()
      ? this.confirmStripePayment(confirmIdBankTransferPayment, paymentMethod)
      : this.confirmStripePayment(confirmPayment, paymentMethod);
  };

  submit(data) {
    const { paymentMethod, brand } = this.props;
    const dataWithBrand = { ...data, brand };

    this.confirmPayment(paymentMethod)(dataWithBrand);
  }

  onPaymentInputFieldsReady = () => {
    const { onReadyPaymentElement, onReady } = this.props;

    onReadyPaymentElement();

    if (onReady) onReady();
  };

  render() {
    const {
      t,
      submitFailed,
      valid,
      pricingText,
      handleSubmit,
      clearError,
      isPaymentElementLoading,
      backButton,
      shouldDisplayIdBankTransfer
    } = this.props;

    const hasPaymentElementLoaded = !isPaymentElementLoading;

    return (
      <Stack>
        <Form
          onSubmit={handleSubmit(this.submit.bind(this))}
          submitFailed={submitFailed}
          valid={valid}
          insideCard
          errorSummary={t('validations.general.summary')}
        >
          <Stack spacing="medium">
            <ConfirmPaymentProvider ref={this.confirmPaymentProvider} />

            {hasPaymentElementLoaded && !shouldDisplayIdBankTransfer ? (
              <Field
                component={InputField}
                name="email"
                fieldId="email"
                label={t('common.fieldLabel.email')}
                autoCorrect="off"
                validationMessages={{
                  ...t('validations.general.email', { returnObjects: true })
                }}
                longField
                firstField
              />
            ) : null}

            <PaymentInputFields
              data-test-key={'payment-element'}
              onChange={() => {
                clearError(REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT);
              }}
              onReady={this.onPaymentInputFieldsReady}
            />

            {hasPaymentElementLoaded && this.shouldDisplayCardFields() ? (
              <Field
                component={InputField}
                name="nameOnCard"
                fieldId="nameOnCard"
                label={t('checkoutForm.card.fieldLabel.nameOnCard')}
                autoCorrect="off"
                autoCapitalize="words"
                validationMessages={{
                  ...t('validations.user.nameOnCard', { returnObjects: true })
                }}
                longField
              />
            ) : null}

            {hasPaymentElementLoaded ? (
              <div className="flex flex-row gap-md items-center">
                {backButton ? backButton : null}

                <Submit
                  insideCard
                  extraTopMargin
                  data-test-key="checkoutSubmit"
                >
                  {t('checkoutForm.action.pay', { price: pricingText })}
                </Submit>
              </div>
            ) : null}

            <ConnectedErrorMessage
              requestKey={REQUEST_KEY.PAYMENT.CONFIRM_PAYMENT}
              extraTopMargin
            />
          </Stack>
        </Form>
      </Stack>
    );
  }
}

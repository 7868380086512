import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BusinessImageAndDetails } from '@common/components/BusinessImageAndDetails';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import {
  BUSINESS_REGISTRATION_NUMBER_FIELD_NAME,
  INDUSTRY_ID_TRANSLATE_MAPPER
} from '@common/constants/data';
import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useLoadBusiness } from '@common/hooks/business';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { useCurrentUser } from '@common/hooks/user';
import { GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Heading } from '@components/Heading';
import { LocationIcon } from '@components/Icon';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

export const BusinessDetails = () => {
  useRedirectUnauthenticatedUser();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: currentUserId } = useCurrentUser();
  const { businessId } = useParams<{ businessId: string }>();
  const countryCode = useSiteCountryCode();
  const { data: business, error } = useLoadBusiness(businessId);

  if (error) {
    if (isNotFoundError(error)) {
      history.push(`/${countryCode}/businesses`);
      return null;
    }

    return (
      <Stack tone="transparent">
        <RequestErrorMessage requestKey={REQUEST_KEY.BUSINESS.LOAD} />
      </Stack>
    );
  }

  if (!business) return null;

  const businessUserRole = business.users.find(
    (businessUser) => currentUserId === businessUser.userId
  )?.role;

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('businessDetailsPage.title')} />
      <Box
        paddingHorizontal="medium"
        marginTop="medium"
        marginBottom="medium"
        tone="transparent"
      >
        <Heading level="h1">{t('businessDetailsPage.heading')}</Heading>
      </Box>

      <Card>
        <CardContent>
          <BusinessImageAndDetails businessSize={business.businessSize}>
            <Text size="xlarge" weight="medium">
              {business.name}
            </Text>
          </BusinessImageAndDetails>

          <Column spacing="xxsmall" marginTop="medium">
            <Text weight="medium">
              {t('createBusinessForm.fieldLabel.businessAddress')}
            </Text>
            <Row alignItems="center" spacing="xxsmall">
              <LocationIcon size="large" variant="outline" tone="success" />
              <Text>{`${business.locations[0].suburb} ${business.locations[0].state} `}</Text>
            </Row>
          </Column>

          <Column spacing="xxsmall" marginTop="medium">
            <Text weight="medium">
              {t('createBusinessForm.fieldLabel.industry')}
            </Text>
            <Text>{t(INDUSTRY_ID_TRANSLATE_MAPPER[business.industryId])}</Text>
          </Column>

          <Column spacing="xxsmall" marginTop="medium">
            <Text weight="medium">
              {t('createBusinessForm.fieldLabel.businessSize')}
            </Text>
            <Text>
              {' '}
              {t(`businessDetailsPage.businessSize.${business.businessSize}`)}
            </Text>
          </Column>

          <Column spacing="xxsmall" marginTop="medium">
            <Text weight="medium">
              {t('createBusinessForm.fieldLabel.role')}
            </Text>
            <Text>{t(`business.businessRole.${businessUserRole}`)}</Text>
          </Column>

          {business.recruitmentCompanyName && (
            <Column spacing="xxsmall" marginTop="medium">
              <Text weight="medium">
                {t('createBusinessForm.fieldLabel.recruitmentName')}
              </Text>
              <Text>{business.recruitmentCompanyName}</Text>
            </Column>
          )}

          {business.website && (
            <Column spacing="xxsmall" marginTop="medium">
              <Text weight="medium">
                {t('createBusinessForm.fieldLabel.website')}
              </Text>
              <Text>{business.website}</Text>
            </Column>
          )}

          {business.businessRegistrationNumber && (
            <Column spacing="xxsmall" marginTop="medium">
              <Text weight="medium">
                {
                  BUSINESS_REGISTRATION_NUMBER_FIELD_NAME[
                    business.locations[0].countryCode
                  ]
                }
              </Text>
              <Text>{business.businessRegistrationNumber}</Text>
            </Column>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

const isNotFoundError = (error: ClientError) =>
  error?.message === GENERAL_EXCEPTION.NOT_FOUND;

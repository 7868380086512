import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Application, ApplicationStatus } from '@common/types';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';

type ApplicantStatusFilterProps = {
  applications: Application[];
  onChange: (selectedValues: ApplicationStatus[]) => void;
  onClear: () => void;
  value?: ApplicationStatus[];
};

export const ApplicantStatusFilter = ({
  applications = [],
  value = [],
  onChange,
  onClear
}: ApplicantStatusFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: ApplicationStatus) => {
    const newValue = value.includes(checkboxValue)
      ? value.filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];

    newValue.length ? onChange(newValue) : onClear();
  };

  return (
    <div className="space-y-md">
      <div className="flex justify-between">
        <div className="text-md">
          {t('applications.applicationsFilter.applicantStatus.title')}
        </div>
        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(({ key, label, value: optionValue }) => (
          <div key={key} className="w-full md:w-1/2 mb-md">
            <Checkbox
              value={value.includes(optionValue)}
              label={label}
              onChange={() => handleCheckbox(optionValue)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const options = (applications: Application[], t: TFunction) => {
  const counts: {
    [status: string]: number;
  } = applications.reduce(
    (acc, { status }) => {
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }

      return acc;
    },
    {
      [APPLICANT_STATUS.NO_STATUS]: 0,
      [APPLICANT_STATUS.NOT_SUITABLE]: 0,
      [APPLICANT_STATUS.SHORTLISTED]: 0,
      [APPLICANT_STATUS.SCHEDULED_INTERVIEW]: 0,
      [APPLICANT_STATUS.OFFERED_JOB]: 0,
      [APPLICANT_STATUS.ACCEPTED_OFFER]: 0
    }
  );

  return Object.keys(counts)
    .sort()
    .map((status) => ({
      key: status,
      value: status as ApplicationStatus,
      label: `${t(`applications.status.${status}`)} (${counts[status]})`
    }));
};

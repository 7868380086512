import React from 'react';
import { PaymentForm } from '@common/components/Payment/PaymentForm/PaymentFormWithCoins';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useGetCoinPricing, useLoadAdPrices } from '@common/hooks/payment';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { useGetUtmParams } from '../hooks/useGetUtmParams';

type Props = Pick<
  TopLevelStep<{
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    adType: AD_TYPE.PLUS | AD_TYPE.MAX;
    campaign?: string;
    onCompletedUrl: string;
  }>,
  'onBack' | 'stepInfo'
>;

export const PaymentJob = (props: Props) => {
  useRedirectUnauthenticatedUser();
  const utmParams = useGetUtmParams();
  const { t } = useTranslation();

  const {
    stepInfo: {
      jobId,
      jobCountryCode,
      adType,
      onCompletedUrl,
      campaign = PAYMENT_CAMPAIGN.MULTI_STEP_CAJA
    },
    onBack
  } = props;

  const { data: currencyPrice } = useLoadAdPrices(jobCountryCode);

  const { coinPrice } = useGetCoinPricing(jobCountryCode);

  const countryHeadingText =
    jobCountryCode === SupportedCountryCode.Indonesia
      ? t('checkoutForm.bankTransfer.heading')
      : t('checkoutForm.card.heading');

  if (!coinPrice) return null;

  return (
    <div className="p-[12px] bg-background-neutral mb-md">
      <div className="flex flex-col pt-md p-[24px]">
        <PaymentForm
          stepInfo={{
            adType,
            jobId,
            jobCountryCode,
            paymentCampaign: campaign,
            currencyPrice,
            coinPrice: coinPrice!!,
            sourceParams: utmParams,
            headingText: countryHeadingText
          }}
          onCompletedUrl={onCompletedUrl}
          onBack={onBack}
        />
      </div>
    </div>
  );
};

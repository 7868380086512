import { removeUndefined } from '@src/lib/func/removeUndefined';
import { QueryClient } from '@tanstack/react-query';
import { DraftJob, findRecentDraftKey } from '../useFindRecentDraft';
import { UpdateDraftParams } from './useUpdateDraft';

export const optimisticallyUpdate = (
  args: UpdateDraftParams,
  queryClient: QueryClient
) => {
  const updatedDraft = updateParamsToDraft(args.draft);

  const key = findRecentDraftKey(args);
  queryClient.setQueryData(key, (existingDraft: DraftJob) =>
    mergeData(existingDraft, updatedDraft)
  );
};

const mergeData = (
  existingDraft: DraftJob,
  updatedDraft: Partial<DraftJob>
): DraftJob => {
  return {
    ...existingDraft,
    ...updatedDraft
  } as DraftJob;
};

const updateParamsToDraft = (
  params: UpdateDraftParams['draft']
): Partial<DraftJob> => {
  return removeUndefined({
    title: params.title,
    businessId: params.businessId,
    immediateStart: params.immediateStart,
    description: params.description,
    rightToWork: params.rightToWork,
    shiftAvailability: params.shiftAvailability,
    salary: params.salary ? params.salary : undefined,
    jobType: params.type,
    jobRole: params.role,
    workExperience: params.workExperience
  });
};

import { useSiteCountryCode } from '@common/hooks/localisation';
import { UpgradeableAdType } from '@src/job';

export type Props = {
  adType: UpgradeableAdType;
  jobId: string;
  campaign?: string;
};

export function useGetPaymentLink({ adType, campaign, jobId }: Props) {
  const countryCode = useSiteCountryCode();

  return `/${countryCode}/jobs/${jobId}/payment?adType=${adType}${
    campaign ? `&campaign=${campaign}` : ''
  }`;
}

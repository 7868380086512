import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { BusinessRegistration as InnerField } from '@common/components/BusinessForm/components/BusinessRegistration';
import { useBusinessRegistrationFieldName } from '@common/components/BusinessForm/components/BusinessRegistration/hooks/useBusinessRegistration';
import { FieldWrapper } from '../FieldWrapper';

export default function BusinessRegistration() {
  const { businessRegistrationRules } = useBusinessRules();
  const { businessRegistrationFieldName } = useBusinessRegistrationFieldName();
  return (
    <FieldWrapper<string>
      name="businessRegistrationNumber"
      rules={businessRegistrationRules(businessRegistrationFieldName)}
      render={(props) => <InnerField {...props} />}
    />
  );
}

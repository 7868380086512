import { ClientError } from '@common/helpers/errors';
import { useBusinessServiceFetch } from '@src/fetchers';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useQuery } from '@tanstack/react-query';

interface Params {
  businessId?: string;
}

export function useFetchBusinessData({ businessId }: Params) {
  const { fetch } = useBusinessServiceFetch();

  const fetchBusiness = async () => {
    if (!businessId) return Promise.resolve(undefined);

    return await fetch<BusinessWithUsers>(`${businessId}`, {
      method: 'GET',
      credentials: 'include'
    });
  };

  const { data, error, isLoading } = useQuery(['loadBusiness'], fetchBusiness, {
    refetchOnWindowFocus: false
  });

  return {
    data,
    error: getClientError(error),
    isLoading
  };
}

const getClientError = (error) => {
  if (!error?.message) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: error?.properties?.guid,
    responseBody: error?.properties?.errorMessage
  });
};

import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { IndustryDescription as InnerField } from '@common/components/BusinessForm/components/IndustryDescription';
import { FieldWrapper } from '../FieldWrapper';

export default function IndustryDescription() {
  const { industryDescriptionRules } = useBusinessRules();
  return (
    <FieldWrapper<string>
      name="industryOtherDescription"
      rules={industryDescriptionRules}
      render={(props) => <InnerField {...props} />}
    />
  );
}

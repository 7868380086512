import { useCallback, useState } from 'react';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { AD_TYPE } from '@seek/je-shared-data';

export const useAdTypeState = () => {
  const params = useSearchQueryParam();
  const queryParamAdType = params.getQueryParam('adType');

  const [jobAdType, set] = useState<AD_TYPE | undefined>(
    validate(queryParamAdType)
  );

  const setJobAdType = useCallback(
    (newAdType?: string) => set(validate(newAdType)),
    [set]
  );

  return [jobAdType, setJobAdType] as const;
};

const validate = (adType) => {
  if (Object.values(AD_TYPE).includes(adType)) {
    return adType as AD_TYPE;
  }
};

import { useLocation } from 'react-router-dom';

// request for comment: duplicated this instead of making the existing "useGetJobIdParam" into a "generic" function (that might need to be moved into the SignUp flow directory)
// Keeps the logic for extracing ids close to the actual individual caja flow and makes it less prone to breakage if the path conventions diverge between flows. open to comments on this thou
export function useGetJobIdParam() {
  const location = useLocation();

  const regex =
    /\/repost-job\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-\d+)\/.*/;

  const match = location.pathname.match(regex);

  if (match) return match[1];
}

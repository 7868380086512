import React from 'react';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { HeaderInfo } from './HeaderInfo';
import { WhatHappensNowInfo } from './WhatHappensNextNow';

export const EditJobSuccess = () => {
  useRedirectUnauthenticatedUser();

  return (
    <div>
      <HeaderInfo />

      <WhatHappensNowInfo />
    </div>
  );
};

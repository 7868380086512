import { useJobServiceFetch } from '@src/fetchers';

export const useFetchUiVersion = () => {
  const { fetch } = useJobServiceFetch();

  async function fetchUiVersion(): Promise<number> {
    return (await fetch('ui-version', {
      method: 'GET'
    })) as number;
  }

  return {
    fetchUiVersion
  };
};

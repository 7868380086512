import { TrpcRouter } from 'je-bff/src/handlers/bff';
import { ClientError } from '@common/helpers/errors';
import { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type PatchJobParams =
  inferRouterInputs<TrpcRouter>['jobs']['actions']['patchDescription'];

export const usePatchDescription = () => {
  const { mutateAsync, isSuccess, error } =
    trpcBff.jobs.actions.patchDescription.useMutation();

  return {
    patchJobData: mutateAsync,
    isSuccess,
    error: getClientError(error)
  };
};

const getClientError = (error) => {
  if (!error?.message) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: error?.properties?.guid,
    responseBody: error?.properties?.errorMessage
  });
};

import React, { useState } from 'react';
import {
  BasicDetailsForm,
  BasicDetailsFormData
} from '@common/components/Job/BasicJobDetails';
import {
  TitleAndBusinessForm,
  TitleAndBusinessFormData
} from '@common/components/Job/TitleAndBusiness';
import { useLoadBusiness } from '@common/hooks/business';
import { useUpdateJob } from '@common/hooks/job';
import { type TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { JobItemState } from '@store/entities/jobs';
import { StepError, StepLoader, StepWrapper } from '../../components';
import { StepIds, stepIds } from './stepsInfo';
import { toEditJobFormData as mapToFormData } from './transformers/toEditJobFormData';
import {
  CompleteEditJobFormData,
  toUpdateJobParams
} from './transformers/toUpdateJobParams';

export type FormData = BasicDetailsFormData & TitleAndBusinessFormData;
type StepInfo = { jobId: string; job: JobItemState; currentStepId: StepIds };
type Props = Pick<
  TopLevelStep<StepInfo>,
  'onCompleted' | 'onBack' | 'stepInfo' | 'onNext'
>;

export const EditJob = ({
  onCompleted,
  onBack,
  onNext,
  stepInfo: { jobId, job, currentStepId }
}: Props) => {
  useRedirectUnauthenticatedUser();

  const [jobUpdates, setJobUpdates] = useState<Partial<FormData>>({});

  const {
    data: business,
    isLoading: businessLoading,
    error: loadBusinessError
  } = useLoadBusiness(job?.businessId);
  const { updateJobData, error: updateJobError } = useUpdateJob();

  const formData = mapToFormData(jobUpdates, job!!, business);
  const isLoading = businessLoading;
  const error = updateJobError || loadBusinessError;

  const onSubmitTitleAndBusiness = (data: TitleAndBusinessFormData) => {
    setJobUpdates({ ...jobUpdates, ...data });
    onNext({});
  };

  const onSubmitBasicDetails = async (data: BasicDetailsFormData) => {
    const response = await updateJobData(
      toUpdateJobParams(jobId, {
        ...formData,
        ...data
      } as CompleteEditJobFormData)
    );

    if (!response?.error) {
      onCompleted({});
    }
  };

  if (isLoading) return <StepLoader />;

  return (
    <StepWrapper>
      {currentStepId === stepIds.title && (
        <TitleAndBusinessForm
          defaultValues={{
            jobTitle: formData.jobTitle,
            business: formData.business
          }}
          onSubmit={onSubmitTitleAndBusiness}
          onBack={onBack}
          businessOptions={{
            isBusinessEditable: false
          }}
        />
      )}
      {currentStepId === stepIds.basicDetails && (
        <BasicDetailsForm
          defaultValues={{
            workExperience: formData.workExperience,
            salary: formData.salary,
            jobType: formData.jobType,
            shiftAvailability: formData.shiftAvailability,
            jobTitle: formData.jobTitle
          }}
          onSubmit={onSubmitBasicDetails}
          onBack={onBack}
        />
      )}
      {error && <StepError error={error} />}
    </StepWrapper>
  );
};

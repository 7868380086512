import { ProfileLocation } from '@common/types';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { NormalisedLocation } from './type';

/**
 * Normalises the district information based on the provided location.
 *
 * @param location - The location object containing country code, country name, region, and sub-region/municipality information.
 * @returns A normalised location object with country code, country name, district, sub-district, and filter value.
 *
 * The function handles different country codes and normalises the location information accordingly:
 * - For Indonesia, it uses `region` as the district and `subRegion` as the sub-district.
 * - For Singapore, it uses `region` as the district and `subMunicipality` as the sub-district.
 * - For other countries, it uses `region` as the district and `municipality` as the sub-district.
 */
export const getNormalisedLocation = (
  location: ProfileLocation
): NormalisedLocation => {
  if (location.countryCode === SupportedCountryCode.Indonesia) {
    return {
      countryCode: location.countryCode,
      countryName: location.countryName,
      district: location.region,
      subDistrict: location.subRegion,
      filterValue: {
        countryName: location.countryName,
        countryCode: location.countryCode,
        region: location.region,
        subRegion: location.subRegion
      }
    };
  }

  if (location.countryCode === SupportedCountryCode.Singapore) {
    return {
      countryCode: location.countryCode,
      countryName: location.countryName,
      district: location.region,
      subDistrict: location.subMunicipality,
      filterValue: {
        countryName: location.countryName,
        countryCode: location.countryCode,
        region: location.region,
        subMunicipality: location.subMunicipality
      }
    };
  }

  return {
    countryCode: location.countryCode,
    countryName: location.countryName,
    district: location.region,
    subDistrict: location.municipality,
    filterValue: {
      countryName: location.countryName,
      countryCode: location.countryCode,
      region: location.region,
      municipality: location.municipality
    }
  };
};

import { useEffect, useState } from 'react';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useCustomHeaders() {
  const [headers, setHeaders] = useState({});
  const { countryCode, brandConfig } = useAppSelector(getLocalisation);
  const brand = brandConfig?.name;

  useEffect(() => {
    (() => {
      setHeaders({
        'x-country-code': countryCode,
        'x-brand': brand
      });
    })();
  }, [brand, countryCode]);

  return headers;
}

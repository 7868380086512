import { T } from '@common/hooks/useTranslation';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';

export const getWorkExperienceSummary = (
  workExperience: WorkExperience,
  t: T
) => {
  switch (workExperience) {
    case WorkExperience.NO_EXPERIENCE:
      return t('postJobForm.summary.work.noExperience');
    case WorkExperience.ONE_YEAR_EXPERIENCE:
      return t('postJobForm.summary.work.oneYearExperience');
    case WorkExperience.TWO_TO_THREE_YEARS_EXPERIENCE:
      return t('postJobForm.summary.work.twoToThreeYearsExperience');
    case WorkExperience.MORE_THAN_FOUR_YEARS_EXPERIENCE:
      return t('postJobForm.summary.work.moreThanFourYearsExperience');
    default:
      return '';
  }
};

import React from 'react';

export type ProgressIndicatorProps = {
  value: number;
  max: number;
};

export default function ProgressIndicator({
  value = 0,
  max
}: ProgressIndicatorProps) {
  return (
    <progress
      className="progress progress-success h-3 w-full"
      value={value}
      max={max}
    ></progress>
  );
}

import {
  getStepNumberForIndicator,
  getTotalStepsForIndicator
} from '@common/hooks/multiStep';
import { StepIndicatorInfo, StepPathInfo } from '@common/hooks/multiStep/types';
import { EditJobStepIds } from './stepConfig';

const stepsIndicatorInfo: StepIndicatorInfo<EditJobStepIds> = {
  enhanceJob: {
    title: 'enhanceJobStep.stepTitle',
    pageTitle: 'editJobPage.heading'
  },
  payment: {
    title: '',
    pageTitle: 'paymentJobStep.payWithCardHeading',
    ignoreIndicator: true
  },
  jobSummaryAndDesc: {
    title: 'summaryAndDescStep.stepTitle',
    pageTitle: 'editJobPage.heading'
  },
  editJobSuccess: {
    title: '',
    pageTitle: '',
    ignoreIndicator: true
  },
  editTitle: {
    title: 'postJobTitleStep.stepTitle',
    pageTitle: 'editJobPage.heading'
  },
  editBasicDetails: {
    title: 'postJobBasicDetailsStep.stepTitle',
    pageTitle: 'editJobPage.heading'
  }
};

export const getPageTitle = (stepId: EditJobStepIds) =>
  stepsIndicatorInfo[stepId].pageTitle;

export const getStepTitle = (stepId: EditJobStepIds) =>
  stepsIndicatorInfo[stepId].title;

export const ignoreIndicator = (stepId: EditJobStepIds) =>
  Boolean(stepsIndicatorInfo[stepId].ignoreIndicator);

export const totalNumberOfSteps = (steps: StepPathInfo<EditJobStepIds>[]) =>
  getTotalStepsForIndicator<EditJobStepIds>(steps, stepsIndicatorInfo);

export const getStepNumber = (
  steps: StepPathInfo<EditJobStepIds>[],
  currentPathname: string
) => getStepNumberForIndicator(steps, stepsIndicatorInfo, currentPathname);

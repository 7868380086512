import { isJobClosedOrExpired } from '@common/helpers/jobs';
import { useGetRepostJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { getTotalApplicationCount } from '@store/entities/applications';
import { getJobById } from '@store/entities/jobs';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useRepostJobContainer(jobId: string) {
  const { redirect } = useRedirect();
  const { countryCode } = useAppSelector(getLocalisation);
  const { status } = useAppSelector(getJobById(jobId));
  const totalApplicationCount =
    useAppSelector(getTotalApplicationCount(jobId)) || 0;

  const shouldRenderRepostJobContainer =
    isJobClosedOrExpired(status) && totalApplicationCount < 1;

  const { repostJobLink } = useGetRepostJobLink(jobId);

  return {
    shouldRenderRepostJobContainer,
    redirectToRepostPage: redirect(repostJobLink),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import Div from '../../../../common/components/Div/Div';
import { RedirectUnauthenticatedUser } from '../../../components/RedirectUnauthenticatedUser';
import UpdatePasswordForm from './components/UpdatePasswordForm/UpdatePasswordForm';

@connect(({ localisation }) => ({
  countryCode: localisation.countryCode
}))
@withTranslation()
export default class UpdatePassword extends Component {
  static propTypes = {
    countryCode: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
  };

  render() {
    const { t, countryCode } = this.props;
    return (
      <Div>
        <RedirectUnauthenticatedUser to={`/${countryCode}/login`} />
        <JoraEmployerHelmet title={t('updatePasswordPage.title')} />
        <Box
          paddingHorizontal="medium"
          marginTop="medium"
          marginBottom="medium"
          tone="transparent"
        >
          <Heading level="h1">{t('updatePasswordPage.heading')}</Heading>
        </Box>

        <UpdatePasswordForm />
      </Div>
    );
  }
}

import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { AD_TYPE } from '@seek/je-shared-data';
import { upgraded } from '@src/job';

const getValidatedAdType = (adType) => {
  if (Object.values(AD_TYPE).includes(adType)) {
    return adType as AD_TYPE;
  }

  return null;
};

export const useGetAdTypeParam = () => {
  const params = useSearchQueryParam();

  const adType = getValidatedAdType(params.getQueryParam('adType'));
  const isPremiumAdType = Boolean(adType && upgraded(adType));

  return {
    adType,
    isPremiumAdType
  };
};

import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { BrandAndCountryConfig } from '@common/types';
import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { getMonetisationFeatureFlags } from '@store/config';
import { useAppSelector } from '@store/hooks';

export function useMonetisationEnabled(jobCountryCode: string) {
  const brandName = useBrandName();
  const monetisationFlags = useAppSelector(getMonetisationFeatureFlags());
  const isEnabled = monetisationEnabled({
    monetisationFlags,
    brand: brandName,
    jobCountryCode
  });

  return isEnabled;
}

type IsMonetisationEnabledParams = {
  monetisationFlags: BrandAndCountryConfig;
  brand: BrandName;
  jobCountryCode: string;
};
export const monetisationEnabled = ({
  monetisationFlags,
  brand,
  jobCountryCode
}: IsMonetisationEnabledParams) => {
  return Boolean(monetisationFlags?.[brand]?.[jobCountryCode]);
};

// Workaround until we fix Stripe issue: https://github.com/SEEK-Jobs/je-ui/pull/1480
export const useMismatchedStripeAccount = (
  jobCountryCode: SupportedCountryCode
) => {
  const brand = useBrandName();
  const siteCountryCode = useSiteCountryCode();

  return mismatchedStripeAccount({
    brand,
    jobCountryCode,
    siteCountryCode
  });
};

type Props = {
  brand: BrandName;
  jobCountryCode: SupportedCountryCode;
  siteCountryCode: SupportedCountryCode;
};

export const mismatchedStripeAccount = ({
  brand,
  jobCountryCode,
  siteCountryCode
}: Props) => {
  if (brand !== BrandName.JOBSTREETEXPRESS) return false;

  if (
    siteCountryCode === SupportedCountryCode.Singapore &&
    jobCountryCode === SupportedCountryCode.Indonesia
  ) {
    return true;
  }
  if (
    siteCountryCode === SupportedCountryCode.Indonesia &&
    jobCountryCode === SupportedCountryCode.Singapore
  ) {
    return true;
  }

  return false;
};

import { verifyEmail } from '@store/auth';
import { useAppDispatch } from '@store/hooks';

type VerifyEmailProps = {
  verificationCode: string;
};

export const useVerifyEmail = () => {
  const dispatch = useAppDispatch();

  return async ({ verificationCode }: VerifyEmailProps) => {
    const response = await dispatch(verifyEmail({ verificationCode }));

    return { isSuccessful: !response?.error, error: response?.error };
  };
};

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpgradeJobWithCoinsMutation } from '@common/hooks/job';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';

type Params = {
  jobId: string;
  countryCode: SupportedCountryCode;
  onSuccessfulPayment: () => void;
};

export const useOnCoinPaymentSubmit = ({
  jobId,
  countryCode,
  onSuccessfulPayment
}: Params) => {
  const { t } = useTranslation();
  const { upgradeJobWithCoins, errorMessage, isSuccessful } =
    useUpgradeJobWithCoinsMutation();

  const errorText = errorMessage?.message
    ? t(errorMessage.message, errorMessage.properties)
    : '';

  useEffect(() => {
    if (isSuccessful) {
      onSuccessfulPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessful]);

  const onCoinPaymentPress = (selectedAdType?: UpgradeableAdType) => {
    if (!selectedAdType) throw new Error('Ad type is required');

    upgradeJobWithCoins({
      jobId,
      adType: selectedAdType,
      countryCode
    });
  };

  return { onCoinPaymentPress, errorMessage: errorText };
};

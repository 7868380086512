import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';

export const pathUserHasLimitedAccessTo = ({
  countryCode,
  userDetails: {
    appealStatus,
    emailVerified,
    emailLastVerifiedAt: _emailLastVerifiedAt
  }
}) => {
  // For sign-up usecase in CAJA
  if (!emailVerified && !_emailLastVerifiedAt) {
    return `/${countryCode}/sign-up/account/verify-email`;
  }

  // EXISTING UNVERIFIED USER
  // Applies for users who have never verified their email
  // add `&& !emailLastVerifiedAt` to the condition to make it work for existing users after sign-up usecase has been added
  if (!emailVerified) {
    return `/${countryCode}/account/verify-email`;
  }

  if (appealStatus === AppealStatus.REQUESTED) {
    return `/${countryCode}/account/verify-account-details`;
  }

  if (appealStatus === AppealStatus.SUBMITTED) {
    return `/${countryCode}/account/verify-account-details-success`;
  }
};

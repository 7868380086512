import { AD_TYPE } from '@seek/je-shared-data';
import { upgraded } from '@src/job';
import { stepConfig } from './stepConfig';

export type GetStepsParams = {
  isUpgradeable: boolean;
  hasJobUpgraded: boolean;
  selectedAdType?: AD_TYPE;
};

export const getSteps = (params: GetStepsParams) => {
  const { isUpgradeable, selectedAdType, hasJobUpgraded } = params;

  const {
    editTitle,
    editBasicDetails,
    editJobSuccess,
    enhanceJob,
    payment,
    jobSummaryAndDesc
  } = stepConfig(selectedAdType);

  return [
    editTitle,
    editBasicDetails,
    ...(isUpgradeable ? [enhanceJob] : []),
    ...(isUpgradeable && upgraded(selectedAdType) && !hasJobUpgraded
      ? [payment]
      : []),
    jobSummaryAndDesc,
    editJobSuccess
  ];
};

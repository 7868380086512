import { UpdateDraftParams } from '@common/hooks/job/useUpdateDraft';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';
import { removeUndefined } from '@src/lib/func/removeUndefined';
import { FormData } from '../../PostJob';

type ShiftAvailabiltyParam = UpdateDraftParams['draft']['shiftAvailability'];

export const toUpdateDraftParams = (
  draft: FormData
): UpdateDraftParams['draft'] =>
  removeUndefined<UpdateDraftParams['draft']>({
    businessId: draft.business?.id,
    title: draft.jobTitle?.jobTitle,
    role: draft.jobTitle?.role,
    workExperience: draft.workExperience,
    salary: mapSalary(draft.salary),
    type: draft.jobType,
    shiftAvailability: draft.shiftAvailability as ShiftAvailabiltyParam
  });

const mapSalary = (salary: FormData['salary']) => {
  if (!salary) return undefined;
  if (!salary.amount) return null;

  return salary as Salary;
};

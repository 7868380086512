import { editJobStepIds } from '@common/components/Caja/steps/MultiStepJobEdit';
import { AD_TYPE } from '@seek/je-shared-data';

export const stepConfig = (selectedAdType?: AD_TYPE) =>
  ({
    editTitle: {
      id: editJobStepIds.title,
      path: '/job-title-business-selection'
    },
    editBasicDetails: {
      id: editJobStepIds.basicDetails,
      path: '/basic-details'
    },
    enhanceJob: {
      id: 'enhanceJob',
      path: '/enhance-job'
    },
    payment: {
      id: 'payment',
      path: '/payment',
      queryParams: `adType=${selectedAdType}`
    },
    jobSummaryAndDesc: {
      id: 'jobSummaryAndDesc',
      path: '/review-job-description',
      queryParams: `adType=${selectedAdType}`
    },
    editJobSuccess: {
      id: 'editJobSuccess',
      path: '/edit-job-success'
    }
  } as const);

type StepConfig = ReturnType<typeof stepConfig>;

export type EditJobStepIds = StepConfig[keyof StepConfig]['id'];

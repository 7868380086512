import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { ShiftAvailabilityType } from '@seek/je-shared-data/lib/types/jobs';
import { shiftAvailabilityRules } from '../../rules';
import { ShiftAvailabilityRadioField } from './ShiftAvailabilityRadioField';

type ShiftAvailabilityProps<T extends FieldValues> = UseControllerProps<T> & {
  overrides?: {
    label?: string;
    options?: {
      key: ShiftAvailabilityType;
      label: React.ReactElement;
    }[];
  };
};

export function ShiftAvailability<T extends FieldValues>({
  name,
  control,
  overrides
}: ShiftAvailabilityProps<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={shiftAvailabilityRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <ShiftAvailabilityRadioField
          onChange={onChange}
          label={overrides?.label}
          options={overrides?.options}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}

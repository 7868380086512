import React from 'react';
import { CookieConsent as InnerField } from '@common/components/Account/components/CookieConsent';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { FieldWrapper } from '../FieldWrapper';

export function CookieConsent() {
  const { cookiesConsentRules } = useAccountRules();

  return (
    <FieldWrapper<boolean>
      name="cookieConsent"
      rules={cookiesConsentRules}
      render={(props) => <InnerField {...props} />}
    />
  );
}

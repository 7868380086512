import { useCallback } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { AppError, getRequestState } from '@store/app';
import { load } from '@store/entities/businesses/actions';
import { getBusiness } from '@store/entities/businesses/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useGetBusiness = (businessId?: string) => {
  const dispatch = useAppDispatch();
  const business = useAppSelector(getBusiness(businessId));

  const error = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LOAD)
  ).error;

  const fetchBusiness = useCallback(async () => {
    if (!businessId) return;

    await dispatch(load(businessId));
  }, [dispatch, businessId]);

  return {
    fetchBusiness,
    error: getClientError(error),
    data: business
  };
};

const getClientError = (error?: AppError) => {
  if (!error) return;

  const errorMessage =
    error.properties?.errorMessage || 'errors.defaultWithGuid';

  return new ClientError(errorMessage, {
    guid: error?.properties?.guid
  });
};

import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { VIDEO_SCREENING_STATUS } from '@seek/je-shared-data/lib/types/applications';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';

type VideoScreeningFilterProps = {
  applications: Application[];
  onChange: (selectedValue: boolean) => void;
  onClear: () => void;
  value?: boolean;
};

export const VideoScreeningFilter = ({
  applications = [],
  value = false,
  onChange,
  onClear
}: VideoScreeningFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: boolean) => {
    if (value === checkboxValue) {
      return onClear();
    }

    return onChange(checkboxValue);
  };

  return (
    <div className="space-y-md">
      <div className="flex flex-row justify-between">
        <div className="text-md">
          {t('applications.applicationsFilter.videoScreening.title')}
        </div>
        <ClearLink onClear={onClear} active={value} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(({ key, label, value: optionValue }) => (
          <div className="w-full md:w-1/2 mb-md">
            <Checkbox
              key={key}
              value={value === optionValue}
              label={label}
              onChange={() => handleCheckbox(optionValue)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const options = (applications: Application[], t: TFunction) => {
  const counts = applications.reduce(
    (acc, { videoScreeningStatus: status }) => {
      if (status === VIDEO_SCREENING_STATUS.VIDEO_READY) {
        acc[status] = (acc[status] || 0) + 1;
      }

      return acc;
    },
    { [VIDEO_SCREENING_STATUS.VIDEO_READY]: 0 }
  );

  return Object.entries(counts).map(([value, count]) => ({
    key: value,
    label: `${t(
      'applications.applicationsFilter.videoScreening.option'
    )} (${count})`,
    value: value === VIDEO_SCREENING_STATUS.VIDEO_READY
  }));
};

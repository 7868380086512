import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetBusinessListData } from '@common/hooks/business';
import { removeDeleted } from '@src/lib/func';
import { BusinessWithUsers } from '@store/entities/businesses/types';

export type LoadBusinesses = {
  isLoading: boolean;
  businesses: BusinessWithUsers[];
};

export const useLoadBusinesses = (): LoadBusinesses => {
  const { businesses, getBusinessListData } = useGetBusinessListData();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getBusinessListDataRef = useRef(getBusinessListData);

  useEffect(() => {
    (async () => {
      try {
        await getBusinessListDataRef.current();
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const memoizedBusinesses = useMemo(
    () => removeDeleted(businesses) as BusinessWithUsers[],
    [businesses]
  );

  return {
    isLoading,
    businesses: memoizedBusinesses
  };
};

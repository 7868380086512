import {
  TFunction,
  useTranslation as useReactTranslation
} from 'react-i18next';

export { Trans as I18nextTrans } from 'react-i18next';

export type T = TFunction;

export function useTranslation() {
  const { t } = useReactTranslation();

  return { t };
}

import { useBrandName } from '@common/hooks/localisation';
import { getFeatures } from '@store/config';
import { useAppSelector } from '@store/hooks';

export const usePremiumApplicantFiltersFeature = () => {
  const features = useAppSelector(getFeatures());
  const brand = useBrandName();

  return Boolean(features?.premiumApplicantFilters[brand]);
};

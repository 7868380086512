import { AD_TYPE } from '@seek/je-shared-data';

export const stepConfig = (selectedAdType?: AD_TYPE) =>
  ({
    enhanceJob: {
      id: 'enhanceJob',
      path: '/enhance-job'
    },
    payment: {
      id: 'payment',
      path: '/payment'
    },
    paymentSuccess: {
      id: 'paymentSuccess',
      path: '/payment-success',
      queryParams: `adType=${selectedAdType}`
    }
  } as const);

type StepConfig = ReturnType<typeof stepConfig>;

export type EnhanceJobStepIds = StepConfig[keyof StepConfig]['id'];

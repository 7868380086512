import React from 'react';
import { RadioCard } from '@common/components/FormElements/RadioCard';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { Badge } from '@components/Badge';
import { PlusAdInfo } from '../AdInfo';

type PlusAdCardProps = {
  adDuration?: number;
  price: React.ReactNode;
  onChange: (adType: AD_TYPE) => void;
  value: string;
  disabled: boolean;
};

export const PlusAdCard = (props: PlusAdCardProps) => {
  const { adDuration, onChange, value, disabled, price } = props;

  const { t } = useTranslation();
  const isValuePlusAd = () => value === AD_TYPE.PLUS;

  return (
    <RadioCard
      testID="plus-radio-card"
      checked={isValuePlusAd()}
      onPress={() => onChange(AD_TYPE.PLUS)}
      disabled={disabled}
      header={
        <div className="flex w-full justify-end">
          <div className="flex w-fit">
            <Badge tone="promo">{t('upgradeJobForm.recommended')}</Badge>
          </div>
        </div>
      }
      content={
        <div className="mt-sm px-1">
          <PlusAdInfo adDuration={adDuration} price={price} />
        </div>
      }
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { Button } from '@components/Button';
import { Row } from '@components/Row';

export const JobsDbPostJobAction = () => {
  const { t } = useTranslation();
  const { postJobLink } = useGetPostJobLink();
  const { redirect } = useRedirect();

  return (
    <>
      {/* // TODO: cleanup after jobsDB shutdown */}
      <Row
        marginHorizontal={{ desktop: 'none', tablet: 'none', mobile: 'none' }}
        tone="transparent"
        alignItems={{
          mobile: 'stretch',
          tablet: 'flex-end',
          desktop: 'flex-end'
        }}
      >
        <Button
          testID="post-job-button"
          onPress={redirect(postJobLink)}
          disabled={true}
        >
          {t('dashboardPage.action.postJob')}
        </Button>
      </Row>
    </>
  );
};

import React from 'react';
import { Skeleton } from '@common/components/Skeleton';
import { Box } from '@components/Box';
import {
  Card,
  CardAction,
  CardActions,
  CardContent,
  CardFooter
} from '@components/Card';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import * as colours from '../../../../../common/theme/palette/colours';

const gridRowHeight = 8;
type Props = {
  count?: number;
  showButtonSkeleton?: boolean;
};

export const ListJobSkeleton = ({ count = 1, showButtonSkeleton }: Props) => {
  return (
    <Box tone="transparent">
      {showButtonSkeleton && (
        <Box
          tone="transparent"
          marginTop="medium"
          marginBottom="small"
          direction="row"
          justifyContent="flex-end"
        >
          <Skeleton
            highlightColor={colours.grey200}
            baseColor={colours.grey300}
            width={200}
            height={gridRowHeight * 6}
            marginRight={gridRowHeight * 2}
          />
        </Box>
      )}

      {new Array(count).fill('').map((_, index) => (
        <Card
          borderWidth="xsmall"
          marginTop={!showButtonSkeleton || index !== 0 ? 'medium' : 'none'}
          key={index}
        >
          <CardContent>
            <Row alignItems="center" tone="transparent" marginBottom="small">
              <Skeleton width={300} height={gridRowHeight * 2} />
            </Row>

            <Skeleton width={100} height={gridRowHeight * 2} />

            <Row tone="transparent" marginTop="medium" alignItems="center">
              <Skeleton
                width={48}
                height={gridRowHeight * 6}
                marginRight={gridRowHeight}
              />
              <Column spacing="xxsmall">
                <Skeleton width={200} height={gridRowHeight * 2} />
                <Skeleton width={100} height={gridRowHeight * 2} />
              </Column>
            </Row>
          </CardContent>
          <CardFooter>
            <CardAction>
              <Skeleton width={125} height={gridRowHeight * 2} />
            </CardAction>

            <CardActions>
              <Skeleton width={60} height={gridRowHeight * 2} />
              <Skeleton width={60} height={gridRowHeight * 2} />
            </CardActions>
          </CardFooter>
        </Card>
      ))}
    </Box>
  );
};

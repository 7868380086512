import React from 'react';
import {
  BoostPerformanceNudge,
  SetupForSuccessNudge
} from '@common/components/upgradeJobAd/UpgradeNudges';
import { useGetEnhanceJobLink } from '@common/hooks/appPageLinks';
import { JOB_STATUS } from '@seek/je-shared-data';
import { isLive } from '@src/job';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';

type Props = {
  jobStatus: JOB_STATUS;
  jobId: string;
};

export const UpgradeAdCallout = ({ jobId, jobStatus }: Props) => {
  const enhancedJobLink = useGetEnhanceJobLink({
    jobId,
    campaign: PAYMENT_CAMPAIGN.CANDIDATE_MANAGEMENT
  });

  if (isLive(jobStatus)) {
    return <BoostPerformanceNudge url={enhancedJobLink} />;
  }

  return <SetupForSuccessNudge url={enhancedJobLink} />;
};

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { Else, If, Then } from '@common/components/If';
import { AUTH_EXCEPTION, GENERAL_EXCEPTION } from '@seek/je-error-helper';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import ButtonWithSecondaryLink from '../../../common/components/ButtonWithSecondaryLink/ButtonWithSecondaryLink';
import ExternalLink from '../../../common/components/ExternalLink/ExternalLink';
import EmailAddressField from '../../../common/components/FormElements/EmailAddressField/EmailAddressField';
import REQUEST_KEY from '../../constants/requestKeys';
import * as link from '../../helpers/links';
import * as authActions from '../../store/auth/actions';
import ConnectedErrorMessage from '../ConnectedErrorMessage/ConnectedErrorMessage';
import Form from '../ConnectedForm/ConnectedForm';
import Submit from '../Submit/Submit';
import { validateForgotPasswordForm } from './helpers/validation';

@connect(
  ({ app, localisation }) => ({
    hasError: !!app.error[REQUEST_KEY.AUTH.FORGOT_PASSWORD],
    localisation: localisation
  }),
  {
    forgotPassword: authActions.forgotPassword
  }
)
@reduxForm({
  form: 'forgot-password-form',
  validate: validateForgotPasswordForm
})
@withTranslation()
export default class ForgotPasswordForm extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    forgotPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      supportTier: PropTypes.number.isRequired
    })
  };

  async submitForgotPassword(data) {
    const { forgotPassword } = this.props;
    const email = data.email.trim();

    await forgotPassword({ email });
  }

  render() {
    const {
      handleSubmit,
      hasError,
      valid,
      submitFailed,
      localisation: {
        brandConfig: { name: brandName },
        countryCode,
        supportTier
      },
      t
    } = this.props;

    return (
      <Stack tone="transparent" spacing="medium">
        <Card>
          <CardContent>
            <Form
              requestKey={REQUEST_KEY.AUTH.FORGOT_PASSWORD}
              reduxFormSubmit={handleSubmit}
              onSubmit={this.submitForgotPassword.bind(this)}
              valid={valid}
              submitFailed={submitFailed}
              insideCard
              errorSummary={t('validations.general.summary')}
            >
              <Field
                component={EmailAddressField}
                label={t('common.fieldLabel.email')}
                fieldId="email"
                name="email"
                firstField
                validationMessages={{
                  ...t('validations.general.email', { returnObjects: true }),
                  [AUTH_EXCEPTION.USER_NOT_FOUND]: t(
                    'errors.authentication.forgotPassword.userNotFoundException'
                  ),
                  [GENERAL_EXCEPTION.UNVERIFIED_EMAIL_USER]: t(
                    'errors.authentication.forgotPassword.emailUnverifiedException'
                  )
                }}
              />

              <ButtonWithSecondaryLink
                linkText={t('common.action.cancel')}
                linkLocation={`/${countryCode}/login`}
                button
                insideCard
                extraTopMargin
              >
                <Submit insideCard extraTopMargin>
                  {t('forgotPasswordForm.action.sendEmail')}
                </Submit>
              </ButtonWithSecondaryLink>
            </Form>
            <ConnectedErrorMessage
              hideWithSubmissionError
              requestKey={REQUEST_KEY.AUTH.FORGOT_PASSWORD}
              extraTopMargin
            />
          </CardContent>
        </Card>
        {!hasError && (
          <Card>
            <CardContent>
              <Column spacing="xxsmall" marginTop="medium">
                <Text weight="medium">{t('common.message.stillNeedHelp')}</Text>
                <If condition={supportTier === SupportLevel.TIER_1}>
                  <Then>
                    <ExternalLink
                      href={link.buildCandidateLink({
                        brandName,
                        countryCode,
                        page: link.CONTACT_PAGE
                      })}
                      target="_blank"
                    >
                      {t('common.linkText.contactUs')}
                    </ExternalLink>
                  </Then>
                  <Else>
                    <ExternalLink
                      href={link.buildCandidateLink({
                        brandName,
                        countryCode,
                        page: link.FAQ_EMPLOYER_PAGE
                      })}
                      target="_blank"
                    >
                      {t('common.linkText.faqs')}
                    </ExternalLink>
                  </Else>
                </If>
              </Column>
            </CardContent>
          </Card>
        )}
      </Stack>
    );
  }
}

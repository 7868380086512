import { mismatchedStripeAccount } from '@common/hooks/featureToggles/useMonetisationEnabled';
import { monetisationEnabled } from '@common/hooks/featureToggles/useMonetisationEnabled/useMonetisationEnabled';
import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { toLowerCase } from '@src/lib/formatting';
import { getMonetisationFeatureFlags } from '@store/config';
import { useAppSelector } from '@store/hooks';

export function useUpgradePermissions() {
  const brand = useBrandName();
  const siteCountryCode = useSiteCountryCode();
  const monetisationFlags = useAppSelector(getMonetisationFeatureFlags());

  const isUpgradeable = (jobCountryCode?: SupportedCountryCode) => {
    const formattedCountryCode =
      toLowerCase<SupportedCountryCode>(jobCountryCode);

    if (!formattedCountryCode) return false;

    const isMismatchedStripeAccount = mismatchedStripeAccount({
      brand,
      jobCountryCode: formattedCountryCode,
      siteCountryCode
    });
    const isMonetisationEnabled = monetisationEnabled({
      brand,
      jobCountryCode: formattedCountryCode,
      monetisationFlags
    });

    return (
      Boolean(isMismatchedStripeAccount) === false &&
      Boolean(isMonetisationEnabled)
    );
  };

  return {
    isUpgradeable
  };
}

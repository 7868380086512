import React from 'react';
// import { EnhanceJob } from '@common/components/Job/EnhanceJob/variants/default_v2';
// import { EnhanceJob as EnhanceJobJse } from '@common/components/Job/EnhanceJob/variants/jse';
// import { EnhanceJob as EnhanceJobSingapore } from '@common/components/Job/EnhanceJob/variants/jse_sg';
// import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';

export const EnhanceJobTest = () => {
  return (
    <Card>
      <CardContent>
        {/* <Heading level="h2">{'Enhance job form JSE_SG:'}</Heading>
        <EnhanceJobSingapore
          jobCountryCode={SupportedCountryCode.Singapore}
          onBack={() => window.alert('Back!')}
          onSubmit={() => window.alert('Submitted!')}
        /> */}
        <Heading level="h2">{'Enhance job form JSE:'}</Heading>
        {/* <EnhanceJobJse
          jobCountryCode={SupportedCountryCode.Indonesia}
          onBack={() => window.alert('Back!')}
          onNext={() => window.alert('Next!')}
          onSubmit={() => window.alert('Submitted!')}
          onChangeAdType={() => window.alert('Ad type changed!')}
        /> */}
        {/* <Heading level="h2">{'Enhance job form JSE_SG:'}</Heading>
        <EnhanceJobSingapore
          jobCountryCode={SupportedCountryCode.Singapore}
          onBack={() => window.alert('Back!')}
          onSubmit={() => window.alert('Submitted!')}
        /> */}
      </CardContent>
    </Card>
  );
};

import { ComponentType } from 'react';
import { matchPath } from 'react-router-dom';
import { Route } from '@common/types';
import { AppDispatch, GetState } from '@store/store';

type getPromisesProps = {
  url: string;
  store: { dispatch: AppDispatch; getState: GetState };
  routes: Route[];
  history?: History;
};

export function getPromises({ url, store, routes, history }: getPromisesProps) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function flatten(routes, prefix, flattenedRoutes, previousComponents) {
    if (routes.childRoutes) {
      for (const route of routes.childRoutes.filter((r) => r)) {
        const flattenedRoute = {
          path: prefix + route.path,
          components: [...previousComponents, route.component],
          exact: true
        };

        flattenedRoutes.push(flattenedRoute);

        flatten(route, prefix + route.path + '/', flattenedRoutes, [
          ...previousComponents,
          route.component
        ]);
      }
    }
  }

  const flattenedRoutes: (Route & { components: ComponentType[] })[] = [];
  const promises: Promise<any>[] = [];

  flatten(routes, '/', flattenedRoutes, []);

  flattenedRoutes.some((route) => {
    const match = matchPath(getPathFromUrl(url), route);

    if (match) {
      const { params } = match;

      for (const component of route.components) {
        // todo: component.WrappedComponent?
        const wrappedComp = component
          ? (component as any).WrappedComponent
          : null;

        if (wrappedComp && wrappedComp.fetchData) {
          promises.push(
            wrappedComp.fetchData({ store, params, history, route })
          );
        }
      }
    }

    return match;
  });

  return promises;
}

function getPathFromUrl(url) {
  return url.split('?')[0];
}

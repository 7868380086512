import isMatch from 'lodash.ismatch';
import { GroupedLocationFilterOptions, LocationFilterOption } from './type';

/**
 * Groups location filter options by their country code.
 *
 * @param {LocationFilterOption[]} locationFilterOptions - An array of location filter options to be grouped.
 * @returns {GroupedLocationFilterOptions} An object where the keys are country codes and the values are arrays of grouped location filter options.
 *
 * Each grouped location filter option includes:
 * - `counter`: The number of times the option appears.
 * - `selected`: A boolean indicating whether the option is selected.
 */
export const groupLocationFilterOptionsByCountryCode = (
  locationFilterOptions: LocationFilterOption[]
): GroupedLocationFilterOptions => {
  const groupedLocationFilters = {};

  for (const option of locationFilterOptions) {
    const countryKey = option.value.countryCode;
    groupedLocationFilters[countryKey] =
      groupedLocationFilters[countryKey] || [];

    const existingOption = groupedLocationFilters[countryKey].find((entry) =>
      isMatch(entry.value, option.value)
    );

    if (existingOption) {
      existingOption.counter += 1;
    } else {
      groupedLocationFilters[countryKey].push({
        ...option,
        counter: 1,
        selected: false
      });
    }
  }

  return groupedLocationFilters;
};

import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useTranslation } from '@common/hooks/useTranslation';
import { useAtBusinessLimit } from '@common/hooks/userPackages';
import { RedirectTo } from '@common/search';
import { getRequestState } from '@store/app';
import { getUser } from '@store/auth';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useAppSelector } from '@store/hooks';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { DisplayBusinessInformation } from './DisplayBusinessInformation';
import { EditableBusinessInformation } from './EditableBusinessInformation';
import { CreateBusinessLink } from './components/CreateBusinessLink';
import { LoadBusinessErrorMessage } from './components/LoadBusinessErrorMessage';
import { PostJobFormBusiness } from './types';

type BusinessInformationProps = {
  isBusinessEditable: boolean;
  shouldRedirectBusinessCreatedTo: RedirectTo | undefined;
  handleBusinessSelected: (business: BusinessWithUsers) => void;
  business?: PostJobFormBusiness;
  error?: string;
};

export const BusinessInformation = ({
  business,
  isBusinessEditable,
  shouldRedirectBusinessCreatedTo,
  handleBusinessSelected,
  error
}: BusinessInformationProps) => {
  const { isAuthenticated } = useAppSelector(getUser);
  const { t } = useTranslation();
  const { error: loadBusinessesError } = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  );
  const atBusinessLimit = useAtBusinessLimit();

  const renderBusinessInformation = (selectedBusiness: PostJobFormBusiness) =>
    isBusinessEditable && shouldRedirectBusinessCreatedTo ? (
      <EditableBusinessInformation
        selectedBusiness={selectedBusiness}
        shouldRedirectBusinessCreatedTo={shouldRedirectBusinessCreatedTo}
        onBusinessSelected={handleBusinessSelected}
        disableAddBusiness={atBusinessLimit}
      />
    ) : (
      <DisplayBusinessInformation selectedBusiness={selectedBusiness} />
    );

  if (!isAuthenticated) return null;

  return (
    <Stack spacing="medium">
      <Text>
        {isBusinessEditable
          ? t('postJobForm.businessInformation.businessWillBeLinkedToJob')
          : t('postJobForm.businessInformation.businessAlreadyLinkedToJob')}
      </Text>

      {loadBusinessesError && <LoadBusinessErrorMessage />}
      {!loadBusinessesError && !business && <CreateBusinessLink />}
      {business && renderBusinessInformation(business)}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Stack>
  );
};

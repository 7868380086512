import React from 'react';
import { StepWrapper } from '@common/components/Caja/components';
import { PaymentForm } from '@common/components/Payment/PaymentForm/PaymentFormWithCurrency';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useLoadAdPrices } from '@common/hooks/payment';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';
import { PAYMENT_CAMPAIGN } from '@store/payment/constants';
import { useGetUtmParams } from '../hooks/useGetUtmParams';

type Props = Pick<
  TopLevelStep<{
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    adType: UpgradeableAdType;
    campaign?: string;
    onCompletedUrl: string;
  }>,
  'onBack' | 'stepInfo'
>;

export const PaymentJob = (props: Props) => {
  useRedirectUnauthenticatedUser();
  const utmParams = useGetUtmParams();

  const {
    stepInfo: {
      jobId,
      jobCountryCode,
      adType,
      onCompletedUrl,
      campaign = PAYMENT_CAMPAIGN.MULTI_STEP_CAJA
    },
    onBack
  } = props;

  const { data: currencyPrice } = useLoadAdPrices(jobCountryCode);

  return (
    <StepWrapper>
      <PaymentForm
        stepInfo={{
          adType,
          jobId,
          jobCountryCode,
          paymentCampaign: campaign,
          currencyPrice,
          sourceParams: utmParams
        }}
        onCompletedUrl={onCompletedUrl}
        onBack={onBack}
      />
    </StepWrapper>
  );
};

import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { rightToWorkRules } from '../../rules';
import { RightToWorkRadioField } from './RightToWorkRadioField';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label?: string | React.ReactNode;
  options?: { label: string; key: boolean }[];
};

export function RightToWork<T extends FieldValues>({
  name,
  label,
  options,
  control
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={rightToWorkRules(t)}
      render={({ field: { onChange, value }, formState }) => {
        return (
          <RightToWorkRadioField
            onChange={onChange}
            options={options}
            label={label}
            value={value}
            disabled={formState.disabled}
            error={formState?.errors[name]?.message as string}
          />
        );
      }}
    />
  );
}

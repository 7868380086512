import { OnSubmitValues } from '@common/components/Job/EnhanceJob/EnhanceJobWithCurrency';

type Params = OnSubmitValues;

export const toJob = (jobId: string, job: Params) => ({
  id: jobId,
  rightToWork: job.rightToWork,
  immediateStart: job.immediateStart,
  videoQuestions: [
    {
      code: job.videoScreeningQuestion
    }
  ]
});

export default {
  APPLICATION: {
    LOAD: 'application/load',
    LOAD_COUNT: 'application/load-count',
    TRACK_INTERACTION: 'application/track-interaction',
    GET_RESUME_URL_BY_ID: (id) => `application/${id}/get-resume-url`,
    UPDATE_APPLICANT_STATUS_BY_ID: (id) =>
      `application/${id}/update-applicant-status`,
    UPDATE_NOTE: 'application/update-note',
    UPDATE_INTERACTION: 'application/update-interaction',
    GET_SIGNED_URL: 'application/get-signed-url'
  },
  AUTH: {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    REGISTER: 'auth/register',
    RESET_PASSWORD: 'auth/reset-password',
    FORGOT_PASSWORD: 'auth/forgot-password',
    UPDATE_PASSWORD: 'auth/update-password',
    UPDATE_ACCOUNT: 'auth/update-account',
    SUBMIT_ACCOUNT_APPEAL: 'auth/submit-account-appeal',
    VERIFY_EMAIL: 'auth/verify-email',
    RESEND_VERIFY_EMAIL: 'auth/resend-verify-email',
    GET_ACCOUNT_DETAILS: 'auth/get-account-details',
    DELETE_ACCOUNT_REQUEST: 'auth/delete-account-request'
  },
  BUSINESS: {
    CREATE: 'business/create',
    EDIT: 'business/edit',
    LOAD: 'business/load',
    LIST: 'business/list',
    DELETE: 'business/delete'
  },
  JOB: {
    CREATE: 'job/create',
    EDIT: 'job/edit',
    DELETE: 'job/delete',
    LOAD: 'job/load',
    LIST: 'job/list',
    ACTION: {
      SHARE: 'job/action/share',
      UPGRADE: 'job/action/upgrade'
    },
    GET_AD_DURATION: 'job/get-ad-duration'
  },
  PAYMENT: {
    GET_AD_PRICES: 'payment/get-ad-prices',
    CREATE_PAYMENT_INTENT: 'payment/create-payment-intent',
    CONFIRM_PAYMENT: 'payment/confirm-payment',
    LOAD_PAYMENT_ELEMENT: 'payment/load-payment-element'
  },
  COINS: {
    PRICING: {
      GET: 'coins/pricing/get'
    },
    BALANCE: {
      GET: 'coins/balance/get'
    },
    NEXT_EXPIRING_CREDIT: {
      GET: 'coins/next-expiring-credit/get'
    },
    TRANSACTIONS: {
      GET: 'coins/transactions/get'
    }
  },
  RECAPTCHA: 'recaptcha',
  ORGANISATION: {
    ACTION: {
      REQUEST_UPGRADE_CALLBACK: 'organisations/actions/upgrade'
    },
    BUSINESS_COUNT_VS_LIMIT: 'organisations/business-count-vs-limit',
    USER_LIMITS: {
      GET: 'organisations/user-limits/get'
    },
    USER_PACKAGE: {
      GET: 'organisations/user-package/get'
    }
  },
  UNSUBSCRIBE: {
    GET_EMAIL_PREFERENCE_CENTER_URL:
      'unsubscribe/braze-api/get-email-preference-center-url'
  }
};

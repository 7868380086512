import {
  getStepNumberForIndicator,
  getTotalStepsForIndicator
} from '@common/hooks/multiStep';
import { StepIndicatorInfo, StepPathInfo } from '@common/hooks/multiStep/types';
import { SignUpStepIds } from './stepConfig';

const stepsIndicatorInfo: StepIndicatorInfo<SignUpStepIds> = {
  createAccount: {
    title: 'createAccountStep.stepTitle',
    pageTitle: 'createAccountStep.heading'
  },
  verifyEmail: {
    title: 'verifyEmailStep.stepTitle',
    pageTitle: 'verifyEmailStep.heading'
  },
  createBusiness: {
    title: 'createBusinessStep.stepTitle',
    pageTitle: 'createBusinessStep.heading'
  },
  enhanceJob: {
    title: 'enhanceJobStep.stepTitle',
    pageTitle: 'enhanceJobStep.heading'
  },
  payment: {
    title: '',
    pageTitle: 'paymentJobStep.payWithCardHeading',
    ignoreIndicator: true
  },
  jobSummaryAndDesc: {
    title: 'summaryAndDescStep.stepTitle',
    pageTitle: 'summaryAndDescStep.heading'
  },
  postJobSuccess: {
    title: '',
    pageTitle: '',
    ignoreIndicator: true
  },
  title: {
    title: 'postJobTitleStep.stepTitle',
    pageTitle: 'postJobTitleStep.heading'
  },
  basicDetails: {
    title: 'postJobBasicDetailsStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  },
  editTitle: {
    title: 'postJobTitleStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  },
  editBasicDetails: {
    title: 'postJobBasicDetailsStep.stepTitle',
    pageTitle: 'postJobBasicDetailsStep.heading'
  }
};

export const getPageTitle = (stepId: SignUpStepIds) =>
  stepsIndicatorInfo[stepId].pageTitle;

export const getStepTitle = (stepId: SignUpStepIds) =>
  stepsIndicatorInfo[stepId].title;

export const ignoreIndicator = (stepId: SignUpStepIds) =>
  Boolean(stepsIndicatorInfo[stepId].ignoreIndicator);

export const totalNumberOfSteps = (steps: StepPathInfo<SignUpStepIds>[]) =>
  getTotalStepsForIndicator<SignUpStepIds>(steps, stepsIndicatorInfo);

export const getStepNumber = (
  steps: StepPathInfo<SignUpStepIds>[],
  currentPathname: string
) => getStepNumberForIndicator(steps, stepsIndicatorInfo, currentPathname);

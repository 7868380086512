import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export const useRedirectUserWithoutSubmittedAppealStatus = () => {
  const { countryCode } = useAppSelector(getLocalisation);
  const { currentUser } = useAppSelector(getUser);
  const history = useHistory();

  useEffect(() => {
    if (currentUser?.appealStatus !== AppealStatus.SUBMITTED) {
      history.push(`/${countryCode}/home`);
    }
  }, [currentUser, countryCode, history]);
};

import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { BusinessSize as InnerField } from '@common/components/BusinessForm/components/BusinessSize';
import { FieldWrapper } from '../FieldWrapper';

export default function BusinessSize() {
  const { businessSizeRules } = useBusinessRules();
  return (
    <FieldWrapper<string>
      name="businessSize"
      rules={businessSizeRules}
      render={(props) => <InnerField {...props} />}
    />
  );
}

import { TFunction } from 'i18next';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';

interface Option {
  value: WorkExperience;
  label: string;
}

/**
 * Maps the number of years of work experience to an option object.
 *
 * @param {number} numberOfYears - The number of years of work experience.
 * @param {number} count - The count of applications with the specified years of experience.
 * @param {TFunction} t - The translation function to localize the label.
 * @returns {Option | undefined} An option object containing the value and localized label, or undefined if the number of years does not match any criteria.
 */
export const mapYearsToOption = (
  numberOfYears: number,
  count: number,
  t: TFunction
): Option | undefined => {
  if (numberOfYears === 0) {
    return {
      value: WorkExperience.NO_EXPERIENCE,
      label: `${t(
        'applications.applicationsFilter.workExperience.noExperience'
      )} (${count})`
    };
  }

  if (numberOfYears === 1) {
    return {
      value: WorkExperience.ONE_YEAR_EXPERIENCE,
      label: `${t(
        'applications.applicationsFilter.workExperience.oneYearExperience'
      )} (${count})`
    };
  }

  if (numberOfYears >= 2 && numberOfYears <= 3) {
    return {
      value: WorkExperience.TWO_TO_THREE_YEARS_EXPERIENCE,
      label: `${t(
        'applications.applicationsFilter.workExperience.twoToThreeYearsExperience'
      )} (${count})`
    };
  }

  if (numberOfYears >= 4) {
    return {
      value: WorkExperience.MORE_THAN_FOUR_YEARS_EXPERIENCE,
      label: `${t(
        'applications.applicationsFilter.workExperience.moreThanFourYearsExperience'
      )} (${count})`
    };
  }
};

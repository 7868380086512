import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as colours from '../../theme/palette/colours';

type Props = {
  highlightColor?: string;
  baseColor?: string;
  width?: number;
  height?: number;
  marginRight?: number;
  margin?: number;
};

export const SkeletonLoader = ({
  baseColor = colours.grey200,
  highlightColor = colours.grey100,
  width = 150,
  height = 42,
  marginRight,
  margin,
  ...restProps
}: Props) => {
  const skeletonStyle = {
    container: {
      ...(marginRight ? { marginRight: `${marginRight}px` } : {}),
      ...(margin ? { margin: `${margin}px` } : {})
    }
  };

  return (
    <div style={skeletonStyle.container}>
      <Skeleton
        baseColor={baseColor}
        highlightColor={highlightColor}
        width={width}
        height={height}
        {...restProps}
      />
    </div>
  );
};

export enum CONSENT_CODES {
  TERMS_PRIVACY_JOB_PROGRESS = 'terms_privacy_job_progress',
  MARKETING_TIPS_ADVICE_UPDATES = 'marketing_tips_advice_updates',
  THAI_COOKIE = 'thai_cookie',
  THAI_AGE_VERIFICATION = 'thai_age_verification'
}

interface ConsentData {
  termsAndPrivacyConsent?: boolean;
  marketingConsent?: boolean;
  cookieConsent?: boolean;
  ageVerification?: boolean;
}
export function getConsentCodes(data: ConsentData): CONSENT_CODES[] {
  const {
    termsAndPrivacyConsent,
    marketingConsent,
    cookieConsent,
    ageVerification
  } = data;

  const consentCodes: CONSENT_CODES[] = [];

  if (termsAndPrivacyConsent) {
    consentCodes.push(CONSENT_CODES.TERMS_PRIVACY_JOB_PROGRESS);
  }

  if (marketingConsent) {
    consentCodes.push(CONSENT_CODES.MARKETING_TIPS_ADVICE_UPDATES);
  }

  if (cookieConsent) {
    consentCodes.push(CONSENT_CODES.THAI_COOKIE);
  }

  if (ageVerification) {
    consentCodes.push(CONSENT_CODES.THAI_AGE_VERIFICATION);
  }

  return consentCodes;
}

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Pricify from '@chargebee/atomicpricing';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { getPricifyConfig } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { useRedirectIfAccountUpgradableDisabled } from './hooks';

export const AccountUpgradeOnline = () => {
  const { t } = useTranslation();
  useRedirectIfAccountUpgradableDisabled();

  return (
    <div>
      <JoraEmployerHelmet title={t('accountUpgradePage.title')} />
      <PricingPage />
    </div>
  );
};

const PricingPage = () => {
  const config = useAppSelector(getPricifyConfig());
  const countryCode = useSiteCountryCode();
  const brandName = useBrandName();

  const { site, pricingPage, viewportDefaultHeight } =
    config[brandName][countryCode].online;

  useEffect(() => {
    Pricify.init();
  }, []);

  return (
    <div
      id="pricify-hosted-pricing-page"
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        width: '100vw',
        maxWidth: '100vw',
        marginLeft: 'calc(-50vw + 50%)'
      }}
      data-pricify-site={site}
      data-pricify-pricingpage={pricingPage}
      data-pricify-viewport-defaultheight={viewportDefaultHeight}
    />
  );
};

import type { T } from '@common/hooks/useTranslation';
import { SalaryWithOptionalAmount } from '../fields';
import {
  isExactSalaryInput,
  isRangeSalaryInput
} from '../fields/ExpectedSalary/hooks/useExpectedSalary';

export function expectedSalaryRules(t: T) {
  return {
    validate: (value: SalaryWithOptionalAmount | undefined) => {
      if (!value) {
        return;
      }

      if (isRangeSalaryInput(value)) {
        const { amount } = value;

        if (amount && amount.from >= amount.to) {
          return t(
            'postJobForm.expectedSalary.validationText.maxAmountGreaterThanMinAmount'
          );
        }

        if (amount && (amount.from === 0 || amount.to === 0)) {
          return t('postJobForm.expectedSalary.validationText.noAmount');
        }

        if (
          amount &&
          (amount.from || amount.to) &&
          !(amount.from && amount.to)
        ) {
          return t(
            'postJobForm.expectedSalary.validationText.maxAmountAndMinAmountFilled'
          );
        }
      }

      if (isExactSalaryInput(value) && value.amount === 0) {
        return t('postJobForm.expectedSalary.validationText.noAmount');
      }

      return;
    }
  };
}

import React from 'react';

type StepWrapperProps = {
  children: React.ReactNode;
};

export const StepWrapper = ({ children }: StepWrapperProps) => {
  return (
    <div className="p-[12px] bg-background-neutral mb-md">
      <div className="flex flex-col pt-md p-[24px]">{children}</div>
    </div>
  );
};

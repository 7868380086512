import REQUEST_KEY from '@common/constants/requestKeys';
import { ClientError } from '@common/helpers/errors';
import { useHandleAppLoading } from '@common/hooks/appLoading';
import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { extractTrpcErrorProperties } from '@src/trpc/lib';
import {
  ErrorResponse,
  QueryOptions,
  useFetchAdDuration
} from './useFetchAdDuration';

export const useQueryAdDuration = (
  countryCode: SupportedCountryCode,
  brand: BrandName,
  queryOptions?: QueryOptions
) => {
  const params = {
    countryCode: countryCode.toLowerCase() as SupportedCountryCode,
    brand
  };

  const { data, error, isLoading } = useFetchAdDuration(params, queryOptions);

  useHandleAppLoading(isLoading, REQUEST_KEY.JOB.GET_AD_DURATION);

  return {
    data,
    error: getClientError(extractTrpcErrorProperties<ErrorResponse>(error))
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};

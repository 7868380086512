import React from 'react';
import { MarketingConsent as InnerField } from '@common/components/Account/components/MarketingConsent';
import { FieldWrapper } from '../FieldWrapper';

export function MarketingConsent() {
  return (
    <FieldWrapper<boolean>
      name="marketingConsent"
      render={(props) => <InnerField {...props} />}
    />
  );
}

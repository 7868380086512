import { useHistory } from 'react-router-dom';
import { userHasBadAccountStatus } from '@common/helpers/auth';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useCurrentUser, useHasAuthentication } from '@common/hooks/user';
import { logout } from '@store/auth';
import { getEnvironment, getSiteId } from '@store/config';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { isCajaRoute } from './isCajaRoute';

export const useJoraEmployerHeader = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isAuthenticated = useHasAuthentication();
  const currentUser = useCurrentUser();
  const countryCode = useSiteCountryCode();
  const environment = useAppSelector(getEnvironment());
  const siteId = useAppSelector(getSiteId());
  const badAccountStatus = userHasBadAccountStatus(currentUser);

  const currentPathname = history.location?.pathname;
  const isCajaPage = isCajaRoute(currentPathname);
  const isNotHomeRoute = currentPathname !== `/${countryCode}/`;
  const isAccountUpgradeOfflineRoute =
    currentPathname === `/${countryCode}/account-upgrade-offline`;

  return {
    isAuthenticated,
    badAccountStatus,
    isAccountUpgradeOfflineRoute,
    siteId,
    isDevelopment: environment === 'development',
    showBackButton:
      !badAccountStatus &&
      isNotHomeRoute &&
      !isAccountUpgradeOfflineRoute &&
      !isCajaPage,
    goBack: () => history.goBack(),
    goToLogin: () => history.push(`/${countryCode}/login`),
    logOut: () => dispatch(logout({ initiatedByUser: true })),
    goToHome: () => history.push(`/${countryCode}/`)
  };
};

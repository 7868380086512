import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { PricingPanel } from '@common/components/PricingPanel';
import { UpgradeableAdType } from '@src/job';
import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type CoinPaymentModalProps = {
  visible: boolean;
  onClose: () => void;
  onPaymentPress: () => void;
  selectedAdType?: UpgradeableAdType;
  errorMessage?: string;
  coinPrice?: number;
};

export const CoinPaymentModal = (props: CoinPaymentModalProps) => {
  const { t } = useTranslation();
  const {
    visible,
    onClose,
    selectedAdType,
    coinPrice = 0,
    onPaymentPress,
    errorMessage
  } = props;

  return (
    <Modal
      title={t('coinPaymentModal.title')}
      height="fit-content"
      visible={visible}
      onRequestClose={onClose}
      testID="coin-payment-modal"
    >
      <Stack spacing="medium">
        <Text weight="regular">
          {t('coinPaymentModal.description', {
            adType: t(`upgradeJobForm.${selectedAdType}.name`)
          })}
        </Text>
        <PricingPanel adType={selectedAdType!} coinPrice={coinPrice} />
        <Button onPress={onPaymentPress}>
          {t('coinPaymentModal.submitAction', { amount: coinPrice })}
        </Button>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Stack>
    </Modal>
  );
};

import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';
import { FILTERS } from '../../../types';

WorkExperience;
type Filter = {
  [FILTERS.WORK_EXPERIENCE]?: WorkExperience[];
};

type Item = {
  yearsOfExperienceInJobRole: number;
};

export const filterWorkExperience = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValues = filter[FILTERS.WORK_EXPERIENCE];

  if (!filterValues || filterValues.length === 0) {
    return items;
  }

  return items.filter((item) => {
    if (item.yearsOfExperienceInJobRole >= 4) {
      return filterValues.includes(
        WorkExperience.MORE_THAN_FOUR_YEARS_EXPERIENCE
      );
    }

    if (
      item.yearsOfExperienceInJobRole >= 2 &&
      item.yearsOfExperienceInJobRole <= 3
    ) {
      return filterValues.includes(
        WorkExperience.TWO_TO_THREE_YEARS_EXPERIENCE
      );
    }

    if (item.yearsOfExperienceInJobRole === 1) {
      return filterValues.includes(WorkExperience.ONE_YEAR_EXPERIENCE);
    }

    if (item.yearsOfExperienceInJobRole === 0) {
      return filterValues.includes(WorkExperience.NO_EXPERIENCE);
    }

    return false;
  });
};

import React from 'react';
import { RadioCard } from '@common/components/FormElements/RadioCard';
import { AD_TYPE } from '@seek/je-shared-data';
import { BasicAdInfo } from '../AdInfo';

type BasicAdCardProps = {
  adDuration?: number;
  onChange: (adType: AD_TYPE) => void;
  value: AD_TYPE;
  disabled: boolean;
};

export const BasicAdCard = (props: BasicAdCardProps) => {
  const { adDuration, onChange, value, disabled } = props;

  const isValueBasicAd = () => value === AD_TYPE.BASIC;

  return (
    <RadioCard
      testID="basic-radio-card"
      disabled={disabled}
      checked={isValueBasicAd()}
      onPress={() => onChange(AD_TYPE.BASIC)}
      header={<BasicAdInfo adDuration={adDuration} />}
    />
  );
};

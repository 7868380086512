import { useLocation } from 'react-router-dom';

export function useGetJobIdParam() {
  const location = useLocation();

  const regex =
    /\/sign-up\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-\d+)\/.*/;

  const match = location.pathname.match(regex);

  if (match) return match[1];
}

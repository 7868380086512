import { ApplicationStatus } from '@common/types';
import { APPLICANT_STATUS } from '@seek/je-shared-data';
import { FILTERS } from '../../../../../../types';
import { useFilterFormContext } from '../../../../FilterFormContext';

export type useApplicantStatusTilesProps = {
  onChange: (selectedValues: ApplicationStatus[]) => void;
  onClear: () => void;
  value?: ApplicationStatus[];
};

export const useApplicantStatusTiles = ({
  onChange,
  onClear,
  value = []
}: useApplicantStatusTilesProps) => {
  const colourVariants = {
    green: 'bg-green-50 border-green-700 text-green-700',
    red: 'bg-red-50 border-red-600 text-red-600',
    redWithoutBorder: 'bg-red-50 text-red-600',
    default: 'bg-white text-gray-700'
  };

  const { applicationFilters } = useFilterFormContext();

  const getStatusTileStyle = (statusValue: ApplicationStatus) => {
    const isStatusSelected =
      applicationFilters[FILTERS.APPLICANT_STATUS]?.includes(statusValue);
    if (statusValue === APPLICANT_STATUS.NO_STATUS) {
      return isStatusSelected
        ? colourVariants.red
        : colourVariants.redWithoutBorder;
    }
    return isStatusSelected ? colourVariants.green : colourVariants.default;
  };

  const handleStatusTileClick = (statusValue: ApplicationStatus) => {
    const newValue = value.includes(statusValue)
      ? value.filter((item) => item !== statusValue)
      : [...value, statusValue];
    newValue.length ? onChange(newValue) : onClear();
  };

  return {
    colourVariants,
    getStatusTileStyle,
    handleStatusTileClick
  };
};

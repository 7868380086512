import { useFindOrCreateDraftForRepost } from '@common/hooks/job/useFindOrCreateDraftForRepost';

export function useLoadRecentDraft(
  jobId: string,
  { isNew }: { isNew: boolean }
) {
  const {
    data: savedDraft,
    isLoading: draftLoading,
    error: findRecentDraftError
  } = useFindOrCreateDraftForRepost({
    repostJobId: jobId,
    forceNewDraft: isNew
  });

  return {
    data: savedDraft,
    isLoading: draftLoading,
    error: findRecentDraftError
  };
}

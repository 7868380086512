import React from 'react';
import { useTranslation } from 'react-i18next';

type ClearLinkProps = {
  onClear: () => void;
  active: boolean;
};

export const ClearLink = ({ active = false, onClear }: ClearLinkProps) => {
  const { t } = useTranslation();

  return (
    <a
      role="button"
      className={`link link-hover
        text-sm font-light
          ${
            active
              ? ' text-blue-500'
              : 'text-gray-300 no-underline hover:no-underline'
          }`}
      onClick={onClear}
    >
      {t('applications.applicationsFilter.clear')}
    </a>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpgradeAccountBanner } from '@common/components/Account/components';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { PageHeadingWithUserPackage } from '@common/components/PageHeadingWithUserPackage';
import { useAccountUpgradeable } from '@common/hooks/featureToggles/useAccountUpgradeable';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useGetUserPackage } from '@common/hooks/userPackages';
import { Box } from '@components/Box';
import { Stack } from '@components/Stack';
import { CoinFaqSection, CoinSummary, CoinTransactions } from './components';
import { useRedirectIfCoinsDisabled } from './hooks';

export const MyCoins = () => {
  const { t } = useTranslation();
  const accountUpgradeable = useAccountUpgradeable();
  const { packageType } = useGetUserPackage();
  const pageTitle = t('myCoinsPage.title');
  const freeAndUpgradeableAccount =
    accountUpgradeable && packageType === 'free';

  useRedirectUnauthenticatedUser();
  useRedirectIfCoinsDisabled();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('myCoinsPage.title')} />

      <Stack spacing="small" tone="transparent" marginBottom="large">
        <Box paddingTop="medium" paddingHorizontal="medium" tone="transparent">
          <PageHeadingWithUserPackage heading={t('myCoinsPage.heading')} />
        </Box>

        <CoinSummary />

        {freeAndUpgradeableAccount && (
          <UpgradeAccountBanner
            pageTitle={pageTitle}
            source="mycoins_page_banner"
          />
        )}

        <CoinTransactions />

        <CoinFaqSection showExpiryInformation={true} />
      </Stack>
    </Stack>
  );
};

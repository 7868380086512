import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { type RightToWork } from '@common/constants/data';
import { Application } from '@common/types';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';

type RightToWorkFilterProps = {
  applications: Application[];
  onChange: (selectedValues: RightToWork[]) => void;
  onClear: () => void;
  value?: RightToWork[];
};

export const RightToWorkFilter = ({
  applications = [],
  value = [],
  onChange,
  onClear
}: RightToWorkFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: RightToWork) => {
    const newValue = value.includes(checkboxValue)
      ? value.filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];

    newValue.length ? onChange(newValue) : onClear();
  };

  return (
    <div className="space-y-md">
      <div className="flex flex-row justify-between">
        <div className="text-md">
          {t('applications.applicationsFilter.rightToWork.title')}
        </div>
        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(({ key, label, value: optionValue }) => (
          <div key={key} className="w-full md:w-1/2 mb-md">
            <Checkbox
              value={value.includes(optionValue)}
              label={label}
              onChange={() => handleCheckbox(optionValue)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const options = (applications: Application[], t: TFunction) => {
  const counts = applications.reduce(
    (acc, { candiRightToWork: rightToWork }) => {
      acc[rightToWork ? 'eligible' : 'sponsorship']++;
      return acc;
    },
    { eligible: 0, sponsorship: 0 }
  );

  return Object.entries(counts)
    .filter(([, count]) => count > 0)
    .map(([value, count]) => ({
      key: value,
      label: `${t(
        `applications.applicationsFilter.rightToWork.options.${value}`
      )} (${count})`,
      value: value as RightToWork
    }));
};

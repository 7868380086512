// THIS IS GENERIC ENOUGH TO MOVE ELSEWHERE

/**
 * Returns a boolean value indicating whether the first field in the error context is present in the error map.
 * @returns boolean.
 */
export function useFormFieldError(errorMap: Record<string, string>) {
  const isFieldError = ({
    context
  }: {
    context?: Record<string, string>;
  }): boolean => {
    if (context) {
      const field = Object.keys(context)[0];
      return Object.keys(errorMap).includes(field);
    }

    return false;
  };

  const setFieldLevelError = ({ error, setError, t }) => {
    const fieldName = Object.keys(error.context)[0];

    setError(fieldName, {
      type: 'server',
      message: t(`common.errors.${errorMap[fieldName]}`)
    });
  };

  return {
    isFieldError,
    setFieldLevelError
  };
}

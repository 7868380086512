import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import REQUEST_KEY from '../../constants/requestKeys';
import ConnectedErrorMessage from '../ConnectedErrorMessage/ConnectedErrorMessage';
import Form from '../ConnectedForm/ConnectedForm';
import PasswordField from '../PasswordField/PasswordField';
import Submit from '../Submit/Submit';
import { validateResetPasswordForm } from './helpers/validation';

@connect()
@reduxForm({
  form: 'reset-password-form',
  validate: validateResetPasswordForm
})
@withTranslation()
export default class ResetPasswordForm extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    submitResetPassword: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const { showPassword } = this.state;
    const { submitResetPassword, handleSubmit, valid, submitFailed, t } =
      this.props;
    return (
      <Card>
        <CardContent>
          <Form
            requestKey={REQUEST_KEY.AUTH.RESET_PASSWORD}
            reduxFormSubmit={handleSubmit}
            onSubmit={submitResetPassword}
            method="POST"
            noValidate
            valid={valid}
            submitFailed={submitFailed}
            insideCard
            errorSummary={t('validations.general.summary')}
          >
            <Field
              component={PasswordField}
              t={t}
              firstField
              name="newPassword"
              label={t('common.fieldLabel.newPassword')}
              showPassword={showPassword}
              togglePassword={this.togglePassword}
              validationMessages={t('validations.general.password', {
                returnObjects: true
              })}
            />

            <Stack marginTop="xxsmall">
              <Text size="small" tone="neutralLight">
                {t('common.explanationText.password')}
              </Text>
            </Stack>

            <Field
              component={PasswordField}
              t={t}
              name="confirmPassword"
              extraTopMargin
              label={t('common.fieldLabel.confirmNewPassword')}
              showPassword={showPassword}
              validationMessages={t('validations.general.password', {
                returnObjects: true
              })}
            />
            <Submit insideCard>
              {t('resetPasswordForm.action.resetPassword')}
            </Submit>
          </Form>
          <ConnectedErrorMessage
            hideWithSubmissionError
            requestKey={REQUEST_KEY.AUTH.RESET_PASSWORD}
            extraTopMargin
          />
        </CardContent>
      </Card>
    );
  }
}

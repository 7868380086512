import { FILTERS } from '../../../types';

type Filter = {
  [FILTERS.CITIZENSHIP]?: string[];
};

type Item = {
  citizenshipCountryCode?: string;
};

export const filterCitizenship = <T extends Item>(
  filter: Filter,
  items: T[]
): T[] => {
  const filterValue = filter[FILTERS.CITIZENSHIP];

  if (!filterValue) return items;

  return items.filter(
    (item) =>
      item.citizenshipCountryCode &&
      filterValue.includes(item.citizenshipCountryCode)
  );
};

import React, { useState } from 'react';
import {
  StepError,
  StepLoader,
  StepWrapper
} from '@common/components/Caja/components';
import {
  EnhanceJob as EnhanceJobForm,
  OnSubmitValues
} from '@common/components/Job/EnhanceJob/EnhanceJobWithCoins';
import { CoinPaymentModal } from '@common/components/Payment/CoinPaymentModal';
import { usePatchPremiumFields } from '@common/hooks/job/usePatchJob';
import { TopLevelStep } from '@common/hooks/multiStep';
import {
  useGetCoinPrice,
  useHasSufficientCoins,
  useOnCoinPaymentSubmit
} from '@common/hooks/upgradeJobAd';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { basicOrUndefined, upgraded } from '@src/job';
import { Heading } from '@components/Heading';
import { useModal } from '@components/Modal';
import { useMapJobToFormData } from './hooks';
import { toJob as formToJob } from './transformers/form/toJob';

type Props = Pick<
  TopLevelStep<{
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    initialAdType?: AD_TYPE;
    onNextText?: string;
    hideBackButton?: boolean;
    hideBasicAdType?: boolean;
    disableAdTypeSelector?: boolean;
  }>,
  'onCompleted' | 'onBack' | 'stepInfo'
>;

export function EnhanceJob(props: Props) {
  useRedirectUnauthenticatedUser();

  const {
    stepInfo: {
      jobId,
      jobCountryCode,
      initialAdType,
      onNextText,
      hideBackButton,
      hideBasicAdType,
      disableAdTypeSelector = false
    },
    onBack,
    onCompleted
  } = props;

  const { t } = useTranslation();
  const { formData, isLoading: jobLoading } = useMapJobToFormData(jobId);
  const { patchJobData, error: patchJobError } = usePatchPremiumFields();

  const [jobAdType, setJobAdType] = useState<AD_TYPE>(
    basicOrUndefined(initialAdType) ? AD_TYPE.PLUS : initialAdType!
  );

  const onNext = ({ hasJobUpgraded }: { hasJobUpgraded?: boolean } = {}) => {
    return onCompleted({
      jobAdType,
      hasJobUpgraded: Boolean(hasJobUpgraded)
    });
  };
  const { pricingText } = useGetCoinPrice({
    jobCountryCode
  });
  const hasSufficientCoins = useHasSufficientCoins(pricingText!);
  const { openModal, closeModal, isModalOpen } = useModal();
  const { onCoinPaymentPress } = useOnCoinPaymentSubmit({
    jobId,
    countryCode: jobCountryCode,
    onSuccessfulPayment: () => {
      closeModal();
      onNext({ hasJobUpgraded: true });
    }
  });

  const onEnhanceJobSubmit = async (data?: OnSubmitValues) => {
    if (upgraded(data?.adType)) await patchJobData(formToJob(jobId, data!!));
  };

  const onEnhanceJobNext = (data?: OnSubmitValues) => {
    if (!data?.adType) return onNext();
    if (disableAdTypeSelector) return onNext();

    const newAdType = data.adType;
    if (hasSufficientCoins[newAdType]) return openModal();

    return onNext();
  };

  const onChangeAdType = (newAdType: AD_TYPE) => {
    if (disableAdTypeSelector) return;

    setJobAdType(newAdType);
  };

  if (jobLoading) return <StepLoader />;

  return (
    <StepWrapper>
      <div className="pb-[12px]">
        <Heading level="h2" weight="regular">
          {t('enhanceJobStep.contentHeading')}
        </Heading>
      </div>

      <EnhanceJobForm
        defaultValues={formData}
        jobCountryCode={jobCountryCode}
        onBack={onBack}
        onNext={onEnhanceJobNext}
        onSubmit={onEnhanceJobSubmit}
        onChangeAdType={onChangeAdType}
        selectedAdType={jobAdType}
        disableSelector={disableAdTypeSelector}
        onNextText={onNextText}
        hideBackButton={hideBackButton}
        hideBasicAdType={hideBasicAdType}
      />

      {upgraded(jobAdType) && pricingText && (
        <CoinPaymentModal
          visible={isModalOpen}
          selectedAdType={jobAdType}
          onClose={closeModal}
          coinPrice={pricingText[jobAdType]}
          onPaymentPress={() => onCoinPaymentPress(jobAdType)}
        />
      )}

      <StepError error={patchJobError} />
    </StepWrapper>
  );
}

import { REGEX } from '@common/constants/data';
import type { T } from '@common/hooks/useTranslation';

export const password = (t: T) => {
  const errors = {
    required: t('validations.general.password.required'),
    pattern: t('validations.general.password.format')
  };

  return {
    required: errors.required,
    pattern: {
      value: REGEX.PASSWORD,
      message: errors.pattern
    }
  };
};

import { useEffect, useState } from 'react';
import { ApplicationStatus } from '@common/types';
import { FILTERS } from '../../../../types';
import { useFilterFormContext } from '../../FilterFormContext';
import { useTrackFiltersApplied, useTrackFiltersCleared } from '../../hooks';

export const useApplicantStatusPanel = () => {
  const {
    filterForm,
    handleFieldChange,
    handleFieldClear,
    applySelectedFilters
  } = useFilterFormContext();
  const [statusClick, setStatusClick] = useState<boolean | undefined>(
    undefined
  );

  const trackFiltersApplied = useTrackFiltersApplied({ filters: filterForm });
  const trackFiltersCleared = useTrackFiltersCleared();

  useEffect(() => {
    if (statusClick !== undefined) {
      applySelectedFilters();
      statusClick ? trackFiltersApplied() : trackFiltersCleared();
      setStatusClick(undefined);
    }
  }, [
    statusClick,
    applySelectedFilters,
    trackFiltersApplied,
    trackFiltersCleared
  ]);

  const toggleStatusClick = () => setStatusClick((prevState) => !prevState);

  const handleStatusChange = (selectedValues: ApplicationStatus[]) => {
    handleFieldChange(FILTERS.APPLICANT_STATUS)(selectedValues);
    toggleStatusClick();
  };

  const handleStatusClear = () => {
    handleFieldClear(FILTERS.APPLICANT_STATUS)();
    toggleStatusClick();
  };

  return {
    handleStatusChange,
    handleStatusClear,
    filterForm
  };
};

import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { RecruitmentCompanyName as InnerField } from '@common/components/BusinessForm/components/RecruitmentCompanyName';
import { FieldWrapper } from '../FieldWrapper';

export default function RecruitmentCompanyName() {
  const { recruitmentCompanyNameRules } = useBusinessRules();
  return (
    <FieldWrapper<string>
      name="recruitmentCompanyName"
      rules={recruitmentCompanyNameRules}
      render={(props) => <InnerField {...props} />}
    />
  );
}

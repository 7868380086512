import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { BaseTheme } from '@theme/DesignSystemProvider/ThemeContext/makeBaseTheme';
import { Sizes } from '@theme/tokens';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type UseStyleProviderStyleProps = {
  fontSize?: keyof BaseTheme['typography']['text'];
  isFocused?: boolean;
  height?: Sizes;
  width?: Sizes;
  tone?: 'neutral' | 'danger';
};

export function useStyleProviderStyle({
  fontSize = 'standard',
  isFocused = false,
  height,
  width,
  tone = 'neutral'
}: UseStyleProviderStyleProps) {
  const { border, formElement, sizes, typography } = useTheme();

  const { resolveToken } = useResolveToken();

  const { borderColor: defaultBorderColor, focusedBorderColor } =
    resolveToken(formElement.color, tone) || {};
  const borderRadius = resolveToken(formElement.container, 'borderRadius');
  const borderWidth = resolveToken(formElement.container, 'borderWidth');

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(typography.text, fontSize) || {};

  const fontFamily = `${quoteFontName(typography.fontFamily)}, ${quoteFontName(
    typography.fontFamilyWebFallback
  )}`;

  // todo: Change to get fontColor from "formElement" for all platforms after converting CheckoutForm to use DS based components.
  const fontColor = resolveToken(formElement.color, tone)?.fontColor;

  const secondaryFontColor = resolveToken(
    formElement.color,
    'neutralMuted'
  )?.fontColor;

  const letterSpacing = resolveToken(
    typography.letterSpacing,
    textLetterSpacing
  );

  return {
    borderColor: isFocused ? focusedBorderColor : defaultBorderColor,
    borderRadius: resolveToken(border.radius, borderRadius),
    borderWidth: resolveToken(border.width, borderWidth),
    color: fontColor,
    secondaryColor: secondaryFontColor,
    fontFamily,
    fontCssSource: typography.fontCssSrc,
    fontWeight: resolveToken(typography.fontWeights, 'light'),
    fontSize: resolveToken(typography.fontSizes, textSize),
    lineHeight: resolveToken(typography.lineHeights, textLineHeight),
    letterSpacing,
    height: resolveToken(sizes, height),
    width: resolveToken(sizes, width)
  };
}

function quoteFontName(fontName: string) {
  return fontName.includes(' ') ? `"${fontName}"` : fontName;
}

// CAJA links
export { useGetSignUpLink } from './useGetSignUpLink';
export { useGetEnhanceJobLink } from './useGetEnhanceJobLink';

// Job page links
export { useGetRepostJobLink } from './useGetRepostJobLink';
export { useGetPostJobLink } from './useGetPostJobLink';
export { useGetEditJobLink } from './useGetEditJobLink';
export { useGetJobNotFoundLink } from './useGetJobNotFoundLink';
export { useGetJobPostedSuccessLink } from './useGetJobPostedSuccessLink';
export { useGetJobEditedSuccessLink } from './useGetJobEditedSuccessLink';

// Business page links
export { useGetCreateBusinessLink } from './useGetCreateBusinessLink';
export { useGetEditBusinessLink } from './useGetEditBusinessLink';

// Application page links
export { useGetApplicationDetailsLink } from './useGetApplicationDetailsLink';

// Account page links
export { useGetAccountLink } from './useGetAccountLink';
export { useGetForgotPasswordLink } from './useGetForgotPasswordLink';

// Home page links
export { useGetHomeLink } from './useGetHomeLink';

// Payment page links
export { useGetUpgradeJobLink } from './useGetUpgradeJobLink';
export { useGetPaymentLink } from './useGetPaymentLink';

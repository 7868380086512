import React from 'react';
import { PasswordConfirmation } from '@common/components/Account/components/PasswordConfirmation';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { FieldWrapper } from '../FieldWrapper';

export const ConfirmPassword = () => {
  const { passwordConfirmationRules } = useAccountRules();
  return (
    <FieldWrapper<string>
      name="confirmPassword"
      rules={passwordConfirmationRules}
      render={(props) => {
        return <PasswordConfirmation {...props} />;
      }}
    />
  );
};

import requestKeys from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { useAppSelector } from '@store/hooks';

export const useGetRequestErrors = () => {
  const { error: paymentIntentError } = useAppSelector(
    getRequestState(requestKeys.PAYMENT.CREATE_PAYMENT_INTENT)
  );

  return {
    hasError: Boolean(paymentIntentError),
    requestKeys: [requestKeys.PAYMENT.CREATE_PAYMENT_INTENT]
  };
};

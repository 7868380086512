import { createContext } from 'react';
import type { Control, FieldErrorsImpl } from 'react-hook-form';
import { CompleteVerifyFormData } from './types';

interface FormContextProps {
  control: Control<CompleteVerifyFormData>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

export const FormContext = createContext<FormContextProps>({
  control: {} as Control<CompleteVerifyFormData>,
  errors: {}
});

import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans as I18nextTrans } from 'react-i18next';
import { useResponseErrorHandler } from '@common/components/Account/forms/verifyEmail/hooks';
import { useTranslation } from '@common/hooks/useTranslation';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { VerifyEmailIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { FormContext } from './FormContext';
import { EmailVerificationCode } from './topLevelFields';
import type { CompleteVerifyFormData, ResponseErrors } from './types';

type FormProps = {
  userEmail: string;
  onResend: () => void;
  onSubmit: (data: CompleteVerifyFormData) => Promise<void | ResponseErrors>;
  submitBtnText: string;
};

export default function VerifyEmailForm({
  userEmail,
  onResend,
  onSubmit,
  submitBtnText
}: FormProps) {
  const { t } = useTranslation();
  const { handleResponseErrors } = useResponseErrorHandler();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<CompleteVerifyFormData>({
    mode: 'onBlur'
  });

  const submit = async (data: CompleteVerifyFormData) => {
    const errorResponse = await onSubmit(data);

    handleResponseErrors(errorResponse, setError);
  };

  return (
    <FormContext.Provider value={{ control, errors }}>
      <Stack alignItems="center" spacing="medium" marginBottom={'small'}>
        <VerifyEmailIllustration size="large" />
        <Heading level="h1" weight="regular" align="center">
          {t('verifyEmailPage.description.confirmEmail')}
        </Heading>
        <Text align="center">
          <I18nextTrans
            extraTopMargin="small"
            parent={Text}
            i18nKey="verifyEmailPage.description.emailSent"
            values={{ email: userEmail }}
            components={[<Text weight="regular" />]}
          />
        </Text>
      </Stack>
      <Stack spacing="large">
        <EmailVerificationCode />
      </Stack>
      <Box marginTop="medium" marginBottom="medium">
        <Text>
          {t('verifyEmailForm.resendQuestion')}{' '}
          <TextLink
            variant="primary"
            onPress={onResend}
            testID="resend-email-action"
          >
            {t('verifyEmailForm.action.emailResend')}
          </TextLink>
        </Text>
      </Box>

      <Button
        fullWidth
        onPress={handleSubmit(submit)}
        testID="verify-email-action"
      >
        {submitBtnText}
      </Button>
    </FormContext.Provider>
  );
}

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import Div from '../../../../common/components/Div/Div';
import ForgotPasswordForm from '../../../components/ForgotPasswordForm/ForgotPasswordForm';
import { RedirectAuthenticatedUser } from '../../../components/RedirectAuthenticatedUser';

@connect((state) => ({
  localisation: state.localisation
}))
@withTranslation()
export default class ForgotPassword extends Component {
  static propTypes = {
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired
    }),
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      t,
      localisation: { countryCode }
    } = this.props;

    return (
      <Div>
        <RedirectAuthenticatedUser
          to={`/${countryCode}/account/update-password`}
        />
        <JoraEmployerHelmet title={t('forgotPasswordPage.title')} />
        <Box
          paddingHorizontal="medium"
          marginTop="medium"
          marginBottom="medium"
          tone="transparent"
        >
          <Heading level="h1">{t('forgotPasswordPage.heading')}</Heading>
        </Box>

        <ForgotPasswordForm />
      </Div>
    );
  }
}

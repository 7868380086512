import { Component, ReactElement, createElement } from 'react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

type FunctionalComponent<P> = (props: P) => ReactElement<any, any> | null;
type ComponentClass<P> = new (props: P) => Component<P, any>;
type PageComponent = FunctionalComponent<any> | ComponentClass<any>;

type ChildRoute = {
  path: string;
  component: PageComponent;
  status?: number;
  isListView?: boolean;
  exact?: boolean;
};

type Props = {
  routes: {
    path: string;
    component: PageComponent;
    childRoutes: ChildRoute[];
  };
  getStatusCode?: (statusCode: number) => void;
};

export const RouteMap = (props: Props) => {
  const { routes, getStatusCode } = props;
  const { childRoutes, path: pathPrefix, component: shellComponent } = routes;

  const children = (
    <Switch>
      {childRoutes.map((route, index) => {
        const { component, path, status, exact, ...restCustomRouteProps } =
          route;

        return (
          <Route
            exact={exact ?? true}
            path={pathPrefix + path}
            key={index}
            render={(routeProps) => {
              const elementProps = {
                route,
                ...routeProps,
                ...restCustomRouteProps
              };

              if (getStatusCode && status) {
                getStatusCode(status);
              }

              return createElement(component, elementProps);
            }}
          />
        );
      })}
    </Switch>
  );

  return createElement(shellComponent, { routes, children });
};

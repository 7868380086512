import {
  INDUSTRY_OTHER_DESCRIPTION_MIN_LENGTH,
  MIN_LENGTH
} from '@common/constants/validation';
import type { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the industry description field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function industryDescriptionRules(t: T) {
  return {
    required: t('validations.business.otherIndustry.required'),
    [MIN_LENGTH]: {
      value: INDUSTRY_OTHER_DESCRIPTION_MIN_LENGTH,
      message: t('validations.business.otherIndustry.minLength')
    }
  };
}

import { useTrackUpgradeAccountButtonClicked } from '@src/joraEmployer/pages/account/hooks';

export const useHandleUpgradeLinkClick = (toggleModalState: () => void) => {
  const onUpgradeAccountClicked = useTrackUpgradeAccountButtonClicked({
    source: 'filters_modal'
  });

  return () => {
    onUpgradeAccountClicked();
    toggleModalState();
  };
};

export async function ensureUiVersion(): Promise<string> {
  const getVersion = (): string | null => localStorage?.getItem('__ui_version');

  let version = getVersion();
  if (version) return version;

  for (let i = 0; i < 2; i++) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    version = getVersion();
    if (version) {
      return version;
    }
  }

  return 'NO_VERSION';
}

import React from 'react';
import { TextInputProps } from 'react-native';
import { useTranslation } from '@common/hooks/useTranslation';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { CurrencyInput, Field } from '@components/FormElements';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { useRangeAmount, useRangeAmountProps } from './hooks/useRangeAmount';

type RangeAmountProps = useRangeAmountProps & {
  currencySymbol?: string;
  onBlur?: TextInputProps['onBlur'];
};

export const RangeAmount = (props: RangeAmountProps) => {
  const { t } = useTranslation();
  const { onChange, value } = props;
  const { onMinAmountChange, onMaxAmountChange } = useRangeAmount({
    value,
    onChange
  });

  return (
    <Field
      label={
        <Box paddingBottom="xsmall">
          <Text size="small">
            {t('postJobForm.expectedSalary.label.salaryAmount')}
          </Text>
        </Box>
      }
      hasMaxWidth
      testID="job-form-salary-range-amount"
    >
      <Row
        alignItems="center"
        spacing="none"
        maxWidth="full"
        justifyContent="space-between"
      >
        <Column width="3/7">
          <CurrencyInput
            currencySymbol={props.currencySymbol}
            value={value?.from?.toString()}
            onChange={(maskAmount, unmaskedAmount) => {
              onMinAmountChange(unmaskedAmount);
            }}
            onBlur={props.onBlur}
            placeholder={t('postJobForm.expectedSalary.placeholder.min', {
              currencySymbol: props.currencySymbol
            })}
            testID="job-form-salaryMinAmount"
          />
        </Column>

        <Column alignItems="center" width="1/7">
          <Text>{t('postJobForm.expectedSalary.label.to')}</Text>
        </Column>

        <Column width="3/7">
          <CurrencyInput
            currencySymbol={props.currencySymbol}
            value={value?.to?.toString()}
            onChange={(maskAmount, unmaskedAmount) => {
              onMaxAmountChange(unmaskedAmount);
            }}
            onBlur={props.onBlur}
            placeholder={t('postJobForm.expectedSalary.placeholder.max', {
              currencySymbol: props.currencySymbol
            })}
            testID="job-form-salaryMaxAmount"
          />
        </Column>
      </Row>
    </Field>
  );
};

import { openExternalURL } from '@common/helpers/externalConnection';
import { useAppDispatch } from '@store/hooks';
import { getEmailPreferenceCenterUrl } from '@store/unsubscribe/brazeApi';
import { useTrackManageMarketingPreferencesButtonClicked } from './useTrackManageMarketingPreferencesButtonClicked';

export function useGetUnsubscribeUrl(userId: string) {
  const dispatch = useAppDispatch();
  const trackMarketingPreferencesButtonClicked =
    useTrackManageMarketingPreferencesButtonClicked();

  const getUnsubscribeUrl = async () => {
    const { result } = await dispatch(getEmailPreferenceCenterUrl(userId));
    if (!result) throw new Error('Failed to get unsubscribe URL');

    trackMarketingPreferencesButtonClicked();
    openExternalURL(result);
  };

  return getUnsubscribeUrl;
}

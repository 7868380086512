import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { Item } from '@components/FormElements/Dropdown/DropdownLegacy/DropdownLegacy';

const rateOptions = (t: TFunction): Item<string>[] => {
  return [
    {
      label: t('postJobForm.expectedSalary.salaryRate.hourly'),
      value: SalaryRate.HOURLY,
      testID: 'rate-hourly'
    },
    {
      label: t('postJobForm.expectedSalary.salaryRate.daily'),
      value: SalaryRate.DAILY,
      testID: 'rate-daily'
    },
    {
      label: t('postJobForm.expectedSalary.salaryRate.weekly'),
      value: SalaryRate.WEEKLY,
      testID: 'rate-weekly'
    },
    {
      label: t('postJobForm.expectedSalary.salaryRate.monthly'),
      value: SalaryRate.MONTHLY,
      testID: 'rate-monthly'
    },
    {
      label: t('postJobForm.expectedSalary.salaryRate.yearly'),
      value: SalaryRate.YEARLY,
      testID: 'rate-yearly'
    }
  ];
};

export const useRateSelector = () => {
  const { t } = useTranslation();

  return { rateOptions: rateOptions(t) };
};

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { BUSINESS_ROLE_OPTIONS } from '@common/constants/data';
import REQUEST_KEY from '@common/constants/requestKeys';
import * as businessActions from '@store/entities/businesses/actions';
import * as jobActions from '@store/entities/jobs/actions';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import Div from '../../../../common/components/Div/Div';
import CreateBusinessForm from '../../../components/CreateBusinessForm/CreateBusinessForm';

@withTranslation()
@connect(
  ({ draft, localisation }) => ({
    initialValues: {
      country: localisation.countryCode,
      ...(draft.business || {})
    },
    countryCode: localisation.countryCode
  }),
  {
    createBusiness: businessActions.createBusiness,
    postJob: jobActions.postJob,
    pushRoute: push
  }
)
export default class CreateBusiness extends PureComponent {
  static propTypes = {
    createBusiness: PropTypes.func.isRequired,
    countryCode: PropTypes.string.isRequired,
    pushRoute: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  async submit(data) {
    const { createBusiness, countryCode, pushRoute } = this.props;
    const business = (await createBusiness(data)).result;

    if (business) {
      pushRoute({ pathname: `/${countryCode}/businesses` });
    }
  }

  render() {
    const { t, initialValues } = this.props;

    const submitButtonLabel = t('createBusinessForm.action.addNewBusiness');
    const title = t('createBusinessPage.title.addNewBusiness');
    const heading = t('createBusinessPage.heading.addNewBusiness');

    return (
      <Div>
        <JoraEmployerHelmet title={title} />
        <Box
          paddingHorizontal="medium"
          marginTop="medium"
          marginBottom="medium"
          tone="transparent"
        >
          <Heading level="h1">{heading}</Heading>
        </Box>

        <Stack space="medium" paddingTop={'none'}>
          <CreateBusinessForm
            requestKey={REQUEST_KEY.BUSINESS.CREATE}
            roleOptions={BUSINESS_ROLE_OPTIONS(t)}
            initialValues={initialValues}
            isSaveDraft={true}
            submitAction={this.submit.bind(this)}
            submitButtonLabel={submitButtonLabel}
          />
        </Stack>
      </Div>
    );
  }
}

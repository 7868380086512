import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { AUTH_EXCEPTION } from '@seek/je-error-helper';
import { Card, CardContent } from '@components/Card';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import ButtonWithSecondaryLink from '../../../../../../common/components/ButtonWithSecondaryLink/ButtonWithSecondaryLink';
import ConnectedErrorMessage from '../../../../../components/ConnectedErrorMessage/ConnectedErrorMessage';
import Form from '../../../../../components/ConnectedForm/ConnectedForm';
import PasswordField from '../../../../../components/PasswordField/PasswordField';
import Submit from '../../../../../components/Submit/Submit';
import REQUEST_KEY from '../../../../../constants/requestKeys';
import * as authActions from '../../../../../store/auth/actions';
import { validateUpdatePasswordForm } from './helpers/validation';

@connect(
  ({ localisation }) => ({
    localisation
  }),
  {
    updatePassword: authActions.updatePassword
  }
)
@reduxForm({
  form: 'update-password-form',
  validate: validateUpdatePasswordForm
})
@withTranslation()
export default class UpdatePasswordForm extends Component {
  static propTypes = {
    ...reduxFormPropTypes,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    localisation: PropTypes.shape({
      countryCode: PropTypes.string.isRequired
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const { showPassword } = this.state;
    const {
      valid,
      submitFailed,
      t,
      handleSubmit,
      updatePassword,
      localisation: { countryCode }
    } = this.props;

    return (
      <Card>
        <CardContent>
          <Form
            requestKey={REQUEST_KEY.AUTH.UPDATE_PASSWORD}
            reduxFormSubmit={handleSubmit}
            onSubmit={updatePassword}
            method="POST"
            noValidate
            valid={valid}
            submitFailed={submitFailed}
            insideCard
            errorSummary={t('validations.general.summary')}
          >
            <Field
              component={PasswordField}
              t={t}
              firstField
              name="currentPassword"
              label={t('updatePasswordForm.fieldLabel.currentPassword')}
              showPassword={showPassword}
              togglePassword={this.togglePassword}
              validationMessages={{
                ...t('validations.general.password', { returnObjects: true }),
                [AUTH_EXCEPTION.INCORRECT_CURRENT_PASSWORD]: t(
                  'errors.updatePassword.incorrectCurrentPassword'
                )
              }}
            />
            <Field
              component={PasswordField}
              t={t}
              firstField
              name="newPassword"
              label={t('common.fieldLabel.newPassword')}
              showPassword={showPassword}
              validationMessages={t('validations.general.password', {
                returnObjects: true
              })}
              extraTopMargin
            />

            <Stack marginTop="xxsmall">
              <Text size="small" tone="neutralLight">
                {t('common.explanationText.password')}
              </Text>
            </Stack>

            <Field
              component={PasswordField}
              t={t}
              name="confirmPassword"
              extraTopMargin
              label={t('common.fieldLabel.confirmNewPassword')}
              showPassword={showPassword}
              validationMessages={t('validations.general.password', {
                returnObjects: true
              })}
            />
            <ButtonWithSecondaryLink
              linkText={t('common.action.cancel')}
              data-test-key="update-cancel-button"
              linkLocation={`/${countryCode}/account`}
              button
              insideCard
              extraTopMargin
            >
              <Submit
                insideCard
                data-test-key="update-password-button"
                extraTopMargin
              >
                {' '}
                {t('updatePasswordForm.action.updatePassword')}
              </Submit>
            </ButtonWithSecondaryLink>
          </Form>
          <ConnectedErrorMessage
            hideWithSubmissionError
            requestKey={REQUEST_KEY.AUTH.UPDATE_PASSWORD}
            extraTopMargin
          />
        </CardContent>
      </Card>
    );
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { WebDropdown } from '@components/FormElements/Dropdown';
import { useIndustrySelector } from './hooks/useIndustrySelector';

type IndustrySelectorProps = {
  value: string | undefined;
  onChange: () => void;
  error?: string;
};

export const IndustrySelector = (props: IndustrySelectorProps) => {
  const { t } = useTranslation();
  const { industrySelectorOptions } = useIndustrySelector();
  const { error, onChange, value } = props;

  return (
    <Field
      label={t('createBusinessForm.fieldLabel.industry')}
      message={
        error && (
          <Box marginTop="xxsmall">
            <ErrorMessage>{error}</ErrorMessage>
          </Box>
        )
      }
    >
      <Box>
        <WebDropdown
          items={industrySelectorOptions}
          selectedItem={value}
          onChange={onChange}
          placeholder={t('business.industry.select')}
          testID="business-form-industrySelector"
        />
      </Box>
    </Field>
  );
};

import React from 'react';
import { PaymentProvider } from '@common/components/FormElements/PaymentProvider';
import { RequestErrorMessages } from '@common/components/RequestErrorMessage';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import CheckoutForm from '@src/joraEmployer/components/CheckoutForm/CheckoutForm';
import { useGetRequestErrors, useSetupPayment } from '../hooks';

type Props = {
  adType: AD_TYPE.PLUS | AD_TYPE.MAX;
  jobId: string;
  jobCountryCode: SupportedCountryCode;
  paymentCampaign: string;
  sourceParams?: string;
  currencyPrice: {
    [AD_TYPE.PLUS]: string;
    [AD_TYPE.MAX]: string;
  };
  // Stripe _requires_ a return_url that it will automatically redirect to (even for card payments) or it WILL error
  // when calling confirmPayment. So we unfortunately can't use onCompleted/onNext ...
  // see:
  // - https://docs.stripe.com/js/payment_intents/confirm_payment
  // - apps/je-ui/src/joraEmployer/store/payment/stripeApi/stripeApi.ts
  onCompletedUrl: string;
  backButton?: React.ReactNode;
};

export const PaymentForm = (props: Props) => {
  const {
    adType,
    jobId,
    jobCountryCode,
    paymentCampaign,
    currencyPrice,
    sourceParams,
    onCompletedUrl,
    backButton
  } = props;
  const { requestKeys } = useGetRequestErrors();

  useSetupPayment({
    currencyPrice,
    campaign: paymentCampaign,
    jobCountryCode,
    jobId,
    sourceParams,
    adType
  });

  return (
    <div className="flex flex-col gap-md">
      <PaymentProvider>
        <CheckoutForm
          jobId={jobId}
          campaign={paymentCampaign}
          onCompletedUrl={onCompletedUrl}
          backButton={backButton}
        />
      </PaymentProvider>

      <RequestErrorMessages requestKeys={requestKeys} />
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Column } from '@components/Column';
import { UpgradeAccountOptionsIllustration } from '@components/Illustration';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { UpgradeAccountModalWithButton } from '../UpgradeAccountModalWithButton';

type UpgradeAccountBannerProps = {
  pageTitle: string;
  source: string;
};

export const UpgradeAccountBanner = ({
  pageTitle,
  source
}: UpgradeAccountBannerProps) => {
  const { t } = useTranslation();
  const title = t('account.upgradeAccountBanner.title');
  const description = t('account.upgradeAccountBanner.description');
  const cta = t('account.upgradeAccountBanner.cta');

  return (
    <Box paddingVertical="large" paddingHorizontal="xlarge" tone="success">
      {/* Mobile */}
      <Column
        tone="transparent"
        visible={{ desktop: false, tablet: false, mobile: true }}
      >
        <Row tone="transparent" marginBottom="small">
          <Box tone="transparent" marginRight="xsmall" flexShrink={1}>
            <Text weight="regular">{title}</Text>
            <Text size="small" height="medium">
              {description}
            </Text>
          </Box>

          <Box tone="transparent" width="xsmall">
            <UpgradeAccountOptionsIllustration size="medium" />
          </Box>
        </Row>

        <UpgradeAccountModalWithButton
          pageTitle={pageTitle}
          buttonText={cta}
          source={source}
        />
      </Column>

      {/* Desktop */}
      <Row
        tone="transparent"
        visible={{ desktop: true, tablet: true, mobile: false }}
      >
        <Column
          tone="transparent"
          spacing="xxsmall"
          flexShrink={1}
          paddingRight="small"
        >
          <Text weight="regular">{title}</Text>
          <Text size="small" height="medium">
            {description}
          </Text>

          <Box marginTop="small" tone="transparent">
            <UpgradeAccountModalWithButton
              pageTitle={pageTitle}
              buttonText={cta}
              source={source}
            />
          </Box>
        </Column>

        <Box tone="transparent">
          <UpgradeAccountOptionsIllustration size="medium" />
        </Box>
      </Row>
    </Box>
  );
};

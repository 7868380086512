import { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the video screening questions field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function videoScreeningQuestionsRules(t: T) {
  return {
    validate: (value: string) => {
      if (value === undefined || value === null || value.trim() === '') {
        return t('validations.job.videoScreeningQuestions.required');
      }
    }
  };
}

import { TFunction } from 'i18next';

export const options = (t: TFunction) => [
  {
    label: t('postJobForm.targetLocation.yes'),
    key: true
  },
  {
    label: t('postJobForm.targetLocation.no'),
    key: false
  }
];

import { useTranslation } from '@common/hooks/useTranslation';
import { businessAddressRules } from './businessAddressRules';
import { businessNameRules } from './businessNameRules';
import { businessRegistrationRules } from './businessRegistrationRules';
import { businessSizeRules } from './businessSizeRules';
import { businessWebsiteRules } from './businessWebsiteRules';
import { industryDescriptionRules } from './industryDescriptionRules';
import { industryRules } from './industryRules';
import { recruitmentCompanyNameRules } from './recruitmentCompanyNameRules';
import { roleRules } from './roleRules';

export function useBusinessRules() {
  const { t } = useTranslation();

  return {
    businessAddressRules: businessAddressRules(t),
    businessNameRules: businessNameRules(t),
    businessRegistrationRules: (businessRegistrationNumberFieldName: string) =>
      businessRegistrationRules(t, businessRegistrationNumberFieldName),
    businessSizeRules: businessSizeRules(t),
    businessWebsiteRules: businessWebsiteRules(t),
    industryDescriptionRules: industryDescriptionRules(t),
    industryRules: industryRules(t),
    recruitmentCompanyNameRules: recruitmentCompanyNameRules(t),
    roleRules: roleRules(t)
  };
}

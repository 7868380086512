import React from 'react';
import clsx from 'clsx';
import { RadioIcon } from './RadioIcon';
import { RadioLabel } from './RadioLabel';

export type LabelType = string | React.ReactElement;

export type Props = {
  /**
   * The string or JSX element representing the text or content to display
   * alongside the RadioButton
   */
  label?: LabelType;
  /**
   * An optional function to be called when the RadioButton is pressed
   */
  onPress?: () => void;
  /**
   * An optional function to be called when the RadioButton receives focus
   */
  onFocus?: () => void;
  /**
   * An optional function to be called when the RadioButton loses focus
   */
  onBlur?: () => void;
  /**
   * An optional, unique identifier to be used for testing purposes
   */
  testID?: string;
  disabled?: boolean;
  checked?: boolean;
  align?: 'left' | 'right';
};

export const RadioButton = (props: Props) => {
  const { label, align = 'left', onPress } = props;

  return (
    <div
      className={clsx(
        'flex items-center gap-[12px] cursor-pointer',
        align === 'right' && 'justify-between'
      )}
      onClick={onPress}
    >
      {align === 'left' && <RadioIcon {...props} />}
      {label && <RadioLabel {...props} label={label} />}
      {align === 'right' && <RadioIcon {...props} />}
    </div>
  );
};

import { EnhanceFormData } from '@common/components/Job/EnhanceJob/EnhanceJobWithCoinsAndTargetLocation';
import { JobForEdit } from '@common/hooks/job';

export const toFormData = (
  job: JobForEdit | undefined
): Partial<EnhanceFormData> => {
  if (!job) return {};

  const formData: Partial<EnhanceFormData> = {
    rightToWork: job?.rightToWork,
    immediateStart: job?.immediateStart,
    videoScreeningQuestion: job?.videoScreeningQuestions?.[0]?.code,
    targetLocation: Boolean(job?.targetLocations?.[0]?.locationCode)
  };

  return formData;
};

import React from 'react';
import CreateAccountForm from '@common/components/AccountMultiStep/forms/createAccount/CreateAccountForm';
import { CompleteRegisterFormData } from '@common/components/AccountMultiStep/forms/createAccount/types';
import { StepWrapper } from '@common/components/Caja/components';
import { useRegister, useSendVerificationEmail } from '@common/hooks/account';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';
import { useShowAgeVerification } from '@common/hooks/useShowAgeVerification';
import { useShowCookieConsent } from '@common/hooks/useShowCookieConsent';
import { useTranslation } from '@common/hooks/useTranslation';
import { ErrorResponse } from '@store/auth/types';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type Props = Pick<TopLevelStep<undefined>, 'onCompleted'>;

export function CreateAccount(props: Props) {
  const { onCompleted } = props;

  const { t } = useTranslation();
  const { brandNameText } = useAppSelector(getLocalisation);
  const sourceParams = useGetSourceParams();
  const register = useRegister();
  const sendVerificationEmail = useSendVerificationEmail();
  const showCookieConsent = useShowCookieConsent();
  const showAgeVerification = useShowAgeVerification();

  async function onRegisterSubmit(
    data: CompleteRegisterFormData
  ): Promise<{ isSuccessful: boolean; error?: ErrorResponse }> {
    const response = await register({
      registerData: data,
      sourceParams
    });

    if (response.isSuccessful) {
      await sendVerificationEmail({ isResend: false });
    }

    return response;
  }

  async function registerFormSubmit(data: CompleteRegisterFormData) {
    const response = await onRegisterSubmit(data);

    if (response?.isSuccessful) {
      onCompleted({});
    }

    return response;
  }

  return (
    <StepWrapper>
      <CreateAccountForm
        submitBtnText={t('common.action.next')}
        onSubmit={registerFormSubmit}
        brandNameText={brandNameText || ''}
        showCookieConsent={showCookieConsent}
        showAgeVerification={showAgeVerification}
      />
    </StepWrapper>
  );
}

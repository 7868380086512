/* eslint css-modules/no-unused-class: [2, { markAsUsed: [
  'label'
] }] */
import { Component } from 'react';
import classNames from 'classnames';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { withTranslation } from 'react-i18next';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import * as events from '../../../constants/events';
import * as constants from '../../../constants/general';
import ReduxFormField from '../ReduxFormField/ReduxFormField';
import styles from './InputField.scss';

@withTranslation()
@ReduxFormField
export default class InputField extends Component {
  static propTypes = {
    fieldId: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.any,
    touched: PropTypes.bool,
    forceFocus: PropTypes.bool,
    shortField: PropTypes.bool,
    longField: PropTypes.bool,
    hideLabel: PropTypes.bool,
    extraTopMargin: PropTypes.bool,
    rows: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    charCountMax: PropTypes.number,
    firstField: PropTypes.bool,
    blacklisthotjar: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.subscription = PubSub.subscribe(
      events.AUTO_FILL_CHECK_REQUIRED,
      this.autoFillCheck.bind(this)
    );
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.subscription);
  }

  //check if field value have been changed by browser autofill
  //iOS chrome doesn't detect the changes otherwise and the user will receive a validation error
  autoFillCheck() {
    const { onChange, fieldId, value } = this.props;

    const currentFieldValue = this.refs[fieldId].value;

    if (currentFieldValue !== value) {
      onChange(currentFieldValue);
    }
  }

  inputClass() {
    const { error, touched, forceFocus } = this.props;

    if (forceFocus) {
      return styles.forceFocus;
    }

    return error && touched ? styles.inputInvalid : classNames(styles.input);
  }

  fieldBlur() {
    const { onBlur } = this.props;

    PubSub.publish(events.AUTO_FILL_CHECK_REQUIRED);

    onBlur();
  }

  charCounter() {
    const { charCountMax, value, t } = this.props;

    if (charCountMax) {
      const remainingCount = value ? charCountMax - value.length : charCountMax;

      if (remainingCount >= 0) {
        return (
          <div className={styles.charCounter}>
            {t('validations.general.inputField.message', {
              count: remainingCount
            })}
          </div>
        );
      }

      return (
        <div className={styles.charCounterOver}>
          {t('validations.general.inputField.maxLength', {
            count: remainingCount * -1
          })}
        </div>
      );
    }
  }

  render() {
    const {
      label,
      fieldId,
      shortField,
      longField,
      rows,
      hideLabel,
      extraTopMargin,
      firstField,
      blacklisthotjar
    } = this.props;
    const propsToSpread = omit(
      this.props,
      'autoCapitalize',
      'label',
      'shortField',
      'forceFocus',
      'fieldId',
      'charCountMax',
      'hideLabel',
      'extraTopMargin',
      'firstField',
      'inModal',
      'blacklisthotjar',
      't',
      'tReady',
      'longField',
      constants.REDUX_FORM_PROPS
    );
    const whitelistInHotjar = blacklisthotjar
      ? {}
      : { 'data-hj-whitelist': '' };
    const fieldLength =
      (shortField && styles.shortField) ||
      (longField && styles.longField) ||
      styles.root;

    return (
      <div
        className={classNames(fieldLength, {
          [styles.extraTopMargin]: extraTopMargin,
          [styles.firstField]: firstField
        })}
      >
        {hideLabel ? null : (
          <Box tone="transparent" marginBottom="xxsmall">
            <Text weight="regular">{label}</Text>
          </Box>
        )}
        {rows ? (
          <div>
            <textarea
              data-test-key={fieldId}
              ref={fieldId}
              className={this.inputClass()}
              aria-label={label}
              {...propsToSpread}
              {...whitelistInHotjar}
              onBlur={this.fieldBlur.bind(this)}
            />
            {this.charCounter()}
          </div>
        ) : (
          <input
            type="text"
            data-test-key={fieldId}
            id={fieldId}
            ref={fieldId}
            className={this.inputClass()}
            aria-label={label}
            {...propsToSpread}
            {...whitelistInHotjar}
            onBlur={this.fieldBlur.bind(this)}
          />
        )}
      </div>
    );
  }
}

import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { SupportLevel } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useWhatHappensNow() {
  const { supportTier } = useAppSelector(getLocalisation);

  return {
    isSupportTier3: supportTier === SupportLevel.TIER_3
  };
}

export function useWhatHappensNowActions() {
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return {
    redirectToPostJobPage: redirect(postJobLink),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlreadyHaveAccountLink } from '@common/components/Account/components/AlreadyHaveAnAccountLink';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useErrorHandler } from '@common/hooks/useErrorHandler';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { FormContext } from './FormContext';
import { errorMap } from './formErrorMap';
import { useStartRecaptcha } from './hooks/useStartRecaptcha';
import { hydrateRegisterFormProps } from './props';
import {
  AgeVerification,
  AgreeToTerms,
  ConfirmEmail,
  ConfirmPassword,
  CookieConsent,
  Email,
  GivenName,
  MarketingConsent,
  MobileNumber,
  Password,
  Recaptcha,
  Surname
} from './topLevelFields';
import type { RecaptchaHandle } from './topLevelFields';
import {
  CompleteRegisterFormData,
  FormProps,
  IncompleteRegisterFormData
} from './types';

export default function CreateAccountForm(opts: FormProps) {
  const { t } = useTranslation();

  const { isFieldError, setFieldLevelError, setGlobalFormError } =
    useErrorHandler(errorMap);

  const props = hydrateRegisterFormProps(opts);
  const { onSubmit, submitBtnText, brandNameText } = props;

  const recaptchaRef = React.createRef<RecaptchaHandle>();
  const { startRecaptcha } = useStartRecaptcha(recaptchaRef);

  const [hasFormError, setHasFormError] = useState(false);
  const [hasGlobalError, setHasGlobalError] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors, isSubmitted, isValid }
  } = useForm<IncompleteRegisterFormData>({
    defaultValues: props.defaultValues,
    mode: 'onBlur'
  });

  function initiateRecaptcha() {
    startRecaptcha();
  }

  async function onRecaptchaVerifySuccess(recaptchaResponse: string) {
    const formData = getValues() as CompleteRegisterFormData;
    const response = await onSubmit({ ...formData, recaptchaResponse });

    if (response?.error) {
      if (isFieldError(response.error)) {
        setFieldLevelError({ error: response?.error, setError, t });
        setHasFormError(true);
        return;
      }

      setGlobalFormError(REQUEST_KEY.AUTH.REGISTER);
      setHasGlobalError(true);
      return;
    }

    setHasFormError(false);
    setHasGlobalError(false);
  }

  const shouldShowFormError = hasFormError || (isSubmitted && !isValid);

  return (
    <FormContext.Provider value={{ control, errors }}>
      <Stack spacing="large">
        <AlreadyHaveAccountLink />
        <GivenName />
        <Surname />
        <Email />
        <ConfirmEmail />
        <MobileNumber />
        <Password />
        <ConfirmPassword />

        <div>
          {props.showCookieConsent && <CookieConsent />}
          {props.showAgeVerification && <AgeVerification />}
          <AgreeToTerms brandNameText={brandNameText} />
          <MarketingConsent />
        </div>

        {shouldShowFormError && (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        )}
        {hasGlobalError && (
          <RequestErrorMessage requestKey={REQUEST_KEY.AUTH.REGISTER} />
        )}

        <div>
          <Button
            variant="primary"
            onPress={handleSubmit(initiateRecaptcha)}
            fullWidth
          >
            {submitBtnText}
          </Button>
        </div>
      </Stack>

      <Recaptcha
        recaptchaRef={recaptchaRef}
        onVerify={onRecaptchaVerifySuccess}
      />
    </FormContext.Provider>
  );
}

import React from 'react';
import { Trans } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { Box } from '@components/Box';
import { Checkbox, Field } from '@components/FormElements';
import { Stack } from '@components/Stack';

type MarketingConsentProps = {
  value: boolean | undefined;
  onChange: () => void;
  error?: string;
};

export const MarketingConsent = ({
  value = true,
  onChange,
  error
}: MarketingConsentProps) => {
  const { brandNameText } = useAppSelector(getLocalisation);

  return (
    <Stack spacing="small">
      <Field
        label=""
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <Checkbox
            label={
              <Trans
                i18nKey="common.message.termsAndPrivacy.marketingConsent"
                values={{
                  brandNameText
                }}
              />
            }
            value={value}
            onChange={onChange}
          />
        </Box>
      </Field>
    </Stack>
  );
};

import { T } from '@common/hooks/useTranslation';
import { RightToWorkDocument } from '../types';

export const getRightToWorkSummary = (
  rightToWork: boolean,
  rightToWorkDocument: RightToWorkDocument,
  t: T
) => {
  if (rightToWork) {
    if (rightToWorkDocument === RightToWorkDocument.WORK_PASS) {
      return t('postJobForm.summary.noRequiredRightToWorkWorkPass');
    }

    return t('postJobForm.summary.noRequiredRightToWork');
  }

  return t('postJobForm.summary.rightToWork');
};

import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Badge } from '@components/Badge';

type Props = {
  text: string;
  disabled?: boolean;
};

export const FieldLabel = ({ text, disabled = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-sm mb-xsm">
      <label
        className={clsx(
          'text-md font-medium cursor-pointer leading-md',
          disabled && 'opacity-60'
        )}
      >
        {text}
      </label>
      <Badge tone="promo">{t('adBadge.plusOrMax')}</Badge>
    </div>
  );
};

import React from 'react';
import clsx from 'clsx';

type Item<T> = {
  label: string;
  value: T;
};

export type DropdownProps<T extends string> = {
  items: Item<T>[];
  onChange: (value: T) => void;
  disabled?: boolean;
  testID?: string;
  selectedItem?: Item<T>['value'];
  placeholder?: string;
  error?: boolean;
};

export const Dropdown = <T extends string>(props: DropdownProps<T>) => {
  const {
    items,
    selectedItem,
    onChange,
    placeholder,
    error = false,
    disabled = false,
    testID
  } = props;

  return (
    <div className="max-w-[430px]" onTouchStart={(e) => e.stopPropagation()}>
      <select
        disabled={disabled}
        value={selectedItem}
        className={clsx(
          'select flex h-auto w-full cursor-pointer select-bordered',
          'py-[12px] px-[16px] md:px-[24px] lg:px-[24px]',
          'rounded-sm',
          'focus:outline-none focus:ring',
          'whitespace-nowrap',
          'text-left text-md font-light leading-md tracking-md',
          'active:border-select-border-success hover:border-select-border-success',
          'disabled:border-select-border-disabled disabled:bg-white disabled:text-select-text-disabled',
          error && 'border-select-border-danger text-select-text-danger',
          !error && 'border-select-border-neutral text-select-text-neutral'
        )}
        onChange={(e) => onChange(e.target.value as T)}
        data-testid={testID}
        // This is a workaround for CreateBusiness and EditBusiness tests that use the `data-test-key` attribute
        data-test-key={testID}
      >
        {placeholder && (
          <option value="" disabled selected={!selectedItem} hidden>
            {placeholder}
          </option>
        )}
        {items.map((item, index) => (
          <option
            key={`dropdown-item-${index}-${item.value}`}
            value={item.value}
            disabled={item.value === selectedItem}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

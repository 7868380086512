import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Text } from '@components/Text';

export type Props = {
  adDuration?: number;
};

export const BasicAdInfo = (props: Props) => {
  const { adDuration: duration = '-' } = props;
  const { t } = useTranslation();

  return (
    <Text size="small" weight="light" height="medium">
      {t('upgradeJobForm.basic.continue', { duration })}
    </Text>
  );
};

import { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the right to work field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function rightToWorkRules(t: T) {
  return {
    validate: (value: boolean) => {
      if (value === undefined || value === null) {
        return t('validations.job.rightToWork.required');
      }
    }
  };
}

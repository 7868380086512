import { AppState } from '@store/store';

export function getPaymentClientId(state: AppState) {
  return state.payment.intent?.clientId;
}

export function getStripe(state: AppState) {
  return state.payment.stripe;
}

export function getPricingItems(jobCountryCode: string) {
  return (state: AppState) => {
    return state.payment.prices.items[jobCountryCode];
  };
}

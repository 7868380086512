import React from 'react';
import { I18nextTrans } from '@common/hooks/useTranslation';
import { Text } from '@components/Text';
import { useBusinessInformation } from '../../hooks/useBusinessInformation';
import { PostJobFormBusiness } from '../../types';

type BusinessNameProps = {
  business: PostJobFormBusiness;
};

export const BusinessName = ({ business }: BusinessNameProps) => {
  const { shouldRenderRecruitmentInformation } = useBusinessInformation({
    business
  });

  const { name, recruitmentCompanyName } = business;

  if (shouldRenderRecruitmentInformation) {
    return (
      <I18nextTrans
        i18nKey={'postJobForm.businessInformation.onBehalfOfRecruitment'}
        values={{
          recruitmentCompanyName,
          name
        }}
        parent={Text}
        components={[<Text weight="regular" />, <Text weight="regular" />]}
      />
    );
  }

  return (
    <Text weight="regular" testID="business-information-business-name">
      {name}
    </Text>
  );
};

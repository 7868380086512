import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { getCajaVariant } from '../../variants';
import type { CajaVariant } from '../../variants';

export function useCajaVariant(): CajaVariant {
  const brandName = useBrandName();
  const countryCode = useSiteCountryCode();

  return getCajaVariant(brandName, countryCode);
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_TARGET_LOCATION } from '@common/constants/targetLocation';
import { Badge } from '@components/Badge';
import { Box } from '@components/Box';
import { SuccessIcon } from '@components/Icon';
import { Row } from '@components/Row';

type Props = {
  suitabilityBadges: string[];
  yearsOfExperienceInJobRole: number;
  distanceFromJob?: number;
  targetLocation?: string;
  testID?: string;
};

export const SuitabilityBadges = ({
  suitabilityBadges,
  distanceFromJob = 0,
  yearsOfExperienceInJobRole,
  targetLocation,
  testID
}: Props) => {
  const { t } = useTranslation();

  return (
    <Row testID={testID} spacing="small" flexWrap="wrap">
      {suitabilityBadges.map((badge, index) => (
        <Box marginTop="xsmall" key={`${testID}-${index}`}>
          <Badge
            icon={
              <SuccessIcon variant="outline" tone="success" size="xsmall" />
            }
            tone="success"
            variant="secondary"
          >
            {t(`applications.applicationCard.suitabilityBadge.${badge}`, {
              distance: Math.round(distanceFromJob),
              count: yearsOfExperienceInJobRole,
              targetLocation: targetLocation
                ? AVAILABLE_TARGET_LOCATION[targetLocation]
                : ''
            })}
          </Badge>
        </Box>
      ))}
    </Row>
  );
};

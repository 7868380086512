import React from 'react';
import { Application } from '@common/types';
import { FILTERS } from '../../../../types';
import { ApplicantStatusTiles } from './components/ApplicantStatusTiles';
import { useApplicantStatusPanel } from './useApplicantStatusPanel';

type ApplicantStatusPanelProps = {
  applications: Application[];
};

export const ApplicantStatusPanel = ({
  applications = []
}: ApplicantStatusPanelProps) => {
  const { handleStatusChange, handleStatusClear, filterForm } =
    useApplicantStatusPanel();

  return (
    <ApplicantStatusTiles
      applications={applications}
      value={filterForm[FILTERS.APPLICANT_STATUS]}
      onChange={handleStatusChange}
      onClear={handleStatusClear}
    />
  );
};

import React from 'react';
import { Box } from '@components/Box';
import { CalloutText } from '@components/CalloutText';

export const JobsDbRetirementCalloutText = () => {
  return (
    <Box marginBottom="small">
      {/* // TODO: cleanup after jobsDB shutdown */}
      <CalloutText tone="warning">
        <div>
          Jobsdb will be replaced by Jobstreet Express Singapore on 1 January
          2025. New job postings are no longer available.{' '}
          <a
            href="https://employer.jobstreetexpress.com/sg/"
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          >
            Start using Jobstreet Express Singapore today!
          </a>
        </div>
      </CalloutText>
    </Box>
  );
};

import { ProfileLocation } from '@common/types';
import { getLocationLabel } from './getLocationLabel';
import { getNormalisedLocation } from './getNormalisedLocation';
import { LocatableItem, LocationFilterOption } from './type';

/**
 * Validates a location component.
 * A location component is valid if it at least has a `countryCode` property.
 * @param locationComponent - A location component to be validated.
 * @returns A boolean indicating whether the location component is valid.
 *
 */
const validateLocationComponent = (locationComponent: ProfileLocation) => {
  return Boolean(locationComponent.countryCode);
};

/**
 * Filters an array of items to include only those that have a `currentLocationComponents` property.
 *
 * @param items - An array of items that may or may not have a `currentLocationComponents` property.
 * @returns A filtered array of items that have a `currentLocationComponents` property.
 */
export const filterItemsWithValidLocation = (items: LocatableItem[]) => {
  return items.filter(
    (item): item is { currentLocationComponents: ProfileLocation } =>
      Boolean(item.currentLocationComponents) &&
      validateLocationComponent(item.currentLocationComponents!)
  );
};

/**
 * @param {LocatableItem} items List of applicants
 * @returns {LocationFilterOption[]} List of location filter options
 */
/**
 * Maps an array of locatable items to an array of location filter options.
 *
 * @param items - An array of locatable items to be filtered and mapped.
 * @returns An array of location filter options.
 */
export const mapLocationFilterOptions = (
  items: LocatableItem[]
): LocationFilterOption[] => {
  const applicationsWithLocation = filterItemsWithValidLocation(items);
  const locationFilterOptions = applicationsWithLocation.map((item) => {
    const normalisedLocationAddress = getNormalisedLocation(
      item.currentLocationComponents
    );
    const label = getLocationLabel(normalisedLocationAddress);

    return {
      label,
      value: normalisedLocationAddress.filterValue
    };
  });

  return locationFilterOptions;
};

import React from 'react';
import { TextWithIcon } from '@common/components/TextWithIcon';
import { RightTickIcon } from '@components/Icon';
import { Text } from '@components/Text';

interface BenefitProps {
  children: React.ReactNode;
}

export const Benefit = ({ children }: BenefitProps) => (
  <TextWithIcon
    alignItems="flex-start"
    marginTop="xxsmall"
    spacing="medium"
    icon={
      <RightTickIcon
        size="small"
        variant="outline"
        tone="success"
        testID="right-tick-icon"
      />
    }
  >
    <Text size="small" weight="light" height="medium">
      {children}
    </Text>
  </TextWithIcon>
);

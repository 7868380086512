import React from 'react';
import { AgreeToTerms as InnerField } from '@common/components/Account/components/AgreeToTerms';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { FieldWrapper } from '../FieldWrapper';

type AgreeToTermsProps = {
  brandNameText: string;
};

export function AgreeToTerms({ brandNameText }: AgreeToTermsProps) {
  const { termsAndPrivacyConsentRules } = useAccountRules();

  return (
    <FieldWrapper<boolean>
      name="termsAndPrivacyConsent"
      rules={termsAndPrivacyConsentRules(brandNameText)}
      render={(props) => <InnerField {...props} />}
    />
  );
}

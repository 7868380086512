import { useTrackFiltersCleared } from '../../../../../hooks';

export const useHandleClearButton = ({
  clearAllFilters,
  closeModal
}: {
  clearAllFilters: () => void;
  closeModal: () => void;
}) => {
  const trackFiltersCleared = useTrackFiltersCleared();

  return () => {
    clearAllFilters();
    trackFiltersCleared();
    closeModal();
  };
};

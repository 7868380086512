type DisplayableLocation = {
  district?: string;
  subDistrict?: string;
  countryName: string;
};

/**
 * @param {DisplayableLocation} location Normalised location address
 * @returns {string} Location label
 */
export const getLocationLabel = (location: DisplayableLocation): string => {
  const { district, subDistrict, countryName } = location;

  if (subDistrict) return district + ', ' + subDistrict;
  if (district) return district;

  return countryName;
};

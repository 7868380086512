import { TFunction } from 'i18next';

// Question: "Are you willing to provide a work visa for this role?",
export const options = (t: TFunction) => [
  {
    label: t('postJobForm.rightToWorkVisa.yes'),
    key: true // This means employer provides right to work
  },
  {
    label: t('postJobForm.rightToWorkVisa.no'),
    key: false // This means employer does not provide right to work
  }
];

// Question: "Would you like to target only {{country}} citizens and permanent residents?"
export const caja2Options = (t: TFunction) => [
  {
    label: t('postJobForm.rightToWorkVisa.yes'),
    key: false // This means employer does not provide right to work
  },
  {
    label: t('postJobForm.rightToWorkVisa.noWithSubText'),
    key: true // This means employer provides right to work
  }
];

import { useCurrentUser } from '../useCurrentUser';

/**
 * Checks if the current user has an impersonator token.
 * This is typically used to determine if the current user session is being impersonated.
 *
 * @returns {boolean} Returns true if the current user has an impersonator token, false otherwise.
 */
export function useHasImpersonatorToken(): boolean {
  return Boolean(useCurrentUser()?.impersonatorToken);
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { TwoPeopleHighFiving } from '@components/Illustration';
import { Stack } from '@components/Stack';

export const VerifyAccount = () => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const accountLink = useGetAccountLink();

  return (
    <Stack spacing="medium" tone="transparent">
      <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
        <Heading level="h1">
          {t('verifyEmailPage.heading.fromUpdateAccount')}
        </Heading>
      </Box>

      <Card>
        <CardContent>
          <Stack spacing="large">
            <Stack alignItems="center" spacing="medium">
              <TwoPeopleHighFiving
                size="large"
                testID="two-people-high-fiving"
              />
              <Heading level="h1" weight="regular">
                {t('verifyEmailSuccessPage.description.emailHasBeenVerified')}
              </Heading>
            </Stack>

            <Button onPress={redirect(accountLink)}>
              {t('common.action.continue')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

import { BusinessWithUsers } from '@store/entities/businesses/types';

export const useDraftBusiness = (
  businesses: BusinessWithUsers[],
  draftBusinessId?: string
): BusinessWithUsers | undefined => {
  return (
    businesses.find((business) => business.id === draftBusinessId) ??
    defaultBusiness(businesses)
  );
};

const defaultBusiness = (businesses: BusinessWithUsers[]) => {
  return businesses[0];
};

import React from 'react';
import { TFunction } from 'react-i18next';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Field, TextArea } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';

type NotesProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  t: TFunction<'translation', undefined>;
  maxChars: number;
};

export const Notes = ({ error, onChange, value, maxChars, t }: NotesProps) => {
  return (
    <Stack spacing="large">
      <Text size="small">{t('applications.notes.modal.label')}</Text>
      <Field
        testID="notes-field"
        label=""
        message={
          error && (
            <div className="mt-2">
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          )
        }
      >
        <TextArea
          placeholder={t('applications.notes.placeholder.notes')}
          onChange={onChange}
          value={value}
          charCountMax={maxChars}
        />
      </Field>
    </Stack>
  );
};

import React, { ReactNode, useState } from 'react';

export type TabsProps = {
  items: { label: string; key: string; content: ReactNode }[];
  onChange?: (key: string) => void;
  suffix?: ReactNode;
};

/**
 * A functional component that renders a set of tabs.
 *
 * @param {TabsProps} props - The properties for the Tabs component.
 * @param {Array<{ label: string, content: React.ReactNode }>} props.items - An array of tab items, each containing a label and content.
 * @param {function} props.onChange - A callback function that is called when the selected tab changes.
 * @param {React.ReactNode} props.suffix - An optional suffix element to be displayed on the right side of the tabs.
 *
 * @returns {JSX.Element} The rendered Tabs component.
 */
export const Tabs = ({ items, onChange = () => {}, suffix }: TabsProps) => {
  const [tabKey, setTabKey] = useState(items?.[0].key || '');

  const handleTabChange = (key) => {
    setTabKey(key);
    onChange(key);
  };

  return (
    <div>
      <div role="tablist" className="tabs tabs-bordered w-fit">
        {items.map(({ label, key }) => (
          <a
            role="tab"
            className={`tab ${tabKey === key ? 'tab-active' : ''}`}
            onClick={() => handleTabChange(key)}
          >
            {label}
          </a>
        ))}
        {suffix && (
          <div className="md:h-16 md:mb-5 md:row-start-1 ml-4 inline-flex items-center">
            {suffix}
          </div>
        )}
      </div>
      {items.map(({ content, key }) => (
        <div role="tabpanel" className={tabKey !== key ? 'hidden' : ''}>
          {content}
        </div>
      ))}
    </div>
  );
};

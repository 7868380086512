import { useCallback, useEffect, useRef } from 'react';
import { openResume } from '@src/nativeBridge/actions';
import { getResumeUrl } from '@store/entities/applications/actions';
import { getTemporaryResumeLink } from '@store/entities/applications/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export function useDownloadResumeCardAction(applicationId: string) {
  const dispatch = useAppDispatch();
  const isDownloadRequestedRef = useRef(false);
  const temporaryResumeLink = useAppSelector(
    getTemporaryResumeLink(applicationId)
  );

  useEffect(() => {
    if (isDownloadRequestedRef.current && temporaryResumeLink) {
      isDownloadRequestedRef.current = false;
      downloadAndOpenResume(temporaryResumeLink.url);
    }
  }, [temporaryResumeLink]);

  const downloadResume = useCallback(async () => {
    isDownloadRequestedRef.current = true;
    await dispatch(getResumeUrl(applicationId));
  }, [applicationId, dispatch]);

  return { downloadResume };
}

/**
 * Downloads and opens a resume from the given URL.
 *
 * This function attempts to open the resume using the `openResume` function.
 * If the application version is not supported or if the platform is web-based,
 * it falls back to setting the window location to the URL, prompting a download.
 *
 * @param url - The URL of the resume to be downloaded and opened.
 * @returns A promise that resolves when the resume has been handled.
 */
async function downloadAndOpenResume(url: string): Promise<void> {
  const resumeResponse = await openResume(url);

  const applicationVersion = resumeResponse?.system?.applicationVersion;
  const webPlatform = !applicationVersion;
  const unsupportedNativeVersion =
    applicationVersion && applicationVersion < '1.9.2';

  if (webPlatform || unsupportedNativeVersion) {
    window.location.href = url;
    return;
  }
}

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useBrandDomain } from '@common/hooks/localisation/useBrandDomain';
import { useBrandName } from '@common/hooks/localisation/useBrandName';
import { useListenForChanges } from '@common/hooks/useListenForChanges';
import { useTranslation } from '@common/hooks/useTranslation';
import { BUSINESS_ROLE, INDUSTRY } from '@seek/je-shared-data';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';
import { FormContext } from './FormContext';
import { hydrateBusinessFormProps } from './props/hydrateDefaultProps';
import {
  BusinessAddress,
  BusinessName,
  BusinessRegistration,
  BusinessSize,
  BusinessWebsite,
  CountrySelector,
  IndustryDescription,
  IndustrySelector,
  RecruitmentCompanyName,
  Role
} from './topLevelFields';
import {
  CompleteBusinessFormData,
  FormProps,
  IncompleteBusinessFormData
} from './types';

export default function BusinessForm(opts: FormProps) {
  const { t } = useTranslation();
  const props = hydrateBusinessFormProps(opts);
  const { onSubmit, defaultValues, submitBtnText, showCountrySelector } = props;
  const brandDomain = useBrandDomain();
  const brandName = useBrandName();

  const [countryCode, setCountryCode] = useState<string | undefined>(
    defaultValues?.country
  );
  const [showIndustryDescription, setShowIndustryDescription] =
    useState<boolean>(false);
  const [showRecruitmentCompanyName, setShowRecruitmentCompanyName] =
    useState<boolean>(false);

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted, isValid }
  } = useForm<IncompleteBusinessFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  function onFormSubmit(data: IncompleteBusinessFormData) {
    onSubmit(data as CompleteBusinessFormData);
  }

  function interceptOnChange(data: IncompleteBusinessFormData) {
    setCountryCode(data.country);
    setShowIndustryDescription(data.industryId === INDUSTRY.OTHER);
    setShowRecruitmentCompanyName(data.role === BUSINESS_ROLE.RECRUITER);
  }

  useListenForChanges({
    watch,
    onChange: interceptOnChange
  });

  return (
    <FormContext.Provider value={{ control, errors }}>
      <Stack spacing="large">
        <BusinessName />
        {showCountrySelector && (
          <Box zIndex={10}>
            <CountrySelector />
          </Box>
        )}
        <Box zIndex={9}>
          <BusinessAddress
            initialValue={defaultValues.businessAddress || ''}
            countryCode={countryCode}
          />
        </Box>
        <Box zIndex={8}>
          <IndustrySelector />
        </Box>
        {showIndustryDescription && <IndustryDescription />}
        <Box zIndex={7}>
          <BusinessSize />
        </Box>
        <Box zIndex={6}>
          <Role />
        </Box>
        {showRecruitmentCompanyName && <RecruitmentCompanyName />}
        <BusinessWebsite
          helperText={t('verifyAccountDetailsForm.fieldHelpText.website', {
            returnObjects: true,
            brandDomain,
            brand: brandName.toLowerCase()
          })}
        />
        <BusinessRegistration />

        <Button fullWidth onPress={handleSubmit(onFormSubmit)}>
          {submitBtnText}
        </Button>

        {isSubmitted && !isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}
      </Stack>
    </FormContext.Provider>
  );
}

import { CAJA_VARIANTS } from '../../hooks';
import { CajaVariant } from '../../variants';
import { PaymentJob as PaymentWithCoins } from './default_jse';
import { PaymentJob as PaymentWithCurrency } from './default_v2';

type Params = {
  cajaVariant: CajaVariant;
};

export const paymentJobRouter = (param: Params) => {
  if (param.cajaVariant === CAJA_VARIANTS.DEFAULT) {
    return PaymentWithCurrency;
  }

  return PaymentWithCoins;
};

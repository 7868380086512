import { useCallback, useEffect, useState } from 'react';
import { useBrandName, useSiteCountryCode } from '@common/hooks/localisation';
import { Suggestion } from '@components/FormElements/AutoSuggest';
import { JobTitle } from '../types';
import fetchJobTitles from './fetchJobTitles';
import { filterAndFormatJobTitles } from './filterAndMapJobTitles';
import { jobTitleExists } from './jobTitleExists';

export default function useTitleSuggestions() {
  const brandName = useBrandName();
  const countryCode = useSiteCountryCode();
  const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);

  useEffect(() => {
    async function loadJobTitles() {
      try {
        const jobTitleData = await fetchJobTitles({ brandName, countryCode });
        setJobTitles(jobTitleData);
      } catch (error) {
        console.error(
          `Failed to load job titles for: ${brandName}-${countryCode}`,
          error
        );
      }
    }

    loadJobTitles();
  }, [brandName, countryCode]);

  const getSuggestions = useCallback(
    (searchTerm: string): Suggestion[] =>
      filterAndFormatJobTitles(jobTitles, searchTerm),
    [jobTitles]
  );

  const isJobTitleInvalid = (jobTitle: string) => {
    if (!jobTitle) return false;

    return jobTitleExists(jobTitles, jobTitle);
  };

  return { getSuggestions, isJobTitleInvalid };
}

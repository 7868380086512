import {
  BrandName,
  SupportedCountryCode
} from '@seek/je-shared-data/lib/types/brand';
import { CAJA_VARIANTS, CajaVariant } from './constants';

/**
 * Returns the appropriate Caja variant based on the provided brand name and country code.
 *
 * @param brandName - The name of the brand.
 * @param countryCode - The supported country code.
 * @returns The Caja variant corresponding to the brand name and country code.
 */
export function getCajaVariant(
  brandName: BrandName,
  countryCode: SupportedCountryCode
): CajaVariant {
  const isJse = brandName === BrandName.JOBSTREETEXPRESS;
  const isSingapore = countryCode === SupportedCountryCode.Singapore;

  if (isJse && !isSingapore) {
    return CAJA_VARIANTS.JSE;
  }

  if (isJse && isSingapore) {
    return CAJA_VARIANTS.JSE_SG;
  }

  return CAJA_VARIANTS.DEFAULT;
}

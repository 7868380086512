import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { RequestErrorMessage } from '@common/components/RequestErrorMessage';
import REQUEST_KEY from '@common/constants/requestKeys';
import { isPermittedToShowApplicationInformation } from '@common/helpers/jobs';
import { useMonetisationEnabled } from '@common/hooks/featureToggles';
import { useMismatchedStripeAccount } from '@common/hooks/featureToggles/useMonetisationEnabled/useMonetisationEnabled';
import { useLoadJobData } from '@common/hooks/job';
import { useRedirectToJobNotFoundIfError } from '@common/hooks/useRedirectToJobNotFoundIfError';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { upgradeable } from '@src/job';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { JobApplicationDetails } from './components/JobApplicationDetails';
import { JobDetails } from './components/JobDetails';

export const JobDetailsAndApplicationsList = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { t } = useTranslation();
  useRedirectUnauthenticatedUser();
  useRedirectToJobNotFoundIfError();
  const { hasLoadJobError, job } = useLoadJobData(jobId);
  const isMonetisationEnabled = useMonetisationEnabled(job.countryCode || '');
  const isMismatchedStripeAccount = useMismatchedStripeAccount(
    job.countryCode as SupportedCountryCode
  );

  if (hasLoadJobError) {
    return (
      <Card>
        <CardContent>
          <Box>
            <RequestErrorMessage requestKey={REQUEST_KEY.JOB.LOAD} />
          </Box>
        </CardContent>
      </Card>
    );
  }

  const shouldDisplayJobComponents = Object.keys(job).length > 0;
  const shouldDisplayApplicationList = isPermittedToShowApplicationInformation(
    job.status
  );
  const showUpgrades =
    !isMismatchedStripeAccount &&
    isMonetisationEnabled &&
    upgradeable(job.adType, job.status);

  const pageTitle = job.title;

  return (
    <Stack
      marginBottom="medium"
      tone="transparent"
      testID="job-details-and-application-management"
    >
      {shouldDisplayJobComponents ? (
        <Stack tone="transparent">
          <JoraEmployerHelmet title={pageTitle} />
          <Box
            paddingHorizontal="medium"
            marginTop="medium"
            marginBottom="medium"
            tone="transparent"
          >
            <Heading level="h1">
              {`${job.title} - ${t(
                `jobDetailsAndApplicationsListPage.jobType.${job.jobType}`
              )}`}
            </Heading>
          </Box>

          <JobDetails job={job} showUpgradeNudge={showUpgrades} />
        </Stack>
      ) : null}

      {shouldDisplayApplicationList ? (
        <JobApplicationDetails jobId={jobId} pageTitle={pageTitle} />
      ) : null}
    </Stack>
  );
};

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { SadManIllustration } from '@components/Illustration';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import Div from '../../../common/components/Div/Div';
import { unsubscribeFromEmail } from '../../store/unsubscribe/actions';

@connect(
  (state) => ({
    localisation: state.localisation
  }),
  {
    unsubscribeFromEmail
  }
)
@withTranslation()
export default class Unsubscribe extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    unsubscribeFromEmail: PropTypes.func.isRequired,
    localisation: PropTypes.shape({
      supportEmail: PropTypes.string.isRequired,
      brandNameText: PropTypes.string.isRequired
    })
  };

  componentDidMount() {
    const {
      location: { search },
      unsubscribeFromEmail
    } = this.props;
    const queryParams = queryString.parse(search);

    if (queryParams.userId) {
      unsubscribeFromEmail({
        userId: queryParams.userId
      });
    }
  }

  render() {
    const {
      t,
      localisation: { brandNameText }
    } = this.props;

    return (
      <Div>
        <JoraEmployerHelmet title={t('unsubscribePage.title')} />
        <Box
          paddingHorizontal="medium"
          marginTop="medium"
          marginBottom="medium"
          tone="transparent"
        >
          <Heading level="h1">{t('unsubscribePage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <Stack spacing="medium" alignItems="center">
              <SadManIllustration />
              <Text weight="regular">
                {t('unsubscribePage.description.youAreUnsubscribed', {
                  brandNameText
                })}
              </Text>
              <Text>{t('unsubscribePage.description.stillReceiveEmails')}</Text>
            </Stack>
          </CardContent>
        </Card>
      </Div>
    );
  }
}

import { ClientError } from '../../../../common/helpers/errors';
import { getUuid } from '../../../../common/helpers/uuid/uuid';
import REQUEST_KEY from '../../../constants/requestKeys';
import { endLoading, updateCustomError } from '../../app/actions';
import * as constants from '../constants';

/**
 * Fetches the email preference center URL for a given user.
 *
 * This function dispatches an asynchronous action to retrieve the email preference center URL
 * for the specified user ID. It uses the Braze API to get the URL and handles any errors that
 * may occur during the process.
 *
 * @param {string} userId - The ID of the user for whom the email preference center URL is being fetched.
 * @returns {Object} An object containing action types and a promise that resolves to the email preference center URL.
 *
 * @throws {ClientError} Throws a ClientError if the request fails.
 */
export function getEmailPreferenceCenterUrl(userId: string) {
  return {
    types: [
      constants.GET_EMAIL_PREFERENCE_CENTER_URL,
      constants.GET_EMAIL_PREFERENCE_CENTER_URL_SUCCESS,
      constants.GET_EMAIL_PREFERENCE_CENTER_URL_FAIL
    ],
    promise: async (dispatch, getState, fetch) => {
      try {
        const {
          config: {
            braze: {
              apiKey: {
                getEmailPreferenceCenterUrl: getEmailPreferenceCenterUrlApiKey
              },
              baseUrl: { rest },
              emailPreferenceCenterId
            }
          }
        } = getState();

        const response = await fetch(
          `https://${rest}/preference_center/v1/${emailPreferenceCenterId}/url/${userId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${getEmailPreferenceCenterUrlApiKey}`
            }
          }
        );

        return response.preference_center_url;
      } catch (err) {
        const error = new ClientError('errors.defaultWithGuid', {
          guid: `ui-${getUuid()}`,
          responseBody: err instanceof Error ? err?.message : ''
        });
        updateCustomError(
          dispatch,
          REQUEST_KEY.UNSUBSCRIBE.GET_EMAIL_PREFERENCE_CENTER_URL,
          error
        );
        throw error;
      } finally {
        endLoading(
          dispatch,
          REQUEST_KEY.UNSUBSCRIBE.GET_EMAIL_PREFERENCE_CENTER_URL,
          true
        );
      }
    }
  };
}

import { TrpcRouter } from 'je-bff/src/handlers/bff';
import { ClientError } from '@common/helpers/errors';
import { inferRouterInputs } from '@trpc/server';
import { trpcBff } from '@trpcBff';

export type CreateJobParams =
  inferRouterInputs<TrpcRouter>['jobs']['actions']['create'];

export const useCreateJob = () => {
  const { mutateAsync, error, isSuccess } =
    trpcBff.jobs.actions.create.useMutation();

  return {
    createJobAsync: mutateAsync,
    error: getClientError(error),
    isSuccess
  };
};

const getClientError = (errorProperties) => {
  if (!errorProperties) return;

  return new ClientError('errors.defaultWithGuid', {
    guid: errorProperties.guid,
    responseBody: errorProperties.responseBody
  });
};

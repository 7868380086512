import React from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import { UpsellMaxAdCard } from './UpsellMaxAdCard';
import { UpsellPlusAdCard } from './UpsellPlusAdCard';

export type PremiumUpsellProps = {
  price: {
    [AD_TYPE.PLUS]: React.ReactNode;
    [AD_TYPE.MAX]: React.ReactNode;
  };
  adDuration?: {
    [AD_TYPE.PLUS]: number;
    [AD_TYPE.MAX]: number;
  };
  countryCode: string;
  jobId: string;
  campaignId: string;
};

export const PremiumUpsell = (props: PremiumUpsellProps) => {
  const { adDuration, price, countryCode, jobId } = props;

  return (
    <div className="flex flex-col gap-sm">
      <div className="flex flex-col md:flex-row lg:flex-row gap-sm">
        <div className="flex md:w-1/2 lg:w-1/2">
          <UpsellPlusAdCard
            adDuration={adDuration?.[AD_TYPE.PLUS]}
            price={price[AD_TYPE.PLUS]}
            countryCode={countryCode}
            jobId={jobId}
            campaignId={props.campaignId}
          />
        </div>
        <div className="flex md:w-1/2 lg:w-1/2">
          <UpsellMaxAdCard
            adDuration={adDuration?.[AD_TYPE.MAX]}
            price={price[AD_TYPE.MAX]}
            countryCode={countryCode}
            jobId={jobId}
            campaignId={props.campaignId}
          />
        </div>
      </div>
    </div>
  );
};

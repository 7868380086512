import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';

export const BackButton = ({ onPress }: { onPress: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="flex mt-[32px]">
      <Button variant="secondary" onPress={onPress}>
        {t('common.action.back')}
      </Button>
    </div>
  );
};

import { useJobServiceFetch } from '@src/fetchers';

interface DraftEvent {
  step: string;
  variant?: string;
  repostJobId?: string;
  version?: string | null;
}

export function useTrackDraftEvent() {
  const { fetch } = useJobServiceFetch();
  return ({ step, repostJobId, variant, version }: DraftEvent) => {
    fetch('caja-draft-events', {
      body: JSON.stringify({
        step,
        variant,
        repostJobId,
        version
      }),
      method: 'POST',
      credentials: 'include'
    });
  };
}

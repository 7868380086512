import { CAJA_VARIANTS, CajaVariant } from './constants';

export const isJseSgVariant = (variantName: CajaVariant) =>
  variantName === CAJA_VARIANTS.JSE_SG;

export const isJseDefaultVariant = (variantName: CajaVariant) =>
  variantName === CAJA_VARIANTS.JSE;

export const isJseVariant = (variantName: CajaVariant) =>
  isJseDefaultVariant(variantName) || isJseSgVariant(variantName);

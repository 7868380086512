import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { matchTitleRange } from '@src/roles';
import { Box } from '@components/Box';
import { Text } from '@components/Text';
import { Suggestion } from './AutoSuggest';
import { useAutoSuggestStyle } from './hooks';

export const NoSuggestionsList = ({
  noResultsText,
  testID
}: {
  noResultsText: string;
  testID?: string;
}) => {
  const resolvedStyles = useAutoSuggestStyle({ tone: 'success' });

  return (
    <View style={resolvedStyles.suggestionList}>
      <Box margin="small">
        <Text tone="neutralLight" testID={testID}>
          {noResultsText}
        </Text>
      </Box>
    </View>
  );
};

export const SuggestionList = ({
  suggestions,
  onSuggestionSelected,
  searchTerm,
  tone,
  testID
}: {
  suggestions: Suggestion[];
  onSuggestionSelected: (suggestion: Suggestion) => void;
  searchTerm: string | undefined;
  tone: 'neutral' | 'success' | 'danger' | 'successMuted';
  testID?: string;
}) => {
  const resolvedStyles = useAutoSuggestStyle({ tone });

  function renderSuggestionText(suggestionText: string) {
    const titleCasedSuggestionText = suggestionText;
    const highlightAt = matchTitleRange({
      suggestionText,
      searchTerm: searchTerm || ''
    });

    return (
      <Text>
        <Text>{titleCasedSuggestionText.slice(0, highlightAt.startIndex)}</Text>
        <Text weight="bold">
          {titleCasedSuggestionText.slice(
            highlightAt.startIndex,
            highlightAt.endIndex
          )}
        </Text>
        <Text>{titleCasedSuggestionText.slice(highlightAt.endIndex)}</Text>
      </Text>
    );
  }

  const renderSuggestionList = () => {
    return suggestions.map((item) => (
      <TouchableOpacity
        key={`${item.key}`}
        onPress={() => onSuggestionSelected(item)}
      >
        <Box padding="small" testID={`${testID}-${item.key}`}>
          {renderSuggestionText(item.displayText)}
        </Box>
      </TouchableOpacity>
    ));
  };

  return (
    <ScrollView style={resolvedStyles.suggestionList}>
      {renderSuggestionList()}
    </ScrollView>
  );
};

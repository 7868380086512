import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Button } from '@components/Button';
import { Heading } from '@components/Heading';
import { Text } from '@components/Text';
import {
  useWhatHappensNow,
  useWhatHappensNowActions
} from './useWhatHappensNow';

export const WhatHappensNowInfo = () => {
  const { t } = useTranslation();
  const { isSupportTier3 } = useWhatHappensNow();

  return (
    <div className="flex flex-col pt-[24px] p-lg mt-md mb-md bg-background-neutral">
      <Heading level="h2">
        {t('editJobSuccessPage.description.whatHappensNow')}
      </Heading>
      <div className="mt-md">
        {' '}
        <Text>{t('editJobSuccessPage.description.yourJobWillBeUpdated')}</Text>
      </div>

      {isSupportTier3 ? <SupportTier3Actions /> : <Actions />}
    </div>
  );
};

const Actions = () => {
  const { t } = useTranslation();
  const { redirectToHomePage, redirectToPostJobPage } =
    useWhatHappensNowActions();

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-md mt-md ">
      <Button onPress={redirectToPostJobPage}>
        {t('postJobSuccessPage.action.postAnotherJob')}
      </Button>

      <Button onPress={redirectToHomePage} variant="secondary">
        {t('common.action.backToHome')}
      </Button>
    </div>
  );
};

const SupportTier3Actions = () => {
  const { t } = useTranslation();
  const { redirectToHomePage } = useWhatHappensNowActions();

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-md mt-md">
      <Button
        data-testid="back-to-home"
        onPress={redirectToHomePage}
        variant="secondary"
      >
        {t('common.action.backToHome')}
      </Button>
    </div>
  );
};

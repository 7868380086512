import { useEffect, useRef } from 'react';
import { ensureUiVersion } from './ensureUiVersion';
import { useTrackConfirmEmailEvent } from './useTrackConfirmEmailEvent';
import { useTrackDraftEvent } from './useTrackDraftEvent';
import { useTrackJobEvent } from './useTrackJobEvent';

interface DraftEvent {
  step: string;
  variant?: string;
  jobId?: string;
  repostJobId?: string;
  campaign?: string;
}

/* Tracks an event across either the draft or the job
 * When the job exists, provide the jobId and step.
 * Before a job exists, provide the step, repostJobId (if applicable), and variant (where appropriate)
 */
export function useTrackCajaEvent({
  step,
  variant,
  campaign,
  jobId,
  repostJobId
}: DraftEvent) {
  const trackDraftEvent = useTrackDraftEvent();
  const trackJobEvent = useTrackJobEvent();
  const trackConfirmEmailEvent = useTrackConfirmEmailEvent();
  const trackDraftEventRef = useRef(trackDraftEvent);
  const trackJobEventRef = useRef(trackJobEvent);
  const trackConfirmEmailEventRef = useRef(trackConfirmEmailEvent);
  const ensureUiVersionRef = useRef(ensureUiVersion);

  useEffect(() => {
    if (step === 'createAccount') return;

    if (step === 'verifyEmail') {
      trackConfirmEmailEventRef.current();
      return;
    }
    ensureUiVersionRef.current().then((version) => {
      if (jobId) {
        trackJobEventRef.current({ step, jobId, version, campaign, variant });
      } else {
        trackDraftEventRef.current({ step, variant, repostJobId, version });
      }
    });
  }, [step, variant, campaign, jobId, repostJobId]);
}

import React from 'react';
import clsx from 'clsx';
import { WebRadioButton as RadioButton } from '@components/FormElements/RadioButton';

export type Props = {
  header?: React.ReactNode;
  content?: React.ReactNode;
  checked?: boolean;
  onPress: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  testID?: string;
  disabled?: boolean;
};

export const RadioCard = (props: Props) => {
  const { header, content, ...radioButtonProps } = props;

  return (
    <div
      onClick={radioButtonProps.disabled ? undefined : radioButtonProps.onPress}
      aria-disabled={radioButtonProps.disabled}
      className={clsx(
        'flex flex-col p-md border',
        radioButtonProps.checked && 'border-success',
        !radioButtonProps.checked && 'border-grey200',
        radioButtonProps.disabled && 'opacity-50 cursor-not-allowed',
        !radioButtonProps.disabled && 'cursor-pointer active:border-success '
      )}
    >
      <div className="flex flex-row gap-md h-10">
        <RadioButton {...radioButtonProps} />
        {header}
      </div>

      {content}
    </div>
  );
};

import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { useTranslation } from '@common/hooks/useTranslation';
import { Field } from '@components/FormElements';
import { WebDropdown as Dropdown } from '@components/FormElements/Dropdown';
import { options } from './options';

type Props = {
  value?: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  onChange: (key: string) => void;
  error?: string;
};

export const VideoScreeningQuestionsField = ({
  value,
  label,
  disabled,
  onChange,
  error
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 z-10">
      <Field
        label={label || t('postJobForm.fieldLabel.videoScreeningQuestion')}
        message={
          error && (
            <div className="mt-sm">
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          )
        }
      >
        <Dropdown
          disabled={disabled}
          placeholder={t('postJobForm.videoScreeningQuestions.placeholder')}
          items={options(t)}
          selectedItem={value}
          onChange={onChange}
          testID="job-form-video-screening-questions"
        />
      </Field>
    </div>
  );
};

import { AD_TYPE } from '@seek/je-shared-data';

export type UpgradeableAdType = AD_TYPE.PLUS | AD_TYPE.MAX;

/**
 * Determines if an ad is upgraded.
 * @param adType - The type of the ad.
 * @returns A boolean indicating whether the ad is upgraded.
 */
export const upgraded = (
  adType: AD_TYPE | undefined
): adType is UpgradeableAdType =>
  adType !== undefined && [AD_TYPE.MAX, AD_TYPE.PLUS].includes(adType);

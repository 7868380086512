import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { Application } from '@common/types';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';

export type CitizenshipFilterProps = {
  applications: Application[];
  onChange: (value: string[]) => void;
  onClear: () => void;
  value?: string[];
};

export const CitizenshipFilter = ({
  applications = [],
  value = [],
  onChange,
  onClear
}: CitizenshipFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: string) => {
    const newValue = value.includes(checkboxValue)
      ? value.filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];

    newValue.length ? onChange(newValue) : onClear();
  };

  return (
    <div className="space-y-md">
      <div className="flex flex-row justify-between">
        <div className="text-md">
          {t('applications.applicationsFilter.citizenship.title')}
        </div>
        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <div className="flex flex-wrap">
        {options(applications).map(({ key, label, value: optionValue }) => (
          <div className="w-full md:w-1/2 mb-md">
            <Checkbox
              key={key}
              value={optionValue ? value.includes(optionValue) : false}
              label={label}
              onChange={() => optionValue && handleCheckbox(optionValue)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

/**
 * Generates an array of options for citizenship filters based on the provided profile applications.
 *
 * @param {Application[]} applications - An array of application objects.
 * @returns {Array<{ key: string, value: string, label: string }>} An array of objects representing citizenship options with their counts.
 *
 * Each object in the returned array contains:
 * - `key`: The citizenship value.
 * - `value`: The citizenship value.
 * - `label`: A string combining the citizenship value and the count of occurrences in the format "citizenship (count)".
 */
export const options = (applications: Application[]) => {
  const counts: {
    [countryCode: string]: { count: number; countryName: string };
  } = applications.reduce(
    (acc, { citizenship, citizenshipCountryCode: countryCode }) => {
      if (!countryCode) return acc;

      return {
        ...acc,
        [countryCode]: {
          count: (acc[countryCode]?.count || 0) + 1,
          countryName: citizenship
        }
      };
    },
    {}
  );

  return Object.entries(counts).map(([countryCode, option]) => ({
    key: countryCode,
    value: countryCode,
    label: `${option.countryName} (${option.count})`
  }));
};

import React from 'react';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { useTranslation } from '@common/hooks/useTranslation';
import { HeaderInfo } from './HeaderInfo';
import { WhatHappensNextInfo } from './WhatHappensNextInfo';
import { isBankTransfer } from './isBankTransfer';

// Payment success step for enhance job flow.
export const PaymentSuccess = () => {
  useRedirectUnauthenticatedUser();

  const { t } = useTranslation();
  const { getQueryParam } = useSearchQueryParam();
  const paymentMethod = getQueryParam('paymentMethod');

  return (
    <div>
      <JoraEmployerHelmet title={t('paymentSuccessPage.title')} />
      <HeaderInfo />

      <WhatHappensNextInfo
        isBankTransfer={isBankTransfer(paymentMethod as string)}
      />
    </div>
  );
};

import { T } from '@common/hooks/useTranslation';
import { ImmediateStartPhrase } from '../types';

export const getImmediateStartSummary = (
  immediateStartPhrase: ImmediateStartPhrase,
  t: T
) => {
  if (immediateStartPhrase === ImmediateStartPhrase.URGENT_HIRING) {
    return t('postJobForm.summary.urgentHire');
  }

  return t('postJobForm.summary.immediateStart');
};

import React from 'react';
import { useBusinessRules } from '@common/components/Business/rules';
import { BusinessWebsite as InnerField } from '@common/components/BusinessForm/components/BusinessWebsite';
import { FieldWrapper } from '../FieldWrapper';

type Props = { helperText: string };

export default function BusinessWebsite(props: Props) {
  const { businessWebsiteRules } = useBusinessRules();
  return (
    <FieldWrapper<string>
      name="website"
      rules={businessWebsiteRules}
      render={(renderProps) => {
        const propsToPass = {
          ...renderProps,
          ...props
        };
        return <InnerField {...propsToPass} />;
      }}
    />
  );
}

import React, { useState } from 'react';
import {
  StepError,
  StepLoader,
  StepWrapper
} from '@common/components/Caja/components';
import {
  EnhanceJob as EnhanceJobForm,
  OnSubmitValues
} from '@common/components/Job/EnhanceJob/EnhanceJobWithCurrency';
import { usePatchPremiumFields } from '@common/hooks/job/usePatchJob';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { basicOrUndefined, upgraded } from '@src/job';
import { Heading } from '@components/Heading';
import { useMapJobToFormData } from './hooks';
import { toJob as formToJob } from './transformers/form/toJob';

type Props = Pick<
  TopLevelStep<{
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    initialAdType?: AD_TYPE;
    disableAdTypeSelector?: boolean;
    onNextText?: string;
    hideBackButton?: boolean;
    hideBasicAdType?: boolean;
  }>,
  'onCompleted' | 'onBack' | 'stepInfo'
>;

export function EnhanceJob(props: Props) {
  useRedirectUnauthenticatedUser();

  const {
    stepInfo: {
      jobId,
      jobCountryCode,
      initialAdType,
      disableAdTypeSelector = false,
      onNextText,
      hideBackButton,
      hideBasicAdType
    },
    onBack,
    onCompleted
  } = props;
  const { t } = useTranslation();

  const { formData, isLoading: jobLoading } = useMapJobToFormData(jobId);
  const { patchJobData, error: patchJobError } = usePatchPremiumFields();

  const [jobAdType, setJobAdType] = useState<AD_TYPE>(
    basicOrUndefined(initialAdType) ? AD_TYPE.PLUS : initialAdType!
  );

  const onSubmit = async (data?: OnSubmitValues) => {
    if (upgraded(jobAdType)) await patchJobData(formToJob(jobId, data!!));
  };

  const onNext = () => {
    return onCompleted({
      jobAdType
    });
  };

  const onChangeAdType = (newAdType: AD_TYPE) => {
    if (disableAdTypeSelector) return;

    setJobAdType(newAdType);
  };

  if (jobLoading) return <StepLoader />;

  return (
    <StepWrapper>
      <div className="pb-[12px]">
        <Heading level="h2" weight="regular">
          {t('enhanceJobStep.contentHeading')}
        </Heading>
      </div>

      <EnhanceJobForm
        defaultValues={formData}
        jobCountryCode={jobCountryCode}
        onBack={onBack}
        onNext={onNext}
        onSubmit={onSubmit}
        onChangeAdType={onChangeAdType}
        selectedAdType={jobAdType}
        disableSelector={disableAdTypeSelector}
        onNextText={onNextText}
        hideBackButton={hideBackButton}
        hideBasicAdType={hideBasicAdType}
      />

      <StepError error={patchJobError} />
    </StepWrapper>
  );
}

import { useEffect } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { useFetchAdPrices } from '@common/hooks/payment';
import { useGetCurrencyPrice } from '@common/hooks/upgradeJobAd';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

export function useLoadAdPrices(jobCountryCode: SupportedCountryCode) {
  const { getAdPrices } = useFetchAdPrices();
  const pricingText = useGetCurrencyPrice({ jobCountryCode });

  useEffect(() => getAdPrices(jobCountryCode), [getAdPrices, jobCountryCode]);

  return {
    data: pricingText,
    errorKey: REQUEST_KEY.PAYMENT.GET_AD_PRICES
  };
}

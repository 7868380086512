import { useHistory } from 'react-router-dom';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

type UseRedirectUnauthenticatedUserProps = {
  to?: string;
};

export function useRedirectUnauthenticatedUser(
  props?: UseRedirectUnauthenticatedUserProps
) {
  const { to } = props || {};
  const { isAuthenticated } = useAppSelector(getUser);
  const { countryCode } = useAppSelector(getLocalisation);
  const history = useHistory();

  if (!isAuthenticated) {
    history.push(to || `/${countryCode}/`);
  }
}

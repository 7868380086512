import { formatStringToTitleCase } from '@common/helpers/textFormatting';
import { matchTitleSearch } from '@src/roles';
import { Suggestion } from '@components/FormElements/AutoSuggest';
import { JobTitle } from '../types';

const toAutoSuggestValue = (
  { jobTitle, role }: JobTitle,
  index: number
): Suggestion => ({
  key: `${index}`,
  displayText: formatStringToTitleCase(jobTitle),
  data: {
    jobTitle: formatStringToTitleCase(jobTitle),
    role
  }
});

export const filterAndFormatJobTitles = (
  jobTitles: JobTitle[],
  searchTerm: string
): Suggestion[] => {
  return jobTitles
    .filter(({ jobTitle }) => matchTitleSearch({ title: jobTitle, searchTerm }))
    .map(toAutoSuggestValue);
};

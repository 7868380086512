import { CAJA_VARIANTS } from '../../hooks';
import { CajaVariant } from '../../variants';
import { EnhanceJob as EnhanceJobWithCoins } from './default_jse';
import { EnhanceJob as EnhanceJobWithCurrency } from './default_v2';
import { EnhanceJob as EnhanceJobWithCoinsAndTargetLocation } from './jse_sg';

type Params = {
  cajaVariant: CajaVariant;
};

export const enhanceJobRouter = (param: Params) => {
  if (param.cajaVariant === CAJA_VARIANTS.JSE_SG) {
    return EnhanceJobWithCoinsAndTargetLocation;
  }

  if (param.cajaVariant === CAJA_VARIANTS.JSE) {
    return EnhanceJobWithCoins;
  }

  return EnhanceJobWithCurrency;
};

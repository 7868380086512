import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RequestUpgrade,
  RequestUpgradeConfirmation
} from '@common/components/Account/components/UpgradeAccountModal/components';
import { Else, If, Then } from '@common/components/If';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRequestUpgradeAccountCallback } from '@common/hooks/orgs/useRequestUpgradeAccountCallback';
import { useTrackContactMeButtonClicked } from '@src/joraEmployer/pages/account/hooks/useTrackContactMeClicked/useTrackContactMeClicked';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';
import { ChevronLeftIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { Modal } from '@components/Modal';
import { Stack } from '@components/Stack';

type UpgradeAccountContentProps = {
  onRequestClose: () => void;
  pageTitle: string;
  toggleModalState: () => void;
  visible: boolean;
};
export const UpgradeAccountContent = ({
  onRequestClose,
  pageTitle,
  toggleModalState,
  visible
}: UpgradeAccountContentProps) => {
  const { t } = useTranslation();
  const {
    currentUser: { mobile, email }
  } = useAppSelector(getUser);
  const brandNameText = useBrandNameText();
  const trackContactMeClicked = useTrackContactMeButtonClicked();
  const { requestUpgradeAccountCallback } = useRequestUpgradeAccountCallback();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleOnSubmit = () => {
    requestUpgradeAccountCallback({
      page: `${pageTitle} | Filters Modal | ${brandNameText}`,
      source: 'filters_modal'
    });
    trackContactMeClicked();
    setSubmitted(true);
  };

  return (
    <Modal
      visible={visible}
      title={t('applications.applicationsFilter.action.filter')}
      height="fit-content"
      testID="applications-filter-modal"
      onRequestClose={onRequestClose}
    >
      <Stack spacing="medium">
        <Link
          icon={<ChevronLeftIcon size="small" tone="info" variant="outline" />}
          onPress={toggleModalState}
        >
          {t('common.action.back')}
        </Link>

        <If condition={submitted}>
          <Then>
            <RequestUpgradeConfirmation
              mobile={mobile}
              email={email}
              onComplete={toggleModalState}
            />
          </Then>
          <Else>
            <RequestUpgrade
              mobile={mobile}
              email={email}
              onSubmit={handleOnSubmit}
            />
          </Else>
        </If>
      </Stack>
    </Modal>
  );
};

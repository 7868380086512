import { BrandName } from '@seek/je-shared-data/lib/types/brand';

/**
 * Determines whether to show the benefit for job featured for x days promo.
 *
 * @param {BrandName} params.brand - The brand name.
 * @returns {boolean} Returns true if the benefit should be shown, otherwise
 * false.
 */
export const showJobFeaturedForXDaysPromo = ({
  brand
}: {
  brand: BrandName;
}): boolean => {
  if (brand === BrandName.JOBSTREETEXPRESS) return true;

  return false;
};

// THIS IS GENERIC ENOUGH TO MOVE ELSEWHERE
import { useFormFieldError } from './useFormFieldError';
import { useGlobalFormError } from './useGlobelFormError';

export function useErrorHandler(errorMap: Record<string, string>) {
  const { isFieldError, setFieldLevelError } = useFormFieldError(errorMap);
  const setGlobalFormError = useGlobalFormError();

  return {
    isFieldError,
    setFieldLevelError,
    setGlobalFormError
  };
}

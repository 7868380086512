import { StepPathInfo } from '@common/hooks/multiStep/types';
import { AD_TYPE } from '@seek/je-shared-data';
import { upgraded } from '@src/job';
import { PostJobStepIds, stepConfig } from './stepConfig';

export type GetStepsParams = {
  createdJobId?: string | null;
  isUpgradeable: boolean;
  hasJobUpgraded: boolean;
  selectedAdType?: AD_TYPE;
};

export const getSteps = (
  params: GetStepsParams
): StepPathInfo<PostJobStepIds>[] => {
  const { isUpgradeable, createdJobId, selectedAdType, hasJobUpgraded } =
    params;

  const {
    title,
    basicDetails,
    editTitle,
    editBasicDetails,
    postJobSuccess,
    enhanceJob,
    payment,
    jobSummaryAndDesc
  } = stepConfig(createdJobId, selectedAdType);

  const postJobSteps = [title, basicDetails];
  const editJobSteps = [editTitle, editBasicDetails];

  const showPayment =
    isUpgradeable && upgraded(selectedAdType) && !hasJobUpgraded;

  return [
    ...(createdJobId ? editJobSteps : postJobSteps),
    ...(isUpgradeable ? [enhanceJob] : []),
    ...(showPayment ? [payment] : []),
    jobSummaryAndDesc,
    postJobSuccess
  ];
};

import { StepPathInfo } from '../types';

type StepIndicatorInfo<StepIds extends string> = {
  [key in StepIds]: { ignoreIndicator?: boolean };
};

export const getTotalStepsForIndicator = <StepIds extends string>(
  steps: StepPathInfo<StepIds>[],
  stepsIndicatorInfo: StepIndicatorInfo<StepIds>
) =>
  Object.keys(steps)
    .map((key) => steps[key])
    .filter((step) => !stepsIndicatorInfo[step.id]?.ignoreIndicator).length;

import { useTranslation } from '@common/hooks/useTranslation';
import { WorkExperience as WorkExperienceEnum } from '@seek/je-shared-data/lib/types/jobs';

type WorkExperience = {
  defaultOptions: { key: WorkExperienceEnum; label: string }[];
  optionsV2: { key: WorkExperienceEnum; label: string }[];
};

export const useWorkExperience = (): WorkExperience => {
  const { t } = useTranslation();
  const defaultOptions = [
    {
      key: WorkExperienceEnum.NO_EXPERIENCE,
      label: t('postJobForm.workExperience.noExperience')
    },
    {
      key: WorkExperienceEnum.ONE_YEAR_EXPERIENCE,
      label: t('postJobForm.workExperience.oneYearExperience')
    },
    {
      key: WorkExperienceEnum.TWO_TO_THREE_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperience.twoToThreeYearsExperience')
    },
    {
      key: WorkExperienceEnum.MORE_THAN_FOUR_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperience.moreThanFourYearsExperience')
    }
  ];

  const optionsV2 = [
    {
      key: WorkExperienceEnum.NO_EXPERIENCE,
      label: t('postJobForm.workExperienceV2.noExperience')
    },
    {
      key: WorkExperienceEnum.ONE_YEAR_EXPERIENCE,
      label: t('postJobForm.workExperienceV2.oneYearExperience')
    },
    {
      key: WorkExperienceEnum.TWO_TO_THREE_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperienceV2.twoToThreeYearsExperience')
    },
    {
      key: WorkExperienceEnum.MORE_THAN_FOUR_YEARS_EXPERIENCE,
      label: t('postJobForm.workExperienceV2.moreThanFourYearsExperience')
    }
  ];

  return {
    defaultOptions,
    optionsV2
  };
};

import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { colors } from '@theme/tokens/colors';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type TextAreaTone = 'neutral' | 'success' | 'danger' | 'successMuted';

type TextAreaStyleProps = {
  tone?: TextAreaTone;
};

export function useTextAreaStyle({
  tone = 'neutral',
  lines = 5
}: TextAreaStyleProps & { lines?: number }) {
  const { formElement, spacing, border } = useTheme();
  const { resolveToken } = useResolveToken();
  const { container, color: formElementColors } = formElement;
  const { borderColor } = formElementColors[tone];

  return {
    container: {
      maxWidth: container.maxWidth
    },
    textInputField: {
      paddingTop: resolveToken(spacing, container.paddingTop),
      paddingBottom: resolveToken(spacing, container.paddingBottom),
      paddingHorizontal: resolveToken(spacing, container.paddingHorizontal),
      borderWidth: resolveToken(border.width, container.borderWidth),
      borderStyle: container.borderStyle,
      borderRadius: resolveToken(spacing, container.borderRadius),
      borderColor,
      height: lines * 30,
      outlineStyle: 'none',
      cursor: container.cursor[tone]
    },
    placeholder: {
      color: resolveToken(colors, 'grey500')
    }
  } as const;
}

export function useTextStyle({ tone = 'neutral' }: TextAreaStyleProps) {
  const textStyleValues = {
    size: 'standard',
    tone: tone,
    weight: 'light'
  } as const;

  const {
    typography: {
      fontFamily,
      text,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacing
    },
    formElement
  } = useTheme();
  const { resolveToken } = useResolveToken();
  const { color: formElementColors } = formElement;
  const { fontColor } = formElementColors[tone];

  const {
    size: textSize,
    lineHeight: textLineHeight,
    letterSpacing: textLetterSpacing
  } = resolveToken(text, textStyleValues.size) || {};

  return {
    fontFamily,
    fontSize: resolveToken(fontSizes, textSize),
    lineHeight: resolveToken(lineHeights, textLineHeight),
    fontWeight: resolveToken(fontWeights, textStyleValues.weight),
    color: fontColor,
    letterSpacing: resolveToken(letterSpacing, textLetterSpacing)
  };
}

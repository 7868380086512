import { getQueryKey } from '@trpc/react-query';
import { trpcBff } from '@trpcBff';
import { UseFindRecentDraftProps } from './useFindRecentDraft';

export const findRecentDraftKey = ({
  repostJobId
}: UseFindRecentDraftProps) => {
  return getQueryKey(
    trpcBff.jobs.drafts.findRecent,
    {
      repostJobId
    },
    'query'
  );
};

import type { T } from '@common/hooks/useTranslation';
import { ShiftAvailability } from '@seek/je-shared-data/lib/types/jobs';

export function shiftAvailabilityRules(t: T) {
  return {
    validate: (value: ShiftAvailability | undefined) => {
      if (!value) {
        return t('validations.job.shiftAvailability.required');
      }

      if (value.type === 'SPECIFIC') {
        if (!value.specificShiftAvailability) {
          return t('validations.job.shiftAvailability.required');
        }

        const shiftAvailabilities = Object.values(
          value.specificShiftAvailability
        ).flat(Infinity);
        if (shiftAvailabilities.length === 0) {
          return t('validations.job.shiftAvailability.required');
        }
      }

      return;
    }
  };
}

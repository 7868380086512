import { StepPathInfo } from '@common/hooks/multiStep/types';
import { AD_TYPE } from '@seek/je-shared-data';
import { upgraded } from '@src/job';
import { EnhanceJobStepIds, stepConfig } from './stepConfig';

export type GetStepsParams = {
  selectedAdType?: AD_TYPE;
  hasJobUpgraded: boolean;
};

export const getSteps = (
  params: GetStepsParams
): StepPathInfo<EnhanceJobStepIds>[] => {
  const { selectedAdType, hasJobUpgraded } = params;

  const { enhanceJob, payment, paymentSuccess } = stepConfig(selectedAdType);

  return [
    enhanceJob,
    ...(upgraded(selectedAdType) && !hasJobUpgraded ? [payment] : []),
    paymentSuccess
  ];
};

import { useTranslation } from '@common/hooks/useTranslation';
import { CurrencySymbols } from '@seek/je-shared-data/lib/types/brand';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';
import { SalaryRate } from '@seek/je-shared-data/lib/types/jobs';
import { ExactSalary, RangeSalary } from '@seek/je-shared-data/lib/types/jobs';

export type ExactSalaryWithOptionalAmount = ExactSalary & {
  amount?: ExactSalary['amount'];
};
export type RangeSalaryWithOptionalAmount = RangeSalary & {
  amount?: RangeSalary['amount'];
};

export function isExactSalaryInput(
  salary: SalaryWithOptionalAmount
): salary is ExactSalaryWithOptionalAmount {
  return salary.type === 'exact';
}

export function isRangeSalaryInput(
  salary: SalaryWithOptionalAmount
): salary is RangeSalaryWithOptionalAmount {
  return salary.type === 'range';
}

export type SalaryWithOptionalAmount = Omit<Salary, 'amount'> & {
  amount?: Partial<RangeSalary['amount']> | ExactSalary['amount'];
};

export type useExpectedSalaryProps = {
  value: SalaryWithOptionalAmount;
  onChange: (value: SalaryWithOptionalAmount) => void;
};

const mapSalaryRateToText = (rate: Salary['rate']) => {
  switch (rate) {
    case SalaryRate.HOURLY:
      return 'hour';
    case SalaryRate.DAILY:
      return 'day';
    case SalaryRate.WEEKLY:
      return 'week';
    case SalaryRate.MONTHLY:
      return 'month';
    case SalaryRate.YEARLY:
      return 'year';
    default:
      return '';
  }
};

export const useExpectedSalary = ({
  value,
  onChange
}: useExpectedSalaryProps) => {
  const { t } = useTranslation();

  const onSalaryByChange = (type: Salary['type']) => {
    onChange({ ...value, type, amount: undefined });
  };

  const onExactAmountChange = (amount?: ExactSalary['amount']) => {
    onChange({ ...value, amount });
  };

  const onRangeAmountChange = (amount?: Partial<RangeSalary['amount']>) => {
    onChange({ ...value, amount });
  };

  const onRateChange = (rate: Salary['rate']) => {
    onChange({ ...value, rate });
  };

  const onCurrencyChange = (currency: Salary['currency']) => {
    onChange({ ...value, currency });
  };

  const currencySymbol = CurrencySymbols[value.currency];

  const renderCalloutText = () => {
    const rateText = t(
      `postJobForm.expectedSalary.rate.${mapSalaryRateToText(value.rate)}`
    );

    if (isExactSalaryInput(value)) {
      const amountText = value?.amount
        ? value?.amount.toLocaleString('en-US')
        : '[Total]';

      return t('postJobForm.expectedSalary.calloutText.exact', {
        currencySymbol: currencySymbol,
        totalAmount: amountText,
        rate: rateText
      });
    }

    if (isRangeSalaryInput(value)) {
      const minAmountText = value?.amount?.from
        ? value?.amount?.from.toLocaleString('en-US')
        : '[Min]';
      const maxAmountText = value?.amount?.to
        ? value?.amount?.to.toLocaleString('en-US')
        : '[Max]';

      return t('postJobForm.expectedSalary.calloutText.range', {
        currencySymbol: currencySymbol,
        minAmount: minAmountText,
        maxAmount: maxAmountText,
        rate: rateText
      });
    }

    return '';
  };

  return {
    onSalaryByChange,
    onExactAmountChange,
    onRangeAmountChange,
    onRateChange,
    onCurrencyChange,
    currencySymbol,
    calloutText: renderCalloutText()
  };
};

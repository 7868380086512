import { useEffect, useState } from 'react';
import { ClientError } from '@common/helpers/errors';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { useGetBusiness } from '../useGetBusiness';

export type LoadBusiness = {
  isLoading: boolean;
  data?: BusinessWithUsers;
  error?: ClientError;
};

export const useLoadBusiness = (businessId?: string): LoadBusiness => {
  const { fetchBusiness, data, error } = useGetBusiness(businessId);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        await fetchBusiness();
      } finally {
        setIsLoading(false);
      }
    })();
  }, [fetchBusiness]);

  return {
    isLoading,
    data,
    error
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

export const PremiumFeaturesBadge = () => {
  const { t } = useTranslation();

  return (
    <div
      className="
        bg-purple500
        text-white
        font-light
        me-2
        px-sm
        py-sm
        leading-4
        text-sm
        rounded
        whitespace-nowrap
      "
    >
      {t('badge.premiumFeatures')}
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application, HighestEducation } from '@common/types';
import { Checkbox } from '@components/FormElements';
import { ClearLink } from '../ClearLink';

export type EducationFilterProps = {
  applications: Application[];
  onChange: (value: HighestEducation[]) => void;
  onClear: () => void;
  value?: HighestEducation[];
};

export const EducationFilter = ({
  applications,
  value = [],
  onChange,
  onClear
}: EducationFilterProps) => {
  const { t } = useTranslation();

  const handleCheckbox = (checkboxValue: HighestEducation) => {
    const newValue = value.includes(checkboxValue)
      ? value.filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];

    newValue.length ? onChange(newValue) : onClear();
  };

  return (
    <div className="space-y-md">
      <div className="flex flex-row justify-between">
        <div className="text-md">
          {t('applications.applicationsFilter.education.title')}
        </div>
        <ClearLink onClear={onClear} active={Boolean(value.length)} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(({ key, label, value: optionValue }) => (
          <div className="w-full md:w-1/2 mb-md">
            <Checkbox
              key={key}
              value={value.includes(optionValue)}
              label={label}
              onChange={() => handleCheckbox(optionValue)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const options = (applications: Application[], t) => {
  const counts = applications.reduce((acc, { highestEducation }) => {
    if (highestEducation) {
      acc[highestEducation] = (acc[highestEducation] || 0) + 1;
    }
    return acc;
  }, {});

  return Object.entries(counts).map(([education, count]) => ({
    key: education,
    value: education as HighestEducation,
    label: `${t(
      `applications.applicationCard.aboutMe.highestEducation.${education}`
    )} (${count})`
  }));
};

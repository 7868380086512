import { EventTypes } from 'redux-segment';
import { type RightToWork } from '@common/constants/data';
import { ApplicationStatus, HighestEducation } from '@common/types';
import { WorkExperience } from '@seek/je-shared-data/lib/types/jobs';
import { ApplicationSortValue } from '@src/joraEmployer/pages/job/details/components/JobApplicationDetails/types';
import * as consts from './constants';

export type TrackApplicationFiltersModalOpenedProps = {
  userIsViewingApplicantsType: 'matched' | 'other';
};

export function trackApplicationFiltersModalOpened({
  userIsViewingApplicantsType
}: TrackApplicationFiltersModalOpenedProps) {
  const event = {
    type: consts.APPLICATION_FILTERS_MODAL_OPENED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application filters modal opened',
          properties: {
            userIsViewingApplicantsType
          }
        }
      }
    }
  };
  return event;
}

export function trackApplicationFiltersModalCleared() {
  const event = {
    type: consts.APPLICATION_FILTERS_CLEARED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application filters cleared'
        }
      }
    }
  };
  return event;
}

export type TrackApplicationFiltersAppliedProps = {
  sortBy: ApplicationSortValue;
  userPackageType?: 'paid' | 'free'; // whether user has any premium package
  userHasPremiumFilters?: boolean; // whether user has package that allows for premium filters usage
  applicantStatusFilter?: ApplicationStatus[];
  citizenshipFilter?: string[];
  distanceFilter?: number;
  educationFilter?: HighestEducation[];
  locationFilter?: { [country: string]: string }[]; // e.g: [{ "MY": "Foo, Bar" }, { "MY": "Bar, Baz" }, { "ID": "Zip, Zap" }]
  rightToWorkFilter?: RightToWork[];
  videoScreeningFilter?: boolean;
  workExperienceFilter?: WorkExperience[];
};

export function trackApplicationFiltersApplied(
  props: TrackApplicationFiltersAppliedProps
) {
  const event = {
    type: consts.APPLICATION_FILTERS_APPLIED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Application filters applied',
          properties: {
            userPackageType: props.userPackageType || 'free',
            userHasPremiumFilters: props.userHasPremiumFilters || false,
            applicantStatusFilter: props.applicantStatusFilter || null,
            citizenshipFilter: props.citizenshipFilter || null,
            distanceFilter: props.distanceFilter
              ? `${props.distanceFilter}`
              : '100+km',
            educationFilter: props.educationFilter || null,
            videoScreeningFilter: props.videoScreeningFilter || false,
            rightToWorkFilter: props.rightToWorkFilter || null,
            locationFilter: props.locationFilter || null,
            sortBy: props.sortBy
          }
        }
      }
    }
  };
  return event;
}

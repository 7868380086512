import { cleanTitleForComparison } from './cleanTitleForComparison';

interface MatchTitleRange {
  suggestionText: string;
  searchTerm: string;
}
interface MatchRange {
  startIndex: number;
  endIndex: number;
}

export function matchTitleRange({
  suggestionText,
  searchTerm
}: MatchTitleRange): MatchRange {
  if (!suggestionText) return { startIndex: 0, endIndex: 0 };
  if (!searchTerm) return { startIndex: 0, endIndex: 0 };

  const startIndex = cleanTitleForComparison(suggestionText).indexOf(
    cleanTitleForComparison(searchTerm)
  );
  if (startIndex === -1) return { startIndex: 0, endIndex: 0 };

  const endIndex = startIndex + searchTerm.length;

  return { startIndex, endIndex };
}

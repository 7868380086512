import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';

export function useWhatHappensNextActions() {
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return {
    redirectToPostJobPage: redirect(postJobLink),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}

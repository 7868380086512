import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSiteDomain } from '@store/config';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';
import { getAlternateHrefLangList } from './helper/links';

type JoraEmployerHelmetProps = {
  title?: string;
};

export const JoraEmployerHelmet = ({ title }: JoraEmployerHelmetProps) => {
  const {
    brandConfig: { name: brandName, validCountryCodes } = {},
    brandNameText
  } = useSelector(getLocalisation);
  const siteDomain = useAppSelector(getSiteDomain(brandName));
  const { pathname } = useLocation();

  const children = [
    title ? <title key="title">{title}</title> : null,
    <link key="link" rel="canonical" href={`${siteDomain}${pathname}`} />,
    getAlternateHrefLangList({
      validCountryCodes,
      pathname,
      siteDomain
    }).map(({ href, hrefLang }) => (
      <link key={href} rel="alternate" href={href} hrefLang={hrefLang} />
    ))
  ];

  return (
    <Helmet
      defaultTitle={`${brandNameText}`}
      titleTemplate={`%s | ${brandNameText}`}
    >
      {children}
    </Helmet>
  );
};

import React from 'react';
import { useAvailabilityDisplay } from '@common/hooks/useAvailibilityDisplay';
import { useTranslation } from '@common/hooks/useTranslation';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import {
  AllSpecificAvailabilityType,
  OnAllCheckboxPressedType
} from '../types';

type Props = {
  currentSelectedOptions: AllSpecificAvailabilityType;
  onChange: (value: OnAllCheckboxPressedType) => void;
};

export const AllShiftsAvailabilityCheckboxes = ({
  currentSelectedOptions,
  onChange
}: Props) => {
  const { t } = useTranslation();
  const { availabilityLabel } = useAvailabilityDisplay();

  return (
    <Row width="full" testID="all-shift-availability-checkboxes">
      <Column width="1/4">
        <Text size="small">{t('postJobForm.shiftAvailability.all')}</Text>
      </Column>

      {availabilityLabel.map(({ value }) => (
        <Column width="1/4" alignItems="center" key={`${value}-all`}>
          <Checkbox
            testID={`${value}-all-checkbox`}
            onChange={(isSelected) =>
              onChange({
                isSelected,
                availability: value
              })
            }
            value={currentSelectedOptions[value]}
          />
        </Column>
      ))}
    </Row>
  );
};

import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { CrossIcon } from '../Icon/icons/CrossIcon';

export interface PillToggleProps {
  label: string;
  checked: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  testId?: string;
}

export const PillToggle = forwardRef<HTMLButtonElement, PillToggleProps>(
  ({ label, checked, icon, disabled = false, onChange, testId }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        role="checkbox"
        aria-checked={checked}
        disabled={disabled}
        data-testid={testId}
        onClick={() => onChange?.(!checked)}
        className={clsx(
          'group flex items-center gap-4 rounded-full border px-6 py-4 text-sm text-gray-900 leading-4',
          'focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2',
          checked
            ? [
                'border-green-700 bg-green-50',
                !disabled && 'hover:bg-green-100'
              ]
            : ['border-gray-300 bg-white', !disabled && 'hover:bg-gray-50'],
          disabled && 'opacity-50 cursor-not-allowed'
        )}
      >
        {icon && icon}
        <span>{label}</span>
        {checked && (
          <CrossIcon
            size="xxsmall"
            tone="neutralLight"
            variant="outline"
            testID="cross-icon"
          />
        )}
      </button>
    );
  }
);

PillToggle.displayName = 'PillToggle';

import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { SingleSelect } from '@common/components/FormElements';
import { useTranslation } from '@common/hooks/useTranslation';
import { WorkExperience as WorkExperienceEnum } from '@seek/je-shared-data/lib/types/jobs/jobs';
import { Box } from '@components/Box';
import { Field } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { useWorkExperience } from './hooks/useWorkExperience';

type WorkExperienceProps = {
  value: WorkExperienceEnum | undefined;
  onChange: (key: string) => void;
  error?: string;
  label?: string;
  options?: { key: WorkExperienceEnum; label: string }[];
};

export const WorkExperienceField = ({
  value,
  onChange,
  error,
  label,
  options
}: WorkExperienceProps) => {
  const { t } = useTranslation();
  const { defaultOptions } = useWorkExperience();

  return (
    <Stack spacing="small">
      <Field
        label={label || t('postJobForm.fieldLabel.workExperience')}
        message={
          error && (
            <Box marginTop="xsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Box marginTop="xsmall">
          <SingleSelect
            testID="job-form-workExperience"
            onChange={onChange}
            options={options || defaultOptions}
            value={value}
          />
        </Box>
      </Field>
    </Stack>
  );
};

import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EyeIcon, EyeSlashIcon } from '@components/Icon';
import { IconButton } from '@components/IconButton';
import InputField from '../../../common/components/FormElements/InputField/InputField';
import styles from './PasswordField.scss';

const PASSWORD_CHAR = '•';

export default class PasswordField extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired
    }),
    validationMessages: PropTypes.object,
    extraTopMargin: PropTypes.bool,
    label: PropTypes.string,
    t: PropTypes.func.isRequired,
    showPassword: PropTypes.bool,
    togglePassword: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      maskedValue: '',
      value: ''
    };
  }

  maskPasswordInput(inputValue, existingValue, selectionStart) {
    const inputChars = inputValue.split('');
    const existingValueChars = existingValue.split('');
    const diffLength = inputChars.length - existingValueChars.length;

    if (diffLength > 0) {
      for (let i = 0; i < diffLength; i++) {
        existingValueChars.splice(selectionStart - diffLength, 0, ' ');
      }
    } else if (diffLength < 0) {
      existingValueChars.splice(selectionStart, -diffLength);
    }

    for (let i = 0; i < inputChars.length; i++) {
      if (inputChars[i] !== PASSWORD_CHAR) {
        existingValueChars[i] = inputChars[i];
      }
    }

    return existingValueChars.join('');
  }

  handleChange(event) {
    const {
      input: { onChange, value }
    } = this.props;

    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;
    const updatedValue = this.maskPasswordInput(
      event.target.value,
      value,
      selectionStart
    );

    event.target.value = event.target.value.replace(/./g, PASSWORD_CHAR);
    this.setState({ maskedValue: event.target.value, value: updatedValue });

    event.target.selectionStart = selectionStart;
    event.target.selectionEnd = selectionEnd;

    onChange(updatedValue);
  }

  render() {
    const { maskedValue, value } = this.state;
    const {
      input: { name },
      t
    } = this.props;
    const {
      input,
      extraTopMargin,
      label = t('common.fieldLabel.password'),
      validationMessages,
      showPassword,
      togglePassword,
      ...propsToSpread
    } = this.props;

    const inputProps = {
      ...input,
      onChange: this.handleChange.bind(this),
      value: showPassword ? value : maskedValue
    };

    const eyeIcon = showPassword ? (
      <EyeIcon testID="show-password-icon" size="xlarge" variant="outline" />
    ) : (
      <EyeSlashIcon
        testID="hide-password-icon"
        size="xlarge"
        variant="outline"
      />
    );

    return (
      <div
        className={classNames(styles.fieldContainer, {
          [styles.extraTopMargin]: extraTopMargin
        })}
      >
        <InputField
          fieldId={name}
          label={label}
          type={showPassword ? 'text' : 'password'}
          validationMessages={validationMessages}
          blacklisthotjar
          {...propsToSpread}
          input={inputProps}
        />
        {typeof togglePassword === 'function' && (
          <span
            className={styles.iconContainer}
            data-test-key={'eye-icon-wrapper'}
          >
            <IconButton
              testID="password-toggle-button"
              icon={eyeIcon}
              onPress={togglePassword}
            />
          </span>
        )}
      </div>
    );
  }
}

import React from 'react';
import { Skeleton } from 'antd';
import { StepWrapper } from './StepWrapper';

export const StepLoader = () => {
  return (
    <StepWrapper>
      <Skeleton active />
    </StepWrapper>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { JobDescription } from '@common/components/Job/formFields';
import { useTranslation } from '@common/hooks/useTranslation';
import { NavControls } from '../components';
import type {
  JobDescriptionFormData,
  JobDescriptionLength,
  OnInvalidDescriptionCallback
} from './types';

type FormOptions = {
  jobDescriptionLength: JobDescriptionLength;
  onInvalidDescription: OnInvalidDescriptionCallback;
};

type DescriptionFormProps = {
  defaultValues: JobDescriptionFormData;
  formOptions: FormOptions;
  stepInfo: {
    submitText: string;
  };
  onSubmit: (data: JobDescriptionFormData) => Promise<void>;
  onBack: () => void;
};

export const DescriptionForm = ({
  defaultValues,
  formOptions,
  onSubmit,
  stepInfo: { submitText },
  onBack
}: DescriptionFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<JobDescriptionFormData>({
    defaultValues,
    mode: 'onBlur'
  });

  const handleFormSubmitForStep = async (data: JobDescriptionFormData) => {
    await onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmitForStep)}>
      <div className="space-y-12">
        <JobDescription
          name="jobDescription"
          control={control}
          jobDescriptionLength={formOptions.jobDescriptionLength}
          onInvalidDescription={formOptions.onInvalidDescription}
        />
      </div>

      <div className="flex flex-col gap-sm mt-lg">
        <NavControls
          onBack={onBack}
          onNext={handleSubmit(handleFormSubmitForStep)}
          onNextText={submitText}
          disabled={formState.isSubmitting || formState.isSubmitSuccessful}
        />

        {formState.isSubmitted && !formState.isValid ? (
          <ErrorMessage>{t('validations.general.summary')}</ErrorMessage>
        ) : null}
      </div>
    </form>
  );
};

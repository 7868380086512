import React from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { useTranslation } from '@common/hooks/useTranslation';
import { immediateStartRules } from '../../rules';
import { ImmediateStartField } from './ImmediateStartField';

type Props<T extends FieldValues> = UseControllerProps<T> & {
  label?: string | React.ReactNode;
};

export function ImmediateStart<T extends FieldValues>({
  name,
  control,
  label
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={immediateStartRules(t)}
      render={({ field: { onChange, value }, formState }) => (
        <ImmediateStartField
          label={label}
          disabled={formState.disabled}
          onChange={onChange}
          value={value}
          error={formState?.errors[name]?.message as string}
        />
      )}
    />
  );
}

import React from 'react';
import {
  StepError,
  StepLoader,
  StepWrapper
} from '@common/components/Caja/components';
import {
  ImmediateStartPhrase,
  RightToWorkDocument
} from '@common/components/Job/BaseJobSummary';
import {
  InvalidDescriptionData,
  JobDescriptionFormData,
  SummaryAndDescription
} from '@common/components/Job/SummaryAndDescription';
import {
  trackMaxLengthValidationError,
  trackMinLengthValidationError
} from '@common/components/Job/formFields';
import {
  JOB_DESCRIPTION_MAX_LENGTH,
  JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH
} from '@common/constants/validation';
import { useFetchBusinessData } from '@common/hooks/business/useFetchBusinessData';
import { useFetchJobData, usePatchDescription } from '@common/hooks/job';
import { TopLevelStep } from '@common/hooks/multiStep';
import { useRedirectUnauthenticatedUser } from '@common/hooks/useRedirectUnauthenticatedUser';
import { AD_TYPE } from '@seek/je-shared-data';
import { useAppDispatch } from '@store/hooks';
import { mapJobToFormData, mapJobToJobSummary } from './transformers';

type Props = Pick<
  TopLevelStep<{
    jobId: string;
    showAdTypeBadge: boolean;
    rightToWorkDocument: RightToWorkDocument;
    immediateStartPhrase: ImmediateStartPhrase;
    adType?: AD_TYPE;
    submitText?: string;
  }>,
  'onCompleted' | 'onBack' | 'stepInfo'
>;

export function JobSummaryAndDesc({
  stepInfo: {
    jobId,
    showAdTypeBadge,
    rightToWorkDocument,
    immediateStartPhrase,
    adType,
    submitText
  },
  onBack,
  onCompleted
}: Props) {
  useRedirectUnauthenticatedUser();
  const dispatch = useAppDispatch();

  const { patchJobData, error: updateJobError } = usePatchDescription();

  const {
    data: job,
    isLoading: jobLoading,
    error: loadJobError
  } = useFetchJobData({ jobId });

  const {
    data: business,
    isLoading: businessLoading,
    error: loadBusinessError
  } = useFetchBusinessData({ businessId: job?.businessId });

  const isLoading = jobLoading || businessLoading;
  const error = updateJobError || loadJobError || loadBusinessError;

  const handleInvalidDescription = (data: InvalidDescriptionData) => {
    if (data.reason === 'MIN_LENGTH') {
      dispatch(trackMinLengthValidationError());
    } else if (data.reason === 'MAX_LENGTH') {
      dispatch(trackMaxLengthValidationError());
    }
  };

  const onUpdateDescriptionSubmit = async (data: JobDescriptionFormData) => {
    const prevJobDescription = job?.description;
    if (prevJobDescription === data.jobDescription) return onCompleted({});

    const res = await patchJobData({
      id: jobId,
      description: data.jobDescription || null
    });

    if (res.id) onCompleted({});
  };

  if (isLoading) return <StepLoader />;

  return (
    <StepWrapper>
      <SummaryAndDescription
        descriptionForm={{
          defaultValues: mapJobToFormData(job),
          formOptions: {
            jobDescriptionLength: {
              min: JOB_DESCRIPTION_MULTISTEP_MIN_LENGTH,
              max: JOB_DESCRIPTION_MAX_LENGTH
            },
            onInvalidDescription: handleInvalidDescription,
            rightToWorkDocument,
            immediateStartPhrase
          }
        }}
        jobSummary={{
          values: mapJobToJobSummary(job, business, adType),
          showAdTypeBadge
        }}
        onSubmit={onUpdateDescriptionSubmit}
        onBack={onBack}
        submitText={submitText}
      />

      <StepError error={error} />
    </StepWrapper>
  );
}

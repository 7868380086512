import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

/**
 * Sorts two country codes, placing the primary country code first if it matches either of the given country codes.
 *
 * @param countryA - The first country code to compare.
 * @param countryB - The second country code to compare.
 * @param primaryCountry - The primary country code to prioritize in the sorting.
 * @returns -1 if `countryA` is the primary country or if `countryA` is lexicographically less than `countryB`, otherwise 1.
 */
export const sortByPrimaryCountry = (
  countryA: string,
  countryB: string,
  primaryCountry?: SupportedCountryCode
) => {
  if (countryA === primaryCountry) return -1;

  if (countryA < countryB) return -1;

  return 1;
};

import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { ApplicationFilterValues } from '../../../types';
import { sortFilteredApplications } from '../sort';
import {
  applyFilterReducer,
  filterApplicantStatus,
  filterCitizenship,
  filterDistance,
  filterEducation,
  filterLocation,
  filterRightToWork,
  filterTargetLocation,
  filterVideoScreening,
  filterWorkExperience
} from './';

export const getFilteredApplications = (
  filterValues: ApplicationFilterValues,
  applications: ProfileApplication[]
): ProfileApplication[] => {
  const filters = [
    filterApplicantStatus,
    filterCitizenship,
    filterDistance,
    filterEducation,
    filterLocation,
    filterRightToWork,
    filterTargetLocation,
    filterVideoScreening,
    filterWorkExperience
  ];
  const filteredApplications = applyFilterReducer(
    filters,
    filterValues,
    applications
  );
  return sortFilteredApplications(filterValues, filteredApplications);
};

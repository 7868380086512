import { JobForSummary } from '@common/components/Job/SummaryAndDescription';
import { AD_TYPE } from '@seek/je-shared-data/lib/types/jobs';
import { BusinessWithUsers } from '@store/entities/businesses/types';
import { JobItemState } from '@store/entities/jobs';

export const mapJobToJobSummary = (
  job?: JobItemState,
  business?: BusinessWithUsers,
  adType?: AD_TYPE
): JobForSummary | undefined => {
  if (!job) return;
  if (!business) return;

  const formData: JobForSummary = {
    jobTitle: job?.title,
    workExperience: job?.workExperience,
    salary: job?.salary,
    jobType: job?.jobType,
    shiftAvailability: job?.shiftAvailability,
    rightToWork: job?.rightToWork,
    immediateStart: job?.immediateStart,
    jobDescription: job?.description,
    location: job?.location,
    adType: adType || job?.adType || AD_TYPE.BASIC,
    business: {
      name: business.name
    }
  };

  return formData;
};

import React, { useRef } from 'react';
import { usePremiumApplicantFiltersFeature } from '@common/hooks/featureToggles';
import { useUserHasPremiumFilterAccess } from '@common/hooks/userPackages';
import { Application } from '@common/types';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Column } from '@components/Column';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { ApplicationFilterValues } from '../../types';
import { FilterFormProvider } from './FilterFormContext';
import {
  FilteredStatusPill,
  FiltersModal,
  PremiumFeaturesBadge
} from './components';
import { ApplicantStatusPanel } from './components/ApplicantStatusPanel';
import { ApplicationsBarTitle } from './components/ApplicationsBarTitle';
import { ApplicationsStatusProgressBar } from './components/ApplicationsStatusProgressBar';
import { FiltersLink } from './components/FiltersLink';

type Props = {
  appliedFiltersCount: number;
  pageTitle: string;
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  applicationFilters: ApplicationFilterValues;
  shouldShowActions: boolean;
  selectedApplicationsTab: 'matched' | 'other';
  shouldShowApplicationStats?: boolean;
  allApplications: Application[];
  applicantCount: number;
};

export const ApplicationsStatusAndFilterSortBar = ({
  appliedFiltersCount,
  pageTitle,
  selectedApplicationsTab,
  setApplicationFilters,
  applicationFilters,
  shouldShowActions,
  shouldShowApplicationStats,
  allApplications,
  applicantCount
}: Props) => {
  const {
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    isModalOpen: isFilterModalOpen
  } = useModal();
  const userIsFreeUser = !useUserHasPremiumFilterAccess();
  const showPremiumFilters = usePremiumApplicantFiltersFeature();

  const ref = useRef<{
    clearAllFilters: () => void;
    sendClearAllFiltersTrackingEvent: (eventType: string) => void;
  }>();

  return (
    <FilterFormProvider
      setApplicationFilters={setApplicationFilters}
      applicationFilters={applicationFilters}
    >
      <Stack tone="transparent">
        {shouldShowApplicationStats ? (
          <Card
            borderWidth="xsmall"
            marginTop="medium"
            testID="applications-management-stats"
          >
            <CardContent paddingBottom="large">
              <Stack spacing="small">
                <ApplicationsBarTitle count={applicantCount} />
                <ApplicantStatusPanel applications={allApplications} />
                <Box marginTop="medium">
                  <ApplicationsStatusProgressBar
                    applications={allApplications}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        ) : null}

        <Column
          justifyContent="space-between"
          alignItems="flex-start"
          padding="medium"
          tone="transparent"
          zIndex={1000}
          spacing="medium"
        >
          <Row
            paddingTop="xsmall"
            paddingLeft="xxsmall"
            tone="transparent"
            alignItems="center"
            spacing="medium"
          >
            {shouldShowActions ? (
              <Row tone="transparent" alignItems="center" spacing="medium">
                <FiltersLink onPressLink={openFilterModal} />

                {userIsFreeUser && showPremiumFilters ? (
                  <PremiumFeaturesBadge />
                ) : null}

                <FiltersModal
                  ref={ref}
                  pageTitle={pageTitle}
                  visible={isFilterModalOpen}
                  onRequestClose={closeFilterModal}
                  selectedApplicationsTab={selectedApplicationsTab}
                />
              </Row>
            ) : null}
          </Row>

          {appliedFiltersCount > 0 ? (
            <Row paddingLeft="xxsmall" tone="transparent" alignItems="center">
              <FilteredStatusPill
                onPressClearFilters={() => {
                  ref.current?.clearAllFilters();
                  ref.current?.sendClearAllFiltersTrackingEvent(
                    'Application filters pill cleared'
                  );
                }}
                filteredCount={appliedFiltersCount}
              />
            </Row>
          ) : null}
        </Column>
      </Stack>
    </FilterFormProvider>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';

export function useBusinessState(jobCountryCode?: string) {
  const [businessCountryCode, set] = useState<
    SupportedCountryCode | undefined
  >();

  useEffect(() => {
    if (jobCountryCode) set(formatted<SupportedCountryCode>(jobCountryCode));
  }, [jobCountryCode]);

  const setCountryCode = useCallback(
    (countryCode?: string) => {
      if (!countryCode) return;
      if (formatted(countryCode) === businessCountryCode) return;

      set(formatted<SupportedCountryCode>(countryCode));
    },
    [set, businessCountryCode]
  );

  return [
    businessCountryCode || (jobCountryCode as SupportedCountryCode),
    setCountryCode
  ] as const;
}

const formatted = <T>(str: string) => str.toLowerCase() as T;

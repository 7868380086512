import { T } from '@common/hooks/useTranslation';
import {
  CurrencySymbols,
  SupportedCurrencies
} from '@seek/je-shared-data/lib/types/brand';

const getSalaryAmount = (
  salaryAmount: number | Partial<{ from: number; to: number }>
) => {
  return typeof salaryAmount === 'number'
    ? { totalAmount: salaryAmount }
    : { minAmount: salaryAmount.from, maxAmount: salaryAmount.to };
};

export const getSalarySummary = (salary, t: T): string | undefined => {
  if (salary?.amount) {
    return t(`postJobForm.summary.salary.${salary?.type}`, {
      rate: t(`postJobForm.summary.salary.rates.${salary?.rate}`),
      currencySymbol: CurrencySymbols[salary?.currency as SupportedCurrencies],
      ...getSalaryAmount(salary?.amount || 0)
    });
  }

  return;
};

import { useCallback } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { loadBusinesses } from '@store/entities/businesses/actions';
import { getBusinesses } from '@store/entities/businesses/selectors';
import { useAppDispatch, useAppSelector } from '@store/hooks';

export const useGetBusinessListData = () => {
  const dispatch = useAppDispatch();
  const businesses = useAppSelector(getBusinesses());

  const loadBusinessListError = useAppSelector(
    getRequestState(REQUEST_KEY.BUSINESS.LIST)
  ).error;

  const getBusinessListData = useCallback(async () => {
    await dispatch(loadBusinesses());
  }, [dispatch]);

  return {
    getBusinessListData,
    hasLoadBusinessListError: Boolean(loadBusinessListError),
    businesses
  };
};

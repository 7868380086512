import React, { useEffect, useRef, useState } from 'react';
import { useFetchUiVersion } from '@common/hooks/system';

export const CodeReloader = () => {
  const [_, setUiVersion] = useState<number | null>(null);
  const [lastChecked, setLastChecked] = useState<number | null>(null);
  const lastCheckedRef = useRef(lastChecked);
  const { fetchUiVersion } = useFetchUiVersion();

  useEffect(() => {
    function checkVersion() {
      if (!shouldCheckVersion()) return;
      setLastChecked(Date.now());
      lastCheckedRef.current = Date.now();
      fetchUiVersion()
        .then((res) => {
          setUiVersion((prev) => {
            if (prev !== null && prev !== res) {
              window.location.reload();
            }
            localStorage.setItem('__ui_version', res.toString());
            return res;
          });
        })
        .catch((e) => {
          console.error('Error fetching ui version', e);
        });
    }

    function recheckTimeInSecs() {
      return 30 * 60; // 30 minutes
    }

    function shouldCheckVersion() {
      if (document.visibilityState !== 'visible') return false;
      if (!lastCheckedRef.current) return true;
      return Date.now() - lastCheckedRef.current >= recheckTimeInSecs() * 1000;
    }

    const intervalId = setInterval(() => {
      checkVersion();
    }, recheckTimeInSecs() * 1000);

    setTimeout(checkVersion, 1000);

    document.addEventListener('visibilitychange', checkVersion);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', checkVersion);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

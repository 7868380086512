import { cleanTitleForComparison } from './cleanTitleForComparison';

interface MatchTitleSearch {
  title: string;
  searchTerm: string;
}

export function matchTitleSearch({
  title,
  searchTerm
}: MatchTitleSearch): boolean {
  if (!title) return false;
  if (!searchTerm) return true;
  return cleanTitleForComparison(title).includes(
    cleanTitleForComparison(searchTerm)
  );
}

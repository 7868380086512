import type { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the job title field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function jobTitleRules(t: T) {
  return {
    validate: (val: { jobTitle: string; role: string }) => {
      if (!val?.jobTitle && !val?.role) {
        return t('validations.job.title.required');
      }
    }
  };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Application } from '@common/types';
import { Checkbox } from '@components/FormElements';
import { PremiumFeaturesBadge } from '../../../../../PremiumFeaturesBadge';
import { ClearLink } from '../ClearLink';
import { options } from './WorkExperienceFilter';

type DisabledWorkExperienceFilterProps = {
  applications: Application[];
};

export const DisabledWorkExperienceFilter = ({
  applications
}: DisabledWorkExperienceFilterProps) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-md">
      <div className="flex justify-between">
        <div className="flex justify-start gap-md">
          <div className="text-md">
            {t('applications.applicationsFilter.workExperience.title')}
          </div>
          <PremiumFeaturesBadge />
        </div>
        <ClearLink onClear={() => {}} active={false} />
      </div>

      <div className="flex flex-wrap">
        {options(applications, t).map(
          (option) =>
            option?.value && (
              <div className="w-full md:w-1/2 mb-md">
                <Checkbox
                  disabled
                  key={option.value}
                  label={option.label}
                  onChange={() => {}}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

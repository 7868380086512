import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompletedUrl } from '@common/components/Caja/steps/PaymentJobStep/hooks/useGetCompletedUrl';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useBusinessState } from '@common/hooks/business';
import { useFetchJobData } from '@common/hooks/job';
import { useSteps } from '@common/hooks/multiStep';
import { useSearchQueryParam } from '@common/hooks/useSearchQueryParam';
import { useTranslation } from '@common/hooks/useTranslation';
import { useUpdateSourceQuery } from '@common/hooks/useUpdateSourceQuery';
import { AD_TYPE } from '@seek/je-shared-data';
import { UpgradeableAdType, upgraded } from '@src/job';
import { PageHeader } from '@components/PageHeader';
import { StepError, StepLoader } from '../../components';
import { useAdTypeState, useCajaVariant, useTrackCajaEvent } from '../../hooks';
import { enhanceJobRouter, paymentJobRouter } from '../../steps';
import { PaymentSuccess } from '../../steps/PaymentSuccessStep/PaymentSuccess';
import {
  EnhanceJobStepIds,
  GetStepsParams,
  getPageTitle,
  getSteps
} from './stepsInfo';

export const EnhanceJob = () => {
  useUpdateSourceQuery();

  const { t } = useTranslation();
  const { getQueryParam } = useSearchQueryParam();
  const { jobId } = useParams<{ jobId: string }>();
  const campaign = getQueryParam('campaign') as string;
  const cajaVariant = useCajaVariant();
  const { getOnCompletedUrl } = useGetCompletedUrl();
  const {
    data: jobData,
    isLoading: fetchJobLoading,
    error: fetchJobError
  } = useFetchJobData({
    jobId
  });

  const [selectedAdType, setSelectedAdType] = useAdTypeState();
  const [businessCountryCode] = useBusinessState(jobData?.countryCode);

  const { currentStep, nextStep, gotoNextStep, gotoPreviousStep } = useSteps<
    GetStepsParams,
    EnhanceJobStepIds
  >({
    data: {
      selectedAdType,
      hasJobUpgraded: upgraded(jobData?.adType)
    },
    getSteps
  });

  useTrackCajaEvent({
    step: `enhance_job_${currentStep.id}`,
    campaign,
    jobId
  });

  const onEnhanceJobCompleted = ({
    jobAdType,
    hasJobUpgraded
  }: {
    jobAdType: AD_TYPE;
    hasJobUpgraded: boolean;
  }) => {
    setSelectedAdType(jobAdType);
    gotoNextStep({
      selectedAdType: jobAdType,
      hasJobUpgraded: hasJobUpgraded || upgraded(jobData?.adType)
    });
  };

  const EnhanceJobStep = enhanceJobRouter({ cajaVariant });
  const PaymentJobStep = paymentJobRouter({ cajaVariant });

  if (fetchJobLoading) return <StepLoader />;

  return (
    <div className="flex flex-col">
      <div>
        <JoraEmployerHelmet title={t(getPageTitle(currentStep.id))} />
        <PageHeader>{t(getPageTitle(currentStep.id))}</PageHeader>
      </div>

      {currentStep.id === 'enhanceJob' && (
        <EnhanceJobStep
          stepInfo={{
            jobId,
            jobCountryCode: businessCountryCode,
            initialAdType: upgraded(jobData?.adType)
              ? jobData?.adType
              : selectedAdType,
            hideBasicAdType: true,
            hideBackButton: true,
            onNextText: t('enhanceJob.action.next')
          }}
          onCompleted={onEnhanceJobCompleted}
          onBack={gotoPreviousStep}
        />
      )}
      {currentStep.id === 'payment' && (
        <PaymentJobStep
          stepInfo={{
            jobId,
            campaign,
            jobCountryCode: businessCountryCode,
            adType: selectedAdType as UpgradeableAdType,
            onCompletedUrl: getOnCompletedUrl(nextStep)
          }}
          onBack={gotoPreviousStep}
        />
      )}

      {currentStep.id === 'paymentSuccess' && <PaymentSuccess />}

      {fetchJobError && <StepError error={fetchJobError} />}
    </div>
  );
};

export const maxAdBenefits = [
  {
    title: 'upgradeJob.maxPackage.benefits1.title',
    points: ['upgradeJob.maxPackage.benefits1.point1']
  },
  {
    title: 'upgradeJob.maxPackage.benefits2.title',
    points: ['upgradeJob.maxPackage.benefits2.point1']
  },
  {
    title: 'upgradeJob.maxPackage.benefits3.title',
    points: ['upgradeJob.maxPackage.benefits3.point1']
  }
];

import { useSiteCountryCode } from '@common/hooks/localisation';

export type Props = {
  jobId: string;
  campaign?: string;
};

export function useGetEnhanceJobLink({ campaign, jobId }: Props) {
  const countryCode = useSiteCountryCode();

  return `/${countryCode}/jobs/${jobId}/enhance-job${
    campaign ? `?campaign=${campaign}` : ''
  }`;
}

import type { T } from '@common/hooks/useTranslation';

/**
 * Generates validation rules for the business industry field.
 * @param t - The translation function.
 * @returns The validation rules object.
 */
export function industryRules(t: T) {
  return {
    required: t('validations.business.industry.required')
  };
}

import { useGetSourceParams } from '@common/hooks/useSearchQueryParam';

export const useGetUtmParams = (): string => {
  const { utm_campaign, utm_medium, utm_source, utm_content } =
    useGetSourceParams();

  const params = new URLSearchParams({
    ...(utm_campaign ? { utm_campaign } : {}),
    ...(utm_medium ? { utm_medium } : {}),
    ...(utm_source ? { utm_source } : {}),
    ...(utm_content ? { utm_content } : {})
  });

  return params.toString();
};

import React from 'react';
import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { useTranslation } from 'react-i18next';
import { Stack } from '@components/Stack';
import {
  Tab,
  TabList,
  TabPanels,
  TabsProvider,
  useTabs
} from '@components/TabsProvider';
import { ApplicationList } from './components/ApplicationList';
import { ApplicationsExpiryCalloutDetails } from './components/ApplicationsExpiryCalloutDetails';
import { ApplicationsLoadError } from './components/ApplicationsLoadError';
import { ApplicationsStatusAndFilterSortBar } from './components/ApplicationsStatusAndFilterSortBar';
import {
  EmptyApplicationsInformationCard,
  useEmptyApplicationsInformationCard
} from './components/EmptyApplicationsInformationCard';
import {
  RepostJobContainer,
  useRepostJobContainer
} from './components/RepostJobContainer';
import {
  useFetchJobApplications,
  useFetchJobApplicationsStatus,
  useFilteredApplications,
  useJobApplicationDetails,
  useMatchedAndUnmatchedApplications
} from './hooks';

type JobApplicationDetailsProps = {
  jobId: string;
  pageTitle: string;
};

export const JobApplicationDetails = ({
  jobId,
  pageTitle
}: JobApplicationDetailsProps) => {
  const { t } = useTranslation();
  useFetchJobApplications(jobId);
  const { hasFetchApplicationsError } = useFetchJobApplicationsStatus();
  const {
    shouldShowApplicationStats,
    shouldShowApplicationFilterBar,
    selectedApplicationsTab,
    handleOnTabPress
  } = useJobApplicationDetails(jobId);

  const {
    applicationFilters,
    setApplicationFilters,
    appliedFiltersCount,
    allApplications,
    filteredApplications
  } = useFilteredApplications(jobId);

  const { matchedApplications, unmatchedApplications } =
    useMatchedAndUnmatchedApplications(filteredApplications);

  if (hasFetchApplicationsError) {
    return <ApplicationsLoadError />;
  }

  return (
    <Stack tone="transparent">
      <ApplicationsStatusAndFilterSortBar
        pageTitle={pageTitle}
        appliedFiltersCount={appliedFiltersCount}
        setApplicationFilters={setApplicationFilters}
        applicationFilters={applicationFilters}
        shouldShowActions={shouldShowApplicationFilterBar}
        selectedApplicationsTab={selectedApplicationsTab}
        shouldShowApplicationStats={shouldShowApplicationStats}
        allApplications={allApplications}
        applicantCount={filteredApplications.length}
      />
      <ApplicationsExpiryCalloutDetails jobId={jobId} />

      <TabsProvider>
        <TabList onTabPress={handleOnTabPress}>
          <Tab testID="matched-applications-tab">
            {`${t(
              'jobDetailsAndApplicationsListPage.applicationList.tab.matchedApplicants'
            )} (${matchedApplications.length})`}
          </Tab>
          <Tab testID="other-applications-tab">
            {`${t(
              'jobDetailsAndApplicationsListPage.applicationList.tab.nonMatchedApplicants'
            )} (${unmatchedApplications.length})`}
          </Tab>
        </TabList>
        <TabPanels>
          <ApplicationInformationPanel
            jobId={jobId}
            tabbedApplications={matchedApplications}
            filteredApplications={filteredApplications}
          />
          <ApplicationInformationPanel
            jobId={jobId}
            tabbedApplications={unmatchedApplications}
            filteredApplications={filteredApplications}
          />
        </TabPanels>
      </TabsProvider>
    </Stack>
  );
};

const ApplicationInformationPanel = ({
  jobId,
  tabbedApplications,
  filteredApplications
}: {
  filteredApplications: ProfileApplication[];
  tabbedApplications: ProfileApplication[];
  jobId: string;
}) => {
  const { selectedTab } = useTabs();
  const selectedTabValue = selectedTab === 0 ? 'matched' : 'other';

  const { shouldRenderEmptyInformationCard } =
    useEmptyApplicationsInformationCard(
      jobId,
      selectedTabValue,
      filteredApplications
    );
  const { shouldRenderRepostJobContainer } = useRepostJobContainer(jobId);

  if (shouldRenderEmptyInformationCard) {
    return (
      <Stack spacing="medium" tone="transparent">
        <EmptyApplicationsInformationCard
          jobId={jobId}
          currentSelectedTab={selectedTabValue}
          filteredApplications={filteredApplications}
        />
        {shouldRenderRepostJobContainer ? (
          <RepostJobContainer jobId={jobId} />
        ) : null}
      </Stack>
    );
  }

  return (
    <ApplicationList
      jobId={jobId}
      applications={tabbedApplications}
      testID={`${selectedTabValue}-applications-list`}
    />
  );
};

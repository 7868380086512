import { useHistory } from 'react-router-dom';
import { useAccountUpgradeable } from '@common/hooks/featureToggles';
import { useSiteCountryCode } from '@common/hooks/localisation';

export const useRedirectIfAccountUpgradableDisabled = () => {
  const history = useHistory();
  const countryCode = useSiteCountryCode();
  const isAccountUpgradeEnabled = useAccountUpgradeable();

  if (!isAccountUpgradeEnabled) {
    history.push(`/${countryCode}/`);
  }
};

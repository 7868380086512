import { editJobStepIds } from '@common/components/Caja/steps/MultiStepJobEdit';
import { repostJobStepIds } from '@common/components/Caja/steps/MultiStepJobRepost';
import { AD_TYPE } from '@seek/je-shared-data';

export const stepConfig = (
  createdJobId?: string | null,
  selectedAdType?: AD_TYPE
) =>
  ({
    title: {
      id: repostJobStepIds.title,
      path: '/job-title-business-selection'
    },
    basicDetails: {
      id: repostJobStepIds.basicDetails,
      path: '/basic-details'
    },
    editTitle: {
      id: editJobStepIds.title,
      path: `/${createdJobId}/job-title-business-selection`
    },
    editBasicDetails: {
      id: editJobStepIds.basicDetails,
      path: `/${createdJobId}/basic-details`
    },
    enhanceJob: {
      id: 'enhanceJob',
      path: `/${createdJobId}/enhance-job`
    },
    payment: {
      id: 'payment',
      path: `/${createdJobId}/payment`,
      queryParams: `adType=${selectedAdType}`
    },
    jobSummaryAndDesc: {
      id: 'jobSummaryAndDesc',
      path: `/${createdJobId}/review-job-description`,
      queryParams: `adType=${selectedAdType}`
    },
    postJobSuccess: {
      id: 'postJobSuccess',
      path: `/${createdJobId}/post-job-success`
    }
  } as const);

type StepConfig = ReturnType<typeof stepConfig>;

export type RepostJobStepIds = StepConfig[keyof StepConfig]['id'];

import React, { ReactNode } from 'react';

/**
 * Props for the Accordion component.
 *
 * @typedef AccordionProps
 * @property {Array<{ label: ReactNode; content: ReactNode }>} items - An array of items to be displayed in the accordion. Each item contains a label and content.
 * @property {string} [name] - An optional name for the accordion when there is more than one on a page.
 */
export type AccordionProps = {
  items: { label: ReactNode; content: ReactNode }[];
  name?: string;
};

export const Accordion = ({ items, name = 'accordion' }: AccordionProps) => {
  return (
    <>
      {items.map(({ label, content }, index) => (
        <div key={index} className="collapse collapse-arrow">
          <input type="radio" name={name} defaultChecked={index === 0} />
          <div className="collapse-title text-sm">{label}</div>
          <div className="collapse-content">{content}</div>
        </div>
      ))}
    </>
  );
};

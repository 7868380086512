import { StepPathInfo } from '../types';

type StepIndicatorInfo<StepIds extends string> = {
  [key in StepIds]: { ignoreIndicator?: boolean };
};

export const getStepNumberForIndicator = <StepIds extends string>(
  steps: StepPathInfo<StepIds>[],
  stepIndicatorInfo: StepIndicatorInfo<StepIds>,
  currentPathname: string
) => {
  const stepsToCount = steps.filter(
    (step) => !stepIndicatorInfo[step.id].ignoreIndicator
  );

  const foundIndex = stepsToCount.findIndex(
    (step) => step.path === currentPathname
  );

  if (foundIndex === -1) return 1;
  return foundIndex + 1;
};

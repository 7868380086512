import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook for handling search query parameters.
 * @returns An object containing functions to check, get, and retrieve raw query parameters.
 */
export function useSearchQueryParam() {
  const { search } = useLocation();

  /**
   * Checks if a specific query parameter value in the search string is true or false.
   * @param queryParam - The name of the query parameter to check.
   * @returns A boolean indicating whether the query parameter value is true or false.
   */
  const checkQueryParam = (queryParam) => {
    return Boolean(
      search?.length &&
        queryString.parse(search, {
          parseBooleans: true
        })[queryParam]
    );
  };

  /**
   * Retrieves the value of a query parameter from the search string.
   *
   * @param queryParam - The name of the query parameter to retrieve.
   * @returns The value of the specified query parameter, or `undefined` if it doesn't exist.
   */
  const getQueryParam = (queryParam: string) => {
    if (search?.length === 0) return;

    return queryString.parse(search, {
      parseBooleans: true
    })[queryParam];
  };

  /**
   * Retrieves the raw query parameters from the search string.
   *
   * @returns The raw query parameters.
   */
  const getRawQueryParams = () => {
    return search;
  };

  return {
    checkQueryParam,
    getQueryParam,
    getRawQueryParams
  };
}

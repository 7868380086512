import { sendToNative } from '../sendToNative';

type NativeSystem = {
  applicationId: string | null;
  applicationName: string | null;
  applicationVersion: string | null;
  platform: string;
};

interface OpenResumeResponse {
  success: boolean;
  system?: NativeSystem;
}

export async function openResume(url: string): Promise<OpenResumeResponse> {
  return (await sendToNative('open-resume', { url })) as OpenResumeResponse;
}

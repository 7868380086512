import { useCallback } from 'react';
import { useBrandName } from '@common/hooks/localisation';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';
import { useAppDispatch } from '@store/hooks';
import { createPaymentIntent } from '@store/payment/actions';

type Params = {
  jobCountryCode: SupportedCountryCode;
  jobId: string;
  campaign?: string;
  sourceParams?: string;
};

export const useCreatePaymentIntent = ({
  jobCountryCode,
  jobId,
  campaign,
  sourceParams
}: Params) => {
  const dispatch = useAppDispatch();
  const brandName = useBrandName();

  const setupPayment = useCallback(
    (adType: UpgradeableAdType, pricingText: string) => {
      dispatch(
        createPaymentIntent({
          countryCode: jobCountryCode,
          jobId,
          adType,
          brand: brandName,
          campaign,
          pricingText,
          sourceParams
        })
      );
    },
    [brandName, campaign, dispatch, jobCountryCode, jobId, sourceParams]
  );

  return setupPayment;
};

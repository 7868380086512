import { trpcBff } from '@trpcBff';

export const atJobLimit = (jobCount: number, jobLimit: number): boolean =>
  jobCount >= jobLimit;

export const useAtJobLimit = () => {
  const { data, isError, error, isLoading, isSuccess, refetch } =
    trpcBff.jobs.limit.jobLimit.useQuery(
      {},
      {
        staleTime: 5000,
        refetchOnWindowFocus: false,
        retry: false
      }
    );

  return {
    isAtLimit: data?.isAtLimit,
    isError,
    error,
    isSuccess,
    isLoading,
    refetch
  };
};

import { UpdateJobParams } from '@common/hooks/job/useUpdateJob/useUpdateJob';
import { Salary } from '@seek/je-shared-data/lib/types/jobs';
import { FormData } from '../EditJobStep';

type ShiftAvailabiltyParam = UpdateJobParams['shiftAvailability'];

export type CompleteEditJobFormData = Required<FormData> & {
  jobDescription?: string;
  salary?: Salary;
  jobTitle: { jobTitle: string; role: string };
};

export const toUpdateJobParams = (
  jobId: string,
  job: CompleteEditJobFormData
): UpdateJobParams => ({
  id: jobId,
  businessId: job.business?.id,
  title: job.jobTitle.jobTitle,
  jobRole: job.jobTitle.role,
  workExperience: job.workExperience,
  salary: mapSalary(job.salary),
  jobType: job.jobType,
  shiftAvailability: job.shiftAvailability as ShiftAvailabiltyParam
});

const mapSalary = (salary: CompleteEditJobFormData['salary']) => {
  if (!salary) return undefined;
  if (!salary.amount) return null;

  return salary as Salary;
};

import { useGetPostJobLink } from '@common/hooks/appPageLinks/useGetPostJobLink';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { useAppSelector } from '@store/hooks';
import { getLocalisation } from '@store/localisation';

export function useWhatHappensNext() {
  const { brandNameText, brandConfig } = useAppSelector(getLocalisation);

  const isRealTimeJobPostingBrand =
    brandConfig?.name &&
    [BrandName.JOBSTREETEXPRESS].includes(brandConfig.name);

  return {
    isRealTimeJobPostingBrand,
    brandNameText
  };
}

export function useWhatHappensNextActions() {
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();
  const { postJobLink } = useGetPostJobLink();

  return {
    redirectToPostJobPage: redirect(postJobLink),
    redirectToHomePage: redirect(`/${countryCode}/`)
  };
}

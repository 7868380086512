import React from 'react';
import { useGetCreateBusinessLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { useTranslation } from '@common/hooks/useTranslation';
import { TextLink } from '@components/TextLink';

export const CreateBusinessLink = () => {
  const { t } = useTranslation();
  const { redirect } = useRedirect();
  const createBusinessLink = useGetCreateBusinessLink(false);

  return (
    <TextLink onPress={redirect(createBusinessLink)}>
      {t('createBusinessForm.action.addNewBusiness')}
    </TextLink>
  );
};

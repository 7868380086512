import React from 'react';
import { TextInputProps } from 'react-native';
import { useTranslation } from '@common/hooks/useTranslation';
import { Box } from '@components/Box';
import { CurrencyInput, Field } from '@components/FormElements';
import { Text } from '@components/Text';
import { useExactAmount, useExactAmountProps } from './hooks/useExactAmount';

type ExactAmountProps = useExactAmountProps & {
  currencySymbol?: string;
  onBlur?: TextInputProps['onBlur'];
};

export const ExactAmount = (props: ExactAmountProps) => {
  const { t } = useTranslation();
  const { onChange, value } = props;
  const { onAmountChange } = useExactAmount({ value, onChange });

  return (
    <Field
      label={
        <Box paddingBottom="xsmall">
          <Text size="small">
            {t('postJobForm.expectedSalary.label.salaryAmount')}
          </Text>
        </Box>
      }
    >
      <Box testID="job-form-salary-exact-amount">
        <CurrencyInput
          currencySymbol={props.currencySymbol}
          value={value?.toString()}
          onChange={(maskAmount, unmaskedAmount) => {
            onAmountChange(unmaskedAmount);
          }}
          onBlur={props.onBlur}
          placeholder={t('postJobForm.expectedSalary.placeholder.total', {
            currencySymbol: props.currencySymbol
          })}
          testID="job-form-salaryAmount"
        />
      </Box>
    </Field>
  );
};

import React from 'react';
import { Skeleton } from '@common/components/Skeleton';
import { Box } from '@components/Box';
import { Card, CardAction, CardContent, CardFooter } from '@components/Card';
import { Column } from '@components/Column';
import { Row } from '@components/Row';
import * as colours from '../../../../../../common/theme/palette/colours';

const gridRowHeight = 8;

export const ListBusinessSkeleton = () => {
  return (
    <Box marginTop="medium" marginBottom="medium" tone="transparent">
      <Row justifyContent="flex-end" marginBottom="medium" tone="transparent">
        <Skeleton
          highlightColor={colours.grey200}
          baseColor={colours.grey300}
          width={200}
          height={gridRowHeight * 6}
          marginRight={gridRowHeight * 2}
        />
      </Row>

      <Card>
        <CardContent>
          <Row alignItems="center">
            <Skeleton
              width={48}
              height={gridRowHeight * 6}
              marginRight={gridRowHeight}
            />
            <Column spacing="xxsmall">
              <Skeleton width={200} height={gridRowHeight * 2} />
              <Skeleton width={100} height={gridRowHeight * 2} />
            </Column>
          </Row>
        </CardContent>
        <CardFooter>
          <CardAction>
            <Skeleton width={64} height={gridRowHeight * 2} />
          </CardAction>
          <CardAction>
            <Skeleton width={64} height={gridRowHeight * 2} />
          </CardAction>
        </CardFooter>
      </Card>
    </Box>
  );
};

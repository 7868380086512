import { useDispatch } from 'react-redux';
import { EventTypes } from 'redux-segment';

type TrackManageMarketingPreferencesButtonClickedAction = {
  type: string;
  meta: {
    analytics: {
      eventType: string;
      eventPayload: {
        event: string;
        properties: Record<string, unknown>;
      };
    };
  };
};

export const useTrackManageMarketingPreferencesButtonClicked = () => {
  const dispatch = useDispatch();
  return () => dispatch(trackManageMarketingPreferencesButtonClicked());
};

const TRACK_MANAGE_MARKETING_PREFERENCES_BUTTON_CLICKED =
  'jora-employer/account/TRACK_MANAGE_MARKETING_PREFERENCES_BUTTON_CLICKED';

function trackManageMarketingPreferencesButtonClicked(): TrackManageMarketingPreferencesButtonClickedAction {
  return {
    type: TRACK_MANAGE_MARKETING_PREFERENCES_BUTTON_CLICKED,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: 'Manage marketing preferences button clicked',
          properties: {}
        }
      }
    }
  };
}

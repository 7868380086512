import React from 'react';
import { Password as InnerField } from '@common/components/Account/components/Password';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { FieldWrapper } from '../FieldWrapper';

export const Password = () => {
  const { passwordRules } = useAccountRules();
  return (
    <FieldWrapper<string>
      name="password"
      rules={passwordRules}
      render={(props) => {
        return (
          <InnerField
            showExplanationText={true}
            {...props}
            labelKey="registerForm.fieldLabel.password"
          />
        );
      }}
    />
  );
};

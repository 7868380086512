import React from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';
import { Heading } from '@components/Heading';
import { BackButton, BasePaymentForm, BasePricingPanel } from '../components';

type Props = {
  stepInfo: {
    adType: UpgradeableAdType;
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    paymentCampaign: string;
    currencyPrice: {
      [AD_TYPE.PLUS]: string;
      [AD_TYPE.MAX]: string;
    };
    coinPrice: {
      [AD_TYPE.PLUS]: number;
      [AD_TYPE.MAX]: number;
    };
    sourceParams?: string;
    headingText: string;
  };
  onCompletedUrl: string;
  onBack: () => void;
};

export const PaymentForm = (props: Props) => {
  const { stepInfo, onCompletedUrl, onBack } = props;

  return (
    <div className="flex flex-col gap-md">
      <BasePricingPanel
        adType={stepInfo.adType}
        currencyPrice={stepInfo.currencyPrice}
        coinPrice={stepInfo.coinPrice}
      />

      <div className="mt-[24px]">
        <Heading level="h2">{stepInfo.headingText}</Heading>
      </div>

      <BasePaymentForm
        {...stepInfo}
        onCompletedUrl={onCompletedUrl}
        backButton={<BackButton onPress={onBack} />}
      />
    </div>
  );
};

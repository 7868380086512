import React from 'react';
import { useTranslation } from '@common/hooks/useTranslation';
import { AD_TYPE } from '@seek/je-shared-data';
import { Badge } from '@components/Badge';
import { Text } from '@components/Text';
import {
  BaseJobSummary,
  ImmediateStartPhrase,
  RightToWorkDocument
} from '../BaseJobSummary';
import { JobForSummary } from './types';

type JobSummaryProps = {
  job: JobForSummary;
  rightToWorkDocument: RightToWorkDocument;
  immediateStartPhrase: ImmediateStartPhrase;
  showAdTypeBadge?: boolean;
};

export const JobSummary = ({
  job,
  rightToWorkDocument,
  immediateStartPhrase,
  showAdTypeBadge = false
}: JobSummaryProps) => {
  return (
    <div className="flex flex-col gap-sm">
      {showAdTypeBadge && (
        <div className="flex flex-row">
          <AdTypeBadge adType={job.adType} />
        </div>
      )}
      <div className="flex flex-col mb-[12px]">
        <Text weight="medium" size="xlarge">
          {job.jobTitle}
        </Text>

        <div className="flex flex-row">
          <Text weight="regular" size="standard">
            {job.business?.name}
          </Text>
          <Text weight="regular" size="standard" tone="neutralLight">
            {` - ${job.location}`}
          </Text>
        </div>
      </div>
      <BaseJobSummary
        job={{
          ...job,
          businessName: job.business?.name!,
          workExperience: job.workExperience!,
          shiftAvailability: job.shiftAvailability!
        }}
        rightToWorkDocument={rightToWorkDocument}
        immediateStartPhrase={immediateStartPhrase}
      />
    </div>
  );
};

const AdTypeBadge = ({ adType }: { adType: AD_TYPE }) => {
  const { t } = useTranslation();

  const badgeTone = adType === AD_TYPE.BASIC ? 'neutral' : 'promo';
  const badgeVariant = adType === AD_TYPE.BASIC ? 'secondary' : 'primary';

  return (
    <Badge tone={badgeTone} variant={badgeVariant}>
      {t(`adBadge.${adType}`)}
    </Badge>
  );
};

import React from 'react';
import {
  BaseJobSummary,
  ImmediateStartPhrase,
  RightToWorkDocument
} from '@common/components/Job/BaseJobSummary';
import { Job } from '@common/types';
import { HtmlText } from '@components/HtmlText';

type Props = {
  job: Job;
  rightToWorkDocument: RightToWorkDocument;
  immediateStartPhrase: ImmediateStartPhrase;
};

export const JobSummary = (props: Props) => {
  const { job, rightToWorkDocument, immediateStartPhrase } = props;

  return (
    <div className="flex flex-col gap-sm">
      <BaseJobSummary
        job={{
          ...job,
          jobTitle: job.title,
          workExperience: job.workExperience!,
          shiftAvailability: job.shiftAvailability!
        }}
        rightToWorkDocument={rightToWorkDocument}
        immediateStartPhrase={immediateStartPhrase}
      />
      <HtmlText testID="job-description-html-text">{job.description}</HtmlText>
    </div>
  );
};

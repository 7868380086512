import { useEffect } from 'react';
import REQUEST_KEY from '@common/constants/requestKeys';
import { getRequestState } from '@store/app';
import { getJobById } from '@store/entities/jobs';
import * as jobActions from '@store/entities/jobs/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

const defaultEmptyJob = {};

export function useLoadJobData(jobId?: string) {
  const dispatch = useAppDispatch();

  const loadJobError = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LOAD)
  ).error;

  const isLoading = useAppSelector(
    getRequestState(REQUEST_KEY.JOB.LOAD)
  ).isLoading;

  useEffect(() => {
    (async () => {
      if (jobId) await dispatch(jobActions.load(jobId));
    })();
  }, [dispatch, jobId]);

  return {
    hasLoadJobError: Boolean(loadJobError),
    isLoading,
    job: useAppSelector(getJobById(jobId || '')) || defaultEmptyJob
  };
}

import React from 'react';
import { Email as InnerField } from '@common/components/Account/components/Email';
import { useAccountRules } from '@common/components/AccountMultiStep/rules/useAccountRules';
import { useTranslation } from '@common/hooks/useTranslation';
import { FieldWrapper } from '../FieldWrapper';

export const Email = () => {
  const { t } = useTranslation();
  const { emailRules } = useAccountRules();
  return (
    <FieldWrapper<string>
      name="email"
      rules={emailRules}
      render={(props) => {
        const mergedProps = { ...props, t };
        return <InnerField {...mergedProps} showExplanationText={false} />;
      }}
    />
  );
};

import React from 'react';
import clsx from 'clsx';

export type Props = {
  checked?: boolean;
  onPress?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  testID?: string;
  disabled?: boolean;
};

export const RadioIcon = (props: Props) => {
  const {
    disabled = false,
    onFocus,
    onBlur,
    onPress,
    checked = false,
    testID
  } = props;

  return (
    <input
      type="radio"
      data-testid={testID}
      checked={checked}
      aria-checked={checked}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onPress}
      className={clsx(
        'radio w-[18px] h-[18px] cursor-pointer outline-none',
        'border border-neutral',
        'checked:border-success checked:bg-background-brand',
        'disabled:cursor-not-allowed disabled:opacity-60'
      )}
    />
  );
};

import React from 'react';
import { AD_TYPE } from '@seek/je-shared-data';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import { UpgradeableAdType } from '@src/job';
import { BackButton, BasePaymentForm } from '../components';

type Props = {
  stepInfo: {
    adType: UpgradeableAdType;
    jobId: string;
    jobCountryCode: SupportedCountryCode;
    paymentCampaign: string;
    currencyPrice: {
      [AD_TYPE.PLUS]: string;
      [AD_TYPE.MAX]: string;
    };
    sourceParams?: string;
  };
  onCompletedUrl: string;
  onBack: () => void;
};

export const PaymentForm = (props: Props) => {
  const { stepInfo, onCompletedUrl, onBack } = props;

  return (
    <BasePaymentForm
      {...stepInfo}
      onCompletedUrl={onCompletedUrl}
      backButton={<BackButton onPress={onBack} />}
    />
  );
};

import { OnSubmitValues } from '@common/components/Job/EnhanceJob/EnhanceJobWithCoinsAndTargetLocation';
import { PatchJobParams } from '@common/hooks/job/usePatchJob/usePatchPremiumFields';

type Params = OnSubmitValues;

export const toJob = (jobId: string, job: Params): PatchJobParams => ({
  id: jobId,
  rightToWork: job.rightToWork,
  immediateStart: job.immediateStart,
  videoQuestions: [
    {
      code: job.videoScreeningQuestion
    }
  ],
  targetLocations: job.targetLocation ? [{ locationCode: 'johor-bahru' }] : []
});

import { normaliseExtractedText } from '@common/helpers/textHelper';
import type { T } from '@common/hooks/useTranslation';
import { JobDescriptionLength, OnInvalidDescriptionCallback } from '../fields';

export function jobDescriptionRules(
  t: T,
  jobDescriptionLength: JobDescriptionLength,
  onInvalidDescription: OnInvalidDescriptionCallback
) {
  return {
    validate: (text: string) => {
      const normalisedExtractedText = normaliseExtractedText(text || '');
      if (normalisedExtractedText.length === 0) {
        // Field is not required
        return;
      }

      if (normalisedExtractedText.length < jobDescriptionLength.min) {
        onInvalidDescription({
          amount: normalisedExtractedText.length,
          requiredAmount: jobDescriptionLength.min,
          reason: 'MIN_LENGTH'
        });
        return t('validations.job.description.minLength', {
          min: jobDescriptionLength.min
        });
      }
      if (normalisedExtractedText.length > jobDescriptionLength.max) {
        onInvalidDescription({
          amount: normalisedExtractedText.length,
          requiredAmount: jobDescriptionLength.max,
          reason: 'MAX_LENGTH'
        });
        return t('validations.job.description.maxLength', {
          max: jobDescriptionLength.max
        });
      }

      return;
    }
  };
}
